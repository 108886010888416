import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

// import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';

import './Werknemer.css';

class WerknemerOverzichtBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      objectName: '',
      object: '',
    }
  }

  componentDidMount() {
    this.props.firebase
    .user(this.props.uid)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val()
      })

      this.getObject();

    })
  }

  getObject() {
    this.props.firebase
    .object(this.props.object)
    .once('value', snapshot => {
      this.setState({
        object: snapshot.val(),
      })
    })
  }

  render() {

    const { user, object } = this.state;
    // console.log('OBJECT', this.props.object)

    return (
        <div>
          {user && <div className="Werknemer-detail-overzicht">
          <div className="Werknemer-detail-gegevens">
          <div className="Werknemer-wijzig-gegevens">
            <Link to={{
                pathname: `${ROUTES.WERKNEMER}/edit`,
                state: { uid:this.props.uid },
              }}
              >
                Wijzig gegevens
            </Link>
          </div>
            <table className="Tabel-gegevens">
              <tbody>
              <h3>Persoonsgegevens</h3>
                <tr>
                  <td><strong>Naam:</strong></td><td>{user.username}</td>
                </tr>
                <tr>
                  <td><strong>Adres:</strong></td><td>{user.address}</td>
                </tr>
                <tr>
                  <td><strong>Postcode:</strong></td><td>{user.zipCode}</td>
                </tr>
                <tr>
                  <td><strong>Plaats:</strong></td><td>{user.place}</td>
                </tr>
                <tr>
                  <td><strong>E-mailadres:</strong></td><td>{user.email}</td>
                </tr>
                <tr>
                  <td><strong>Telefoonnummer:</strong></td><td>{user.phoneNumber}</td>
                </tr>
                <tr>
                  <td><strong>sQootID:</strong></td><td>{this.props.uid.substring(0, 5)}</td>
                </tr>
                <tr>
                  <td><strong>Werkgever:</strong></td><td>{user.company}</td>
                </tr>

                <h3 className="H3-tabel">Gegevens contract</h3>
                <tr>
                  <td><strong>Maximale waarde fiets:</strong></td><td>€{user.leasebudget}</td>
                </tr>
                {user.startDateContract && <tr>
                  <td><strong>Start contract:</strong></td><td>{user.startDateContract.substring(4,15)}</td>
                </tr>}
                {user.endDateContract && <tr>
                  <td><strong>Eind contract:</strong></td><td>{user.endDateContract.substring(4,15)}</td>
                </tr>}
                <tr>
                  <td><strong>Type bijdrage:</strong></td><td>{user.bijdrageWerkgeverType}</td>
                </tr>
                <tr>
                  <td><strong>Bijdrage werkgever:</strong></td><td>€{user.bijdrageWerkgever}</td>
                </tr>
                <tr>
                  <td><strong>Bruto aanschafprijs:</strong></td>{object && <td>€{object.objectPrice}</td>}
                </tr>
                <tr>
                  <td><strong>Maandelijks leasebedrag:</strong></td><td>€{user.monthlyLeaseFee}</td>
                </tr>
                <tr>
                  <td><strong>Bijtelling:</strong></td><td>€{user.bijtelling}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="Werknemer-buttons">
            <Link to={{
              pathname: `${ROUTES.WERKNEMER}/object/${user.object}`,
              state: {objectName: user.object }
            }}
            >
              <div className="Werknemer-button">
              <h3>Objectgegevens</h3>
              </div>
            </Link>
            <Link to={{
              pathname: `${ROUTES.WERKNEMER}/service`,
              state: { uid: this.props.uid, object: user.object },
            }}
            >
              <div className="Werknemer-button">
                <h3>Melden service, diefstal, schade</h3>
              </div>
            </Link>

          </div>
        </div>}
      </div>
  )}


};

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(WerknemerOverzichtBase);
