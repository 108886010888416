import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class EndContractBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      gegevensGewzijgd: false,
      uid: '',
      ...props.location.state,
    };
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  endContractFietsVerkocht = event => {
    this.props.firebase
      .object(this.state.user.object)
      .update({
        userUid: '',
        company: '',
        dealer: '',
        status: 'Verkocht',
        active: true,
    })
    .then(() => {
      this.props.firebase
        .user(this.state.uid) //delete user in Realtime database
        .remove()
    })
    // .then(() => {
    //   this.props.firebase
    //     .authUser(this.state.uid) //delete authUser werkt nog niet
    //     .delete()
    //   })
    .then(() => {
      //update object activity
      const ref = this.props.firebase
        .object(this.state.user.object)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `Object is verkocht aan ${this.state.user.username} van ${this.state.user.company}`,
        createdAt: new Date().toString(),
      })
    })
    .then(() => {
      this.sendEmailToNiels(event);
    })
    .catch((error) => {
      console.log('Error', error)
    })
    .then(() => {
      this.props.history.push(ROUTES.ADMIN);
    })

    this.setState({
      gegevensGewzijgd: true,
    })
  }

  endContractFietsNaarSqoot = event => {
    this.props.firebase
      .object(this.state.user.object)
      .update({
        userUid: '',
        company: 'sQoot & Bike',
        dealer: 'sQoot & Bike',
        status: 'Voorraad sQoot & Bike',
        poolObject: true,
        active: true, //als fiets nog niet actief is en contract beeindigd blijft de fiets niet in de lijst 'niet actieve objecten' staan hierdoor
    })
    .then(() => {
      this.addObjectToCompany(this.state.user.object)
    })
    .then(() => {
      this.props.firebase
        .user(this.state.uid) //delete user in Realtime database
        .remove()
    })
    .then(() => {
      //update object activity
      const ref = this.props.firebase
        .object(this.state.user.object)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `Contract met ${this.state.user.username} van ${this.state.user.company} is beëindigd en fiets is naar voorraad sQoot & Bike`,
        createdAt: new Date().toString(),
      })
    })
    .then(() => {
      this.sendEmailToNiels(event);
    })
    .catch((error) => {
      console.log('Error', error)
    })
    .then(() => {
      this.props.history.push(ROUTES.ADMIN);
    })

    this.setState({
      gegevensGewzijgd: true,
    })
  }

  addObjectToCompany(objectName) {
    const objectRef = this.props.firebase
    .company('sQoot & Bike')

    const companyObjecten = objectRef.child('objecten')

    companyObjecten.push({
      objectName: objectName,
    })

    console.log('Fiets toegevoegd als poolobject')
  }

  sendEmailToNiels(event) {
  //Verstuur mail naar Niels voor het verwijderen van Storage en AuthUser
    const templateId = 'notificatie_naar_niels_voor_verwijderen_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      usernameWerknemer: this.state.user.username,
      uid: this.state.uid,
    }

    this.sendFeedbackNiels(templateId, template_params, userId)
  }

  sendFeedbackNiels(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const { user, uid, loading, gegevensGewzijgd } = this.state;

    console.log('USER', user)
    console.log('UID', uid)


    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {user && (
          <div className="Werknemer-gegevens-edit">
            <div className="Einde-contract">
              <div className="Einde-contract-column">
                <h2>Contract beëindigen</h2>
                <h3>Fiets wordt overgenomen door klant.</h3>
                <p>Klik op onderstaande knop om het contract te beëindigen. Dit doe je als de contractduur van deze persoon is verlopen.</p>
                <p><strong>LET OP: Hiermee wordt deze persoon uit de portal verwijderd, deze stap is niet meer te herstellen.</strong></p>
                <div>
                  <button onClick={this.endContractFietsVerkocht} className="Button-marge red">Contract beëindigen</button>
                </div>
                  {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </div>
              <div className="Einde-contract-column">
              <h2>Contract beëindigen</h2>
              <h3>Fiets gaat terug naar sQoot & Bike.</h3>
                <p>Klik op onderstaande knop om het contract te beëindigen. Dit doe je als de contractduur van deze persoon is verlopen.</p>
                <p><strong>LET OP: Hiermee wordt deze persoon uit de portal verwijderd, deze stap is niet meer te herstellen.</strong></p>
                <div>
                  <button onClick={this.endContractFietsNaarSqoot} className="Button-marge red">Contract beëindigen</button>
                </div>
                  {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </div>

          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(EndContractBase);
