import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class DealerItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dealer: null,
      users: [],
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "username",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.ADMIN}/user/${params.data.uid}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }}, {
        headerName: "E-mailadres", field: "email", width: 250,
      }, {
        headerName: "Werkgever", field: "company",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.COMPANIES}/company/${params.value}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      }, {
        headerName: "Object", field: "object",
        cellRendererFramework: (params) => {
          console.log('Params', params)
          return <Link to={{
              pathname: `${ROUTES.OBJECTS}/object/${params.value}`,
              state: { uid: params.data.uid },
              // pathname: `${ROUTES.OBJECTS}/object/${user.object}`,
              // state: { user },
            }}
            >
              {params.value}
            </Link>
      }
      }],
      defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    },
      ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.dealer) {
    //   return;
    // } Deze staat nu uit, omdat anders werknemers niet geladen werden omdat company niet bekend is.

    this.setState({
      loading: true
    });

    this.props.firebase
    .dealer(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        dealer: snapshot.val(),
        // loading: false,
      });
    });

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.dealers(this.props.match.params.id).off();
    this.props.firebase.users().off();
  }

  render() {
    const { dealer, users, loading } = this.state;
    // console.log('Dealer', dealer)
    // console.log('Users', users)

    return (
      <>
        <div className="Terug-naar-overzicht">
          <Link to={{
              pathname: `${ROUTES.DEALERS}`,
              // state: { user },
            }}
            >
              Terug naar overzicht dealers
          </Link>
        </div>
        <div  className="Dealer">
          <div className="Dealer-info-block">
            <h2>{this.props.match.params.id}</h2>

            {dealer && (
              <div className="Dealer-info">
                <table>
                  <tbody>
                    <tr>
                      <td><strong>Name:</strong></td>
                      <td>{dealer.dealerName}</td>
                    </tr>
                    <tr>
                      <td><strong>Adres:</strong></td>
                      <td>{dealer.dealerAddress}</td>
                    </tr>
                    <tr>
                      <td><strong>Plaats:</strong></td>
                      <td>{dealer.dealerPlace}</td>
                    </tr>
                    <tr>
                      <td><strong>Contact:</strong></td>
                      <td>{dealer.dealerContact}</td>
                    </tr>
                    <tr>
                      <td><strong>E-mailadres:</strong></td>
                      <td>{dealer.dealerContactEmail}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/*<SignUpWerknemerForm/>*/}
          <div className="Dealer-werknemers">
            <h2>Werknemers bij deze dealer</h2>
            {loading && <div>Loading...</div>}

            <div
              className="ag-theme-alpine"
              style={{
              height: '400px',
              }}
            >
              <AgGridReact
                reactNext={true} //
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                paginationAutoPageSize={true}
                rowData={users.filter(user => {
                  return user.dealer === dealer.dealerName
                })}>
              </AgGridReact>
            </div>

            {/*<table className="Tabel-full-width">
              <tbody>
                <tr>
                  <th>Naam</th>
                  <th>E-mailadres</th>
                  <th>Werkgever</th>
                  <th>Object</th>
                </tr>
                {users
                .filter(user => {
                  return user.dealer === dealer.dealerName
                })
                .sort((a, b) => {
                  if (a.username < b.username) {
                    return -1;
                  }
                  if (a.username > b.username) {
                    return 1;
                  }
                  return 0;
                })
                .map(user => (
                <tr key={user.uid} >
                  <td><Link to={{
                    pathname: `${ROUTES.ADMIN}/user/${user.uid}`,
                    state: { user },
                  }}
                  >
                    {user.username}
                  </Link></td>
                  <td>{user.email}</td>
                  <td><Link to={{
                    pathname: `${ROUTES.COMPANIES}/company/${user.company}`,
                    state: { user },
                  }}
                  >
                    {user.company}
                  </Link></td>
                  <td><Link to={{
                    pathname: `${ROUTES.OBJECTS}/object/${user.object}`,
                    state: { user },
                  }}
                  >
                    {user.object}
                  </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            </table>*/}
          </div>
        </div>
      </>
    )
  }
}

export default DealerItemBase;
