import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import "./SignUpWerkgever.css";

const SignUpWerkgeverPage = () => (
  <div className="Page">
    <h1>Aanmelden werkgever</h1>
      <div className="SignUpWerkgeverForm">
        <SignUpWerkgeverForm />
      </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  companyName: '',
  companyAddress: '',
  companyPlace: '',
  companyContact: '',
  companyContactEmail: '',
  isWerkgever: true,
  error: null,
}

class SignUpWerkgeverFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { username, email, passwordOne, companyName, companyPlace, companyAddress, companyContact, companyContactEmail, isWerkgever} = this.state;

    const roles = {};

    if(isWerkgever) {
      roles[ROLES.WERKGEVER] = ROLES.WERKGEVER;
    }


    this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      //Create a user in Firebase realtime database
      return this.props.firebase
      .user(authUser.user.uid)
      .set({username, email, companyName, roles,})
    })
    .then(() => {
      return this.props.firebase.doSendEmailVerification();
    })
    .then(() => {
      this.sendEmailToWerkgever(event);
    })
    .then(authUser => {
      // this.setState({...INITIAL_STATE});
      // this.props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      this.setState({error});
    });

    return this.props.firebase
    .company(companyName)
    .set({ companyName, companyAddress, companyPlace, companyContact, companyContactEmail })
    .then(this.setState({...INITIAL_STATE},
          this.props.history.push(ROUTES.COMPANIES)))
    .catch(error => {
      this.setState({error});
    })

    // event.preventDefault();
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      companyContact: this.state.username,
      companyContactEmail: this.state.email,
     })
  }

  sendEmailToWerkgever(event) {
  //Verstuur mail naar werkgever
    const templateId = 'aanmelding_werkgever_bevestiging_naar_werkgever';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.companyContactEmail,
      companyContact: this.state.companyContact,
      companyAddress: this.state.companyAddress,
      companyPlace: this.state.companyPlace,
      companyName: this.state.companyName,
      password: this.state.passwordOne,
    }

    this.sendFeedbackWerkgever(templateId, template_params, userId)
  }

  sendFeedbackWerkgever(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      companyName,
      companyPlace,
      companyAddress,
      // companyContact,
      // companyContactEmail,
      // isDealer,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      companyName === '' ||
      companyAddress === '' ||
      companyPlace === '';

    return(
      <form onSubmit={this.onSubmit} className="SignUpForm">
        <label>Naam contactpersoon</label>
        <input
        name = 'username'
        value = {username}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Emailadres contactpersoon</label>
        <input
        name = 'email'
        value = {email}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Wachtwoord</label>
        <input
        name = 'passwordOne'
        value = {passwordOne}
        onChange = {this.onChange}
        type = 'password'
        />
        <label>Bevestig wachtwoord</label>
        <input
        name = 'passwordTwo'
        value = {passwordTwo}
        onChange = {this.onChange}
        type = 'password'
        />
        <label>Bedrijfsnaam</label>
        <input
        name = 'companyName'
        value = {companyName}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Adres bedrijf</label>
        <input
        name = 'companyAddress'
        value = {companyAddress}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Plaats bedrijf</label>
        <input
        name = 'companyPlace'
        value = {companyPlace}
        onChange = {this.onChange}
        type = 'text'
        />

        <button disabled={isInvalid} type='submit'>Aanmelden</button>

        {error && <p>{error.message}</p>}

      </form>
    )
  }
}

const SignUpWerkgeverLink = () => (
  <p>
    Heb je nog geen account? <Link to={ROUTES.SIGN_UP_WERKGEVER}>Meld je hier aan</Link>
  </p>
)

const SignUpWerkgeverForm = compose(
  withRouter,
  withFirebase,
)(SignUpWerkgeverFormBase);

export default SignUpWerkgeverPage;

export { SignUpWerkgeverForm, SignUpWerkgeverLink };
