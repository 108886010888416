import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

class WerkgeverPoolObjectsListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      objects: '',
      company: '',
      companyName: '',
      ...props.location.state
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

  //   this.props.firebase
  //   // .company(this.state.companyName)
  //   .company(this.props.company)
  //   .on('value', snapshot => {
  //
  //
  //   const poolObject = snapshot.val().objecten;
  //
  //   if(poolObject) {
  //     const objectenList = Object.keys(poolObject).map(key => ({
  //       ...poolObject[key],
  //       uid: key,
  //     })
  //   );
  //
  //     this.setState({
  //       company: snapshot.val(),
  //       loading:false,
  //       objecten: objectenList
  //     })
  //   } else {
  //     this.setState({
  //       loading: false
  //     })
  //   }
  // })

  this.props.firebase.objects()
  .on('value', snapshot => {
    const objectsObject = snapshot.val();

    const objectsList = Object.keys(objectsObject).map(key => ({
      ...objectsObject[key],
      uid: key,
    }));

    this.setState({
      objects: objectsList,
      loading: false,
    });
  });
}


  render() {
    const { loading, objects } = this.state;
    // console.log('company', company)
    // console.log('objects', objects)
    // console.log('company', this.props.company)

    return (
      <AuthUserContext.Consumer>
      {authUser => (
      <div>
      {!!authUser.roles[ROLES.WERKGEVER] && <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>}
      <div className='Overzicht-poolobjecten'>
        <h2>Poolobjecten</h2>
        {loading && <div>Loading...</div>}

        {/*dit wordt getoond op de bedrijvenpagina voor de admin*/}
        {objects && !!authUser.roles[ROLES.ADMIN] && (
          <table className="Tabel-full-width">

          <tbody>
          {Object.values(objects)
            .sort((a,b) => parseInt(a.uid.slice(7)) - parseInt(b.uid.slice(7)))
            .filter(object => {
              return object.company === this.props.company
            })
            .filter(object => {
              return object.poolObject === true
            })
            .map(object => (
            <tr key={object.uid} className="Activiteit">

              <td><Link to={{
              pathname: `${ROUTES.OBJECTS}/object/${object.objectName}`,
              // state: { object: object.objectName },
              }}
              >
                {object.objectName}
              </Link></td>
            </tr>
          ))}
          </tbody>
          </table>)}

          {/*dit wordt getoond op de werkgeverspagina onder Poolobjecten*/}
          {objects && !!authUser.roles[ROLES.WERKGEVER] && (
            <table className="Tabel-full-width">
              <thead>
                <th>Object</th><th>Berijder</th><th>Locatie</th><th>Startdatum</th><th>Einddatum</th>
              </thead>

            <tbody>
            {Object.values(objects)
              .sort((a,b) => parseInt(a.uid.slice(7)) - parseInt(b.uid.slice(7)))
              .filter(object => {
                return object.company === authUser.companyName
              })
              .filter(object => {
                return object.poolObject === true
              })
              .map(object => (
              <tr key={object.uid} className="Activiteit">

                <td><Link to={{
                pathname: `${ROUTES.WERKGEVER}/object/${object.objectName}`,
                // state: { object: object.objectName },
                }}
                >
                  {object.objectName}
                </Link></td>
              <td>{object.poolObjectDriver}</td>
              <td>{object.poolObjectLocation}</td>
              {object.poolObjectStartDate && <td>{object.poolObjectStartDate.slice(4,16)}</td>}
              {object.poolObjectEndDate && <td>{object.poolObjectEndDate.slice(4,16)}</td>}
              </tr>
            ))}
            </tbody>
            </table>)}

        {!objects && <p>Er zijn nog geen poolobjecten bij deze werkgever bekend.</p>}
      </div>
    </div>
  )}
    </AuthUserContext.Consumer>
)
  }
}

export default withRouter(WerkgeverPoolObjectsListBase);
