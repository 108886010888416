import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class DealersListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      dealers:[],
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "dealerName"
      }, {
        headerName: "Adres", field: "dealerAddress"
      }, {
        headerName: "Plaats", field: "dealerPlace"
      }, {
        headerName: "Contactpersoon", field: "dealerContact"
      }, {
        headerName: "Email", field: "dealerContactEmail", width:250,
      }, {
        headerName: "Details", field: "dealerName", filter: false, sortable: false, width:100,
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.DEALERS}/dealer/${params.value}`,
              state: { dealer: params.data },
            }}
            >
              Details
            </Link>
      }}],
      defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    },
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase.dealers().on('value', snapshot => {
      const dealersObject = snapshot.val();

      const dealersList = Object.keys(dealersObject).map(key => ({
        ...dealersObject[key],
        uid: key,
      }));

      this.setState({
        dealers: dealersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.dealers().off();
  }

render() {
  const { dealers, loading } = this.state;
  console.log('dealers', dealers)

  return (
    <div className="Dealers">
      <h2>Dealers</h2>
      <div className="Nieuw-bedrijf-aanmaken">
      <Link to={{
          pathname: `${ROUTES.SIGN_UP_DEALER}`,
          // state: { user },
        }}
        >
          Nieuwe dealer aanmaken
      </Link>
      </div>
      {loading && <div>Loading...</div>}

      <div
        className="ag-theme-alpine"
        style={{
        height: '500px',
        }}
      >
        <AgGridReact
          reactNext={true} //
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          pagination={true}
          paginationPageSize={this.state.paginationPageSize}
          paginationAutoPageSize={true}
          rowData={dealers}>
        </AgGridReact>
      </div>

      {/*<table className="Tabel-full-width">
        <tbody>
          <tr>
            <th>Naam</th><th>Adres</th><th>Details</th>
          </tr>
          {Object.values(dealers)
            .sort((a, b) => {
              if (a.username < b.username) {
                return -1;
              }
              if (a.username > b.username) {
                return 1;
              }
              return 0;
            })
            .map(dealer => (
          <tr key={dealer.uid}>
            <td>{dealer.uid}</td>
            <td>{dealer.dealerAddress}</td>
            <td><Link to={{
              pathname: `${ROUTES.DEALERS}/dealer/${dealer.uid}`,
              state: { dealer },
            }}
            >
              Details
            </Link></td>
          </tr>
          ))}
        </tbody>
      </table>*/}
    </div>
  );
}
}

export default DealersListBase;
