import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

// import { SignUpLink} from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import "./SignIn.css";

const SignInPage = () => (
  <div className="Page">
    <div className="SignInForm">
      <SignInForm />
      <PasswordForgetLink />
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase.doSignInWithEmailAndPassword(email, password)
    .then(() => {
      this.setState({ ...INITIAL_STATE});
      this.props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      this.setState({ error });
    });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <>
        <h1>Inloggen</h1>
        <form onSubmit={this.onSubmit} className="SignInForm-fields">
          <label>E-mailadres</label>
          <input
            name = 'email'
            value = {email}
            onChange = {this.onChange}
            type = 'text'
          />
          <label>Wachtwoord</label>
          <input
            name = 'password'
            value = {password}
            onChange = {this.onChange}
            type = 'password'
          />
          <button disabled={isInvalid} type="submit">Inloggen</button>

          {error && <p>{error.message}</p>}
        </form>
      </>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;
export { SignInForm };
