import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import { withFirebase } from '../Firebase';
// import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';

class WerkgeverDeelemerDetailBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: {},
      username: '',
      email: '',
      leasebudget: '',
      bijdrageWerkgever: 0,
      bijdrageWerkgeverType: '',
      selectedOption: "",
      bijtelling: '',
      bedragenIngevuld: false,
      object:{},
      sqootId: '',
      akkoord: '',
      error: '',
      success: '',
      ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.object && this.state.user) {
    //   return;
    // }

    this.setState({
      loading: true
    });

    this.props.firebase
    .user(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        leasebudget: snapshot.val().leasebudget,
        bijdrageWerkgever: snapshot.val().bijdrageWerkgever,
        selectedOption: snapshot.val().bijdrageWerkgeverType,
        bijtelling: snapshot.val().bijtelling,
        username: snapshot.val().username,
        email: snapshot.val().email,
        akkoord: snapshot.val().isAkkoord,
        sqootId: this.props.match.params.id.substring(0, 5),
        loading: false,
      });
    });

    this.props.firebase
    .object(this.state.user.object)
    .on('value', snapshot => {
      this.setState({
        object: snapshot.val(),
      })
    })
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  updateLeasebudget = event => {

    //Deze code wordt doorlopen als werkgever gedeeltelijk betaald. Bijdrage werkgever wordt op ingevulde bedrag gezet
    if(this.state.selectedOption === 'Werkgever betaalt gedeeltelijk') {
      this.props.firebase
      .user(this.props.match.params.id)
      .update({
        leasebudget: this.state.leasebudget,
        bijdrageWerkgeverType: this.state.selectedOption,
        bijdrageWerkgever: this.state.bijdrageWerkgever,
      })
      .then(() => {
        if(this.state.akkoord) {
          console.log('mail versturen naar werknemer')
          this.sendLeasebudgetEmailToWerknemer(event)
        }
      })
      .then(res => console.log('leasebudget succesvol aangepast', res))
      .catch(err => console.error('Error', err));
    }

    //Deze code wordt doorlopen als werkgever faciliteert. Bijdrage werkgever wordt op 0 gezet
    else if(this.state.selectedOption === 'Werkgever faciliteert (geen bijdrage vanuit werkgever)') {
      this.props.firebase
      .user(this.props.match.params.id)
      .update({
        leasebudget: this.state.leasebudget,
        bijdrageWerkgeverType: this.state.selectedOption,
        bijdrageWerkgever: 0,
      })
      .then(() => {
        if(this.state.akkoord) {
          console.log('mail versturen naar werknemer')
          this.sendLeasebudgetEmailToWerknemer(event)
        }
      })
      .then(res => console.log('leasebudget succesvol aangepast', res))
      .catch(err => console.error('Error', err));
    }

    //Deze code wordt doorlopen als werkgever volledige leasebedrag vergoed. Bijdrage wordt op 100% gezet
    else if(this.state.selectedOption === 'Werkgever betaalt volledige leasebedrag') {
      this.props.firebase
      .user(this.props.match.params.id)
      .update({
        leasebudget: this.state.leasebudget,
        bijdrageWerkgeverType: this.state.selectedOption,
        bijdrageWerkgever: 'Volledige bedrag',
      })
      .then(() => {
        if(this.state.akkoord) {
          console.log('mail versturen naar werknemer')
          this.sendLeasebudgetEmailToWerknemer(event)
        }
      })
      .then(res => console.log('leasebudget succesvol aangepast', res))
      .catch(err => console.error('Error', err));
    }



    //update user activity
    const ref = this.props.firebase
    .user(this.props.match.params.id)

    const userActivity = ref.child('activity')

    userActivity.push({
      activity: 'Werkgever heeft leasebudget en andere bedragen aangepast',
      createdAt: new Date().toString(),
    })

    this.setState({
      bedragenIngevuld: true,
    })

    // this.setState({leasebuget: ''})
    event.preventDefault();

  };

  akkoordWerknemer = event => {
    this.props.firebase
    .user(this.props.match.params.id)
    .update({
      isAkkoord: true,
      phase: 'Werkgever akkoord',
    })
    .then(() => {
      this.sendEmailToWerknemer(event);
    })
    .then(res => {
      this.setState({
        success: 'U heeft deze werknemer succesvol akoord gegeven'});
    })
    .catch(err => {
      this.setState({
        error: 'Er ging iets mis bij het akkoord geven. Probeer het opnieuw.'});
    })

    //update user activity
    const ref = this.props.firebase
    .user(this.props.match.params.id)

    const userActivity = ref.child('activity')

    userActivity.push({
      activity: 'Werkgever heeft akkoord gegeven op deelname -> Naar fase Werkgever akkoord',
      createdAt: new Date().toString(),
    })

    event.preventDefault();
  }

  sendLeasebudgetEmailToWerknemer(event) {
  //Verstuur mail naar werkgever
    const templateId = 'notificatie_naar_werknemer_leasebudget_gewijzigd';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.email,
      name: this.state.username,
      leasebudget: this.state.leasebudget,
      bijdrageWerkgever: this.state.bijdrageWerkgever,
      bijdrageWerkgeverType: this.state.selectedOption,
    }

    this.sendFeedbackLeasebudgetWerknemer(templateId, template_params, userId)
  }

  sendFeedbackLeasebudgetWerknemer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  sendEmailToWerknemer(event) {
  //Verstuur mail naar werkgever
    const templateId = 'bevestiging_akkoord_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.email,
      name: this.state.username,
      leasebudget: this.state.leasebudget,
      bijdrageWerkgever: this.state.bijdrageWerkgever,
      bijdrageWerkgeverType: this.state.selectedOption,
      sqootId: this.state.sqootId,
    }

    this.sendFeedbackWerknemer(templateId, template_params, userId)
  }

  sendFeedbackWerknemer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const { user, loading, leasebudget, bijdrageWerkgever, selectedOption, bedragenIngevuld, akkoord, object, error, success } = this.state;

    const isInvalid =
      leasebudget === '' ||
      selectedOption === ''

    return (
      <div>
        <div className="Terug-naar-overzicht">
          <Link to={{
              pathname: `${ROUTES.WERKGEVER_DEELNEMERS}`,
              state: { user },
            }}
            >
              Terug naar deelnemers
          </Link>
        </div>
        {loading && <div>Loading...</div>}

        {!akkoord && user.leasebudget && <div className="Dealer-informeer-balk">
          <p className="Dealer-informeer-tekst">Klik op deze knop om de deelnemer akkoord te geven op deelname en aanmaken leasecontract.</p>
          <button onClick={this.akkoordWerknemer} >Akkoord deelname</button>
        </div>}

        {user && (
          <div className="Werknemer-pagina-werkgever">

            <div className="Werknemer-informatie">
              <h2>
                {user.username}
              </h2>
              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>E-mail:</strong></td><td>{user.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Telefoonnummer:</strong></td><td>{user.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td colSpan='2' className="Tabel-rij-titel"><h3>Fietsgegevens</h3></td>
                  </tr>
                  <tr>
                    <td><strong>Fiets:</strong></td>
                    <td><Link to={{
                    pathname: `${ROUTES.WERKGEVER}/object/${user.object}`,
                    state: { object, user },
                    }}
                    >
                      {user.object}
                    </Link></td>
                  </tr>
                  <tr>
                    <td><strong>Soort fiets:</strong></td>{object &&<td>{object.objectType}</td>}
                  </tr>
                  <tr>
                    <td><strong>Merk fiets:</strong></td>{object &&<td>{object.objectBrand}</td>}
                  </tr>
                  <tr>
                    <td><strong>Model fiets:</strong></td>{object &&<td>{object.objectModel}</td>}
                  </tr>
                  {object && <tr>
                    <td><strong>Bruto aanschafprijs:</strong></td><td>€{object.objectPrice}</td>
                  </tr>}
                  <tr>
                    <td colSpan='2' className="Tabel-rij-titel"><h3>Contractgegevens</h3></td>
                  </tr>
                  <tr>
                    <td><strong>Leasecontract:</strong></td><td>{user.downloadUrlLeaseContract &&  <a href={user.downloadUrlLeaseContract} target="_blank" rel="noopener noreferrer">Download leasecontract</a>
                    }</td>
                  </tr>

                  <tr>
                    <td><strong>Type bijdrage:</strong></td><td>{user.bijdrageWerkgeverType}</td>
                  </tr>
                  <tr>
                    <td><strong>Max. waarde fiets:</strong></td><td>€{user.leasebudget}</td>
                  </tr>
                  <tr>
                    <td><strong>Bijdrage werkgever:</strong></td><td>{user.bijdrageWerkgever}</td>
                  </tr>
                  <tr>
                    <td><strong>Bijdrage werknemer:</strong></td><td>€{user.monthlyLeaseFee}</td>
                  </tr>
                  <tr>
                    <td><strong>Bijtelling:</strong></td><td>€{user.bijtelling}</td>
                  </tr>
                  {akkoord &&
                  <tr>
                    <td><strong>Akkoord:</strong></td><td>Werknemer is akkoord voor deelname</td>
                  </tr> }
                </tbody>
              </table>
            </div>
            <div className="Leasebudget-instellen">
              <h2>Vul bedragen in</h2>
              <p>Hieronder vult u de maximale waarde in van de fiets die deze werknemer mag kiezen.</p>

              <form onSubmit={this.updateLeasebudget}>
              <label>Maximale waarde fiets</label>
                <input
                  name = 'leasebudget'
                  value = {leasebudget}
                  onChange = {this.onChange}
                  type = 'text'
                  placeholder = {leasebudget}
                />
              <div className="Bijdrage-werkgever">
                <label>Bijdrage werkgever</label>
                <p className="Bijdrage-werkgever-p">Hieronder selecteert u hoe u als werkgever bijdraagt. Indien u kiest voor gedeeltijke bijdrage, dan moet u ook het bedrag invullen.</p>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="selectedOption"
                    value="Werkgever betaalt volledige leasebedrag"
                    checked={selectedOption === "Werkgever betaalt volledige leasebedrag"}
                    onChange={this.onChange}
                    className="form-check-input"
                  />
                  Werkgever betaalt volledige leasebedrag
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="selectedOption"
                    value="Werkgever faciliteert (geen bijdrage vanuit werkgever)"
                    checked={selectedOption === "Werkgever faciliteert (geen bijdrage vanuit werkgever)"}
                    onChange={this.onChange}
                    className="form-check-input"
                  />
                  Werkgever faciliteert (geen bijdrage vanuit werkgever)
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="selectedOption"
                    value="Werkgever betaalt gedeeltelijk"
                    checked={selectedOption === "Werkgever betaalt gedeeltelijk"}
                    onChange={this.onChange}
                    className="form-check-input"
                  />
                  Werkgever betaalt gedeeltelijk
                </label>
              </div>
                {selectedOption === 'Werkgever betaalt gedeeltelijk' && <input
                  name = 'bijdrageWerkgever'
                  value = {bijdrageWerkgever}
                  onChange = {this.onChange}
                  type = 'text'
                  placeholder = 'Vul hier de maandelijkse bijdrage vanuit de werkgever in'
                  className= "input-marge"
                />}

                <button disabled={isInvalid} type='submit' className="Button-marge">Stel bedragen in</button>
                {bedragenIngevuld && <p>De bedragen zijn succesvol aangepast. U kunt de aanvraag via bovenstaande groene knop bevestigen.</p>}

              </form>

              {error && <p>{error}</p>}
              {success && <p>{success}</p>}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default WerkgeverDeelemerDetailBase;
