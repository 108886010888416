import React from 'react';
// import { Link } from 'react-router-dom';
// import { AuthUserContext } from '../Session';

// import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';

import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';

import './Home.css';

const HomePage = () => (
  <div className="Page">
    <h1>HomePage</h1>
    <p>Welkom op de sQoot & Bike portal. Kies een optie uit het menu om verder te gaan.</p>

  </div>
);

// const HomePage = () => (
//   <AuthUserContext.Consumer>
//   {authUser => (
//   <div className="Page">
//     <h1>HomePage</h1>
//     <p>Welkom op de sQoot & Bike portal. Kies een optie uit het menu om verder te gaan.</p>
//
//     {!!authUser.roles[ROLES.WERKNEMER] && (
//       <div className="Homepage-buttons">
//       <Link to={{
//         pathname: `${ROUTES.WERKNEMER}`,
//         state: { object: authUser.object, uid:authUser.uid }
//       }}
//       >
//         <div className="Melding-button">
//           <h3>Dashboard</h3>
//         </div>
//       </Link>
//
//       <Link to={{
//         pathname: `${ROUTES.WERKNEMER}/object/${authUser.object}`,
//         state: { object: authUser.object, uid:authUser.uid }
//       }}
//       >
//         <div className="Melding-button">
//           <h3>Objectgegevens</h3>
//         </div>
//       </Link>
//
//       <Link to={{
//         pathname: `${ROUTES.WERKNEMER}/service`,
//         state: { object: authUser.object, uid:authUser.uid }
//       }}
//       >
//         <div className="Melding-button">
//           <h3>Melding maken</h3>
//         </div>
//       </Link>
//       </div>
//     )}
//
//   </div>
// )}
//   </AuthUserContext.Consumer>
// );



// class MessagesBase extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       loading: false,
//       messages: [],
//       text: '',
//     };
//   }
//
//   componentDidMount() {
//     this.setState({loading: true})
//
//     this.props.firebase.messages().on('value', snapshot => {
//
//       const messageObject = snapshot.val();
//
//       if(messageObject) {
//         // convert messages list from snapshot
//         const messageList = Object.keys(messageObject).map(key => ({
//           ...messageObject[key],
//           uid:key,
//         }))
//
//         this.setState({
//           loading:false,
//           messages: messageList,
//         });
//       } else {
//         this.setState({
//           messages: null,
//           loading: false,
//         })
//       }
//
//       this.setState({loading: false})
//     })
//   }
//
//   componentWillUnmount() {
//     this.props.firebase.messages().off();
//   }
//
//   onChangeText = event => {
//     this.setState({
//       text: event.target.value
//     })
//   }
//
//   onCreateMessage = (event, authUser) => {
//     this.props.firebase.messages().push({
//       text: this.state.text,
//       userId: authUser.uid,
//     });
//
//     this.setState({ text:'' })
//
//     event.preventDefault();
//   }
//
//   render() {
//     const { text, messages, loading } = this.state;
//
//     return (
//       <AuthUserContext.Consumer>
//         {authUser => (
//           <div>
//             {loading && <div>Loading...</div>}
//
//             {messages ? (
//               <MessageList messages={messages}/>
//             ) : (
//               <div>Er zijn geen berichten.</div>
//             )}
//
//             <form onSubmit = {event => this.onCreateMessage(event, authUser)} >
//               <input
//                 type='text'
//                 value={text}
//                 onChange={this.onChangeText}
//               />
//               <button type='submit'>Verstuur</button>
//             </form>
//
//           </div>
//         )}
//       </AuthUserContext.Consumer>
//     )
//   }
// }
//
// const MessageList = ({ messages }) => (
//   <ul>
//     {messages.map(message => (
//       <MessageItem key={message.uid} message={message} />
//     ))}
//   </ul>
// )
//
// const MessageItem = ({ message }) => (
//   <li>
//     <strong>{message.userId}</strong>{message.text}
//   </li>
// )
//
// const Messages = withFirebase(MessagesBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
