import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import Autocomplete from './Autosuggestion';

import "./SignUpWerknemer.css";

const SignUpWerknemerPage = () => (
  <div className="Page">
    <h1>Aanmelden werknemer</h1>
    <div className="SignUpWerknemerForm">
      <SignUpWerknemerForm />

    </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  uid: '',
  // linkToWerknemer: 'http://localhost:3000/werkgever/werknemer/',
  email: '',
  phoneNumber: '',
  passwordOne: '',
  passwordTwo: '',
  address: '',
  place: '',
  zipCode: '',
  company: '',
  companyContactEmail: '',
  companyContact: '',
  companies: [],
  leasebudget: '',
  monthlyLeaseFee: '',
  bijtelling: '',
  bijdrageWerkgeverType: '',
  bijdrageWerkgever: '',
  dealer: '',
  isAkkoord: false,
  activity: {
    aanmelden: {
      activity: "Werknemer heeft zich aangemeld -> Naar fase Nieuw",
      createdAt: new Date().toString(),
    }
  },
  phase: 'Nieuw',
  value: '',
  isWerknemer: true,
  error: null,
  listDataFromChild: null,
}

class SignUpWerknemerFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { username,
            email,
            phoneNumber,
            passwordOne,
            address,
            place,
            zipCode,
            company,
            leasebudget,
            bijdrageWerkgeverType,
            bijdrageWerkgever,
            dealer,
            monthlyLeaseFee,
            bijtelling,
            isAkkoord,
            activity,
            phase,
            isWerknemer} = this.state;

    const roles = {};

    if(isWerknemer) {
      roles[ROLES.WERKNEMER] = ROLES.WERKNEMER;
    }

  this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      //Create a user in Firebase realtime database
      return this.props.firebase
      .user(authUser.user.uid)
      .set({username, email, roles, address, phoneNumber, place, zipCode, company, leasebudget, bijtelling, monthlyLeaseFee, bijdrageWerkgeverType, bijdrageWerkgever, dealer, isAkkoord, activity, phase, })
    })
    .then(() => {
      return this.props.firebase.doSendEmailVerification();
    })
    .then(() => {
      this.sendEmailToWerkgever(event);
      this.sendEmailToWerknemer(event);
    })
    .then(authUser => {
      this.setState({...INITIAL_STATE});
      this.props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      this.setState({error});
    });

    event.preventDefault();

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  sendEmailToWerkgever(event) {
  //Verstuur mail naar werkgever
    const templateId = 'aanmelding_werknemer_bevestiging_naar_werkgever';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.companyContactEmail,
      usernameWerknemer: this.state.username,
      emailWerknemer: this.state.email,
      companyContact: this.state.companyContact,
      linkToWerknemer: this.state.linkToWerknemer,
    }

    this.sendFeedbackWerkgever(templateId, template_params, userId)
  }

  sendFeedbackWerkgever(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  sendEmailToWerknemer(event) {
  //Verstuur bevestiging van aanmelding naar werknemer
    const templateId = 'aanmelding_werknemer_bevestiging_naar_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.email,
      usernameWerknemer: this.state.username,
      userAddress: this.state.address,
      userPhoneNumber: this.state.phoneNumber,
      userZipCode: this.state.zipCode,
      userPlace: this.state.place,
      company: this.state.company,
    }

    this.sendFeedbackWerknemer(templateId, template_params, userId)
  }

  sendFeedbackWerknemer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  // onWerkgeverSelectChange = event => {
  //   console.log("werkgever changed");
  //   this.setState({company: event.target.value})
  //
  //   this.props.firebase
  //   .company(event.target.value) //haalt de company gegevens op na selectie van werkgever
  //   .on('value', snapshot => {
  //     this.setState({
  //       companyContactEmail: snapshot.val().companyContactEmail, //selecteert emailadres contactpersoon werkgever
  //       companyContact: snapshot.val().companyContact, //selecteert contactpersoon werkgever
  //       loading: false,
  //     });
  //   });
  // }


  componentDidMount() {

    this.props.firebase.companies().on('value', snapshot => {
      const companiesObject = snapshot.val();

      const companiesList = Object.keys(companiesObject).map(key => ({
        ...companiesObject[key],
        uid: key,
      }));

      this.setState({
        companies: companiesList,
        // company: this.props.match.params.id, //dit zet het bedrijf van werknemer automatisch op basis van URL
        loading: false,
      });
  }
)};

  componentWillUnmount() {
    this.props.firebase.companies().off();
  }

  onWerkgeverSelectChange = (dataFromChild) => {
    // this.setState({
    //   company: dataFromChild
    // })

    this.setState({company: dataFromChild})

    this.props.firebase
    .company(dataFromChild) //haalt de company gegevens op na selectie van werkgever
    .on('value', snapshot => {
      this.setState({
        companyContactEmail: snapshot.val().companyContactEmail, //selecteert emailadres contactpersoon werkgever
        companyContact: snapshot.val().companyContact, //selecteert contactpersoon werkgever
        loading: false,
      });
    });

  }

  render() {

    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      address,
      phoneNumber,
      place,
      zipCode,
      company,
      companies,
      // value,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      address === '' ||
      zipCode === '' ||
      place === '' ||
      email === '' ||
      username === '' ||
      company === '';

    return(
      <form onSubmit={this.onSubmit} id='aanmelden-werknemer' className="SignUpForm">
        <label>Naam</label>
        <input
        name = 'username'
        value = {username}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Mailadres</label>
        <input
        name = 'email'
        value = {email}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Telefoonnummer</label>
        <input
        name = 'phoneNumber'
        value = {phoneNumber}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Wachtwoord</label>
        <input
        name = 'passwordOne'
        value = {passwordOne}
        onChange = {this.onChange}
        type = 'password'
        />
        <label>Bevestig wachtwoord</label>
        <input
        name = 'passwordTwo'
        value = {passwordTwo}
        onChange = {this.onChange}
        type = 'password'
        />
        <label>Adres</label>
        <input
        name = 'address'
        value = {address}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Postcode</label>
        <input
        name = 'zipCode'
        value = {zipCode}
        onChange = {this.onChange}
        type = 'text'
        />
        <label>Plaats</label>
        <input
        name = 'place'
        value = {place}
        onChange = {this.onChange}
        type = 'text'
        />
        {/*<input
        name = 'company'
        value = {this.props.match.params.id}
        type = 'text'
        onSubmit = {this.onChange}
        />*/}

        <label>Selecteer jouw werkgever</label>
        <span className="Toelichting-werkgever-keuze">Vul hieronder uw bedrijfsnaam in. Nadat u begint met typen, verschijnt een dropdown lijst met werkgevers. Selecteer uit deze lijst uw werkgever.</span>
        {/*<div>
        <select
          name = 'company'
          value={company}
          onChange={this.onWerkgeverSelectChange}
        >
          <option value={value} disabled>
            Maak een keuze
          </option>
          {companies.map(company => {
            return (
              <option
                value={company.uid}
                key={company.uid}
              >
                {company.uid}
              </option>
            );
          })}
        </select>
        </div>*/}
        <Autocomplete name='company' callbackFromParent={this.onWerkgeverSelectChange} suggestions={companies.map(company => {
          return company.companyName;
        })} />

        <button disabled={isInvalid} type='submit'>Aanmelden</button>

        {error && <p>{error.message}</p>}

      </form>
    )
  }
}

const SignUpWerknemerLink = () => (
  <p>
    Heb je nog geen account? <Link to={ROUTES.SIGN_UP_WERKNEMER}>Meld je hier aan</Link>
  </p>
)

const SignUpWerknemerForm = compose(
  withRouter,
  withFirebase,
)(SignUpWerknemerFormBase);

export default SignUpWerknemerPage;

export { SignUpWerknemerForm, SignUpWerknemerLink };
