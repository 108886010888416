import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';

import './Objects.css';

class InsuranceItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      id: '',
      user: '',
      insurance: '',
      // users: '',
      insuranceName: '',
      // ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.object) {
    //   return;
    // }

    this.setState({
      loading: true
    });

    this.props.firebase
    .insurance(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        insurance: snapshot.val(),
        loading: false,
      });
    })

    // this.props.firebase
    //   .user(this.state.id)
    //   .on('value', snapshot => {
    //     this.setState({
    //       user: snapshot.val(),
    //       loading: false,
    //     });
    //   });
}

  componentWillUnmount() {
    this.props.firebase.object(this.props.match.params.id).off();
    // this.props.firebase.user(this.state.uid).off();
  }

  render() {
    const { insurance, loading } = this.state;


    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug naar object</button>
        <div className="Object">
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}
            {insurance && (
              <table>
                <tbody>
                  <tr>
                    <td><strong>Verzekering</strong></td><td>{insurance.insuranceName}</td>
                  </tr>
                  <tr>
                    <td><strong>Verzekeraar</strong></td><td>{insurance.verzekeraar}</td>
                  </tr>

                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InsuranceItemBase);
