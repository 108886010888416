import React , { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class WerknemerItemEditBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      username: '',
      phoneNumber: '',
      address: '',
      zipCode: '',
      place: '',
      gegevensGewzijgd: false,
      uid: '',
      error: '',
      activity: [],
      date: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
    });

    this.props.firebase
    .user(this.state.uid)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        username: snapshot.val().username,
        phoneNumber: snapshot.val().phoneNumber,
        address: snapshot.val().address,
        zipCode: snapshot.val().zipCode,
        place: snapshot.val().place,
        loading: false,
      });
    });
}

  componentWillUnmount() {
    // this.props.firebase.user(this.props.match.params.id).off();
    // this.props.firebase.dealers().off();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  onSubmit = event => {
    this.props.firebase
    .user(this.state.uid)
    .update({
      username: this.state.username,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      zipCode: this.state.zipCode,
      place: this.state.place,
    })
    .then(res => console.log('Gegevens succesvol aangepast', res))
    .catch(err => console.error('Error', err));

    //update user activity
    // const ref = this.props.firebase
    // .user(this.state.uid)
    //
    // const userActivity = ref.child('activity')
    //
    // userActivity.push({
    //   activity: 'Gegevens door werknemer gewijzigd.',
    //   createdAt: new Date().toString(),
    // })

    this.setState({
      gegevensGewzijgd: true,
    })


    event.preventDefault();
  }

  render() {
    const { user, username, phoneNumber, address, zipCode, place, gegevensGewzijgd, loading } = this.state;
    console.log('USER', user)

    return (
      <div>
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.WERKNEMER}`,
            // state: { user },
          }}
          >
            Terug naar overzicht
        </Link>
      </div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {user && (
          <div className="Werknemer-gegevens-edit">
            <div className="Werknemer-details">
            <div className="Werknemer-details-column">
            <h3>Peroonsgegevens</h3>
            <form onSubmit={this.onSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td><strong>Naam:</strong></td>
                    <td>
                    <input
                      name = 'username'
                      value = {username}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {username}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Telefoonnummer:</strong></td>
                    <td>
                    <input
                      name = 'phoneNumber'
                      value = {phoneNumber}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {phoneNumber}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Adres:</strong></td>
                    <td>
                    <input
                      name = 'address'
                      value = {address}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {address}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Postcode:</strong></td>
                    <td>
                    <input
                      name = 'zipCode'
                      value = {zipCode}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {zipCode}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Plaats:</strong></td>
                    <td>
                    <input
                      name = 'place'
                      value = {place}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {place}
                    /></td>
                  </tr>
                </tbody>
              </table>
              <button type='submit'>Opslaan</button>
              {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </form>
            </div>

          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(WerknemerItemEditBase);
