import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';

import './Objects.css';

class OnderhoudsFacturenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      invoices: '',
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    // .company(this.props.company.companyName)
    .object(this.props.object)
    .on('value', snapshot => {

    const invoicesObject = snapshot.val().onderhoudsfacturen;

    if(invoicesObject) {
      const invoicesList = Object.keys(invoicesObject).map(key => ({
        ...invoicesObject[key],
        uid: key,
      })
    );

      this.setState({
        object: snapshot.val(),
        loading:false,
        invoices: invoicesList
      })
    } else {
      this.setState({
        loading: false
      })
    }
  })

    // this.setState({
    //   loading: true
    // });
    //
    // const maintenanceInvoices = this.props.firebase.maintenances(this.props.object); //lijst binnenhalen van onderhoudsfacturen van object
    // console.log('maintenanceInvoices', maintenanceInvoices)
    // maintenanceInvoices.listAll().then((list) => {
    //
    //   const invoiceList = []
    //
    //   list.items.forEach(item => {
    //
    //     item.getDownloadURL().then((url) => {
    //
    //       const invoice = {
    //         name: item.name,
    //         downloadURL: url
    //       }
    //
    //       invoiceList.push(invoice)
    //
    //       this.setState({
    //         invoiceList: invoiceList,
    //         loading: false,
    //       })
    //     })
    //   })
    // })
    //
    // this.setState({
    //   loading: false
    // });
}


  render() {
    const { invoices, loading } = this.state;

    return (
      <div>
            {loading && <div>Loading...</div>}
            {invoices && (

              <table className="Tabel-full-width">
                <thead>
                <tr>
                  <th>Factuur datum</th><th>Bedrag</th><th>Download</th>
                </tr>
                </thead>
                <tbody>
                  {Object.values(invoices)
                    .map(invoice => (
                  <tr key={invoice.uid}>
                    {invoice.invoiceDate && <td >{invoice.invoiceDate.slice(4,15)}</td>}
                    <td>€{invoice.invoiceAmount}</td>
                    <td><a href={invoice.downloadMaintenanceUrl} target="_blank" rel="noopener noreferrer">Download Factuur</a></td>
                  </tr>
                ))}
              </tbody>
              </table>
            )}
            {!invoices && <p>Er zijn nog geen onderhoudsfacturen voor dit object.</p>}
      </div>
    )
  }
}

export default withRouter(OnderhoudsFacturenBase);
