import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import './Dealer.css';

class MeldingenUsersNoInvoiceBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      uid: '',
      user: '',
      company:'',
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
        <div>
        <div className="Dealer-klanten">
          <h2>Definitieve factuur nog uploaden</h2>
          {loading && <div>Loading...</div>}
          <table className="Tabel-full-width">
            <tbody>
              <tr>
                <th>Naam</th><th>E-mailadres</th><th>Telefoonnummer</th><th>Bedrijf</th><th>Definitieve factuur geupload</th><th>Details</th>
              </tr>
              {users
                .filter(user => {
                  return user.dealer === authUser.dealerName
                })
                .filter(user => {
                  return !user.downloadUrl
                })
                .map(user => (
                  <tr key={user.uid}>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.phoneNumber}</td>
                      <td>{user.company}</td>
                      {user.downloadUrl && <td>Ja</td>}
                      {!user.downloadUrl && <td className="Rood">Nee</td>}
                      {/*{user.readyForPickUp && !user.startDateContract && <button className="Ophalen-button" onClick={this.bikeIsPickedUp} name={user.uid}>Opgehaald door klant</button>}*/}
                      <td><Link to={{
                        pathname: `${ROUTES.DEALER}/user/${user.uid}`,
                        state: { user },
                      }}
                      >
                        <strong>Details</strong>
                      </Link></td>

                  </tr>
              ))}

            </tbody>
          </table>
      </div>
      </div>
    )}
  </AuthUserContext.Consumer>
    )}
}

export default MeldingenUsersNoInvoiceBase;
