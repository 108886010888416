import React , { Component } from 'react';
import { format } from 'date-fns';

import { AuthUserContext } from '../Session';

class OnderhoudsFactuurUploadenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      downloadMaintenanceUrl: '',
      uid: '',
      dealer: '',
      invoiceAmount: '',
      invoice: '',
      invoiceBike: '',
      object: '',
      invoices: '',
      value: '',
      success: '',
      successUploadInvoice: '',
      error: '',
      activity: [],
      date: '',
      visibility: true,
      // ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
    });

    this.props.firebase
    .user(this.props.uid)
    .once('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        // uid: this.props.uid,
        loading: false,
      });
    })
    .then(() => {
      this.props.firebase
      .object(this.props.object)
      .once('value', snapshot => {
        this.setState({
          object: snapshot.val(),
          loading: false,
        });
      })}
    )
  }


  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onUpload = event => {
    this.setState({
      invoice: event.target.files[0],
    })
  }

  handleSubmit = event => {

      const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

      const uploadTask = this.props.firebase.maintenance(this.state.invoice, this.props.object, date);
      uploadTask.on('state_changed', snapshot => {

      }, error => {
          this.setState({
            error: true
          })

      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

          this.setState({
            downloadMaintenanceUrl: downloadURL,
          })

        this.setMeldingToDone();
        this.pushInvoiceToObject();

        })
    })

    event.preventDefault();
  }

  setMeldingToDone() {
    this.props.firebase
    .serviceaanvraag(this.props.object, this.props.meldingUid)
    .update({
      aanvraagAfgerond:true,
    })
  }

  pushInvoiceToObject(event) {
  const ref = this.props.firebase
  .object(this.props.object)

  const objectInvoices = ref.child('onderhoudsfacturen')
  console.log('objectInvoices', objectInvoices)

  objectInvoices.push({
  invoiceDate: new Date().toString(),
  invoiceAmount: this.state.invoiceAmount,
  invoice: this.state.invoice,
  downloadMaintenanceUrl: this.state.downloadMaintenanceUrl,

  })

  this.sendMaintenanceEmailToBackoffice(event);

  console.log('push uitgevoerd')

  this.setState({
    success: true
  })

  //set state to normal
    this.setState({
      invoiceAmount: '',
      invoice: '',
      downloadMaintenanceUrl: '',
      error: false,
    })
  }

  sendMaintenanceEmailToBackoffice(event) {
  //Verstuur mail naar BO
    const templateId = 'notificatie_naar_backoffice_onderhoudsfactuur_geupload';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      object: this.props.object,
      linkToObject: `https://portal.sqoot.nl/objecten/object/${this.props.object}`
    }

    this.sendMaintenanceFeedbackBackoffice(templateId, template_params, userId)
  }

  sendMaintenanceFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const { user, invoiceAmount, invoice, loading, success } = this.state;

    const isInvalid =
      invoiceAmount === '' ||
      invoice === '';

    return (
      <AuthUserContext.Consumer>
      {authUser => (
      <div>

        {loading && <div>Loading...</div>}

        {user &&  (
          <div>
            <form>
            <label>Factuur bedrag</label>
              <input
                name = 'invoiceAmount'
                value = {invoiceAmount}
                onChange = {this.onChange}
                type = 'text'
              />
              <label>Upload factuur: </label>
              <div className="Upload-facturen">
              <input
                name = 'file'
                type = 'file'
                onChange = {this.onUpload}
              />
              </div>
              <button disabled={isInvalid} onClick={this.handleSubmit}>Verzenden</button>
              {success &&  <p>De factuur is succesvol geupload.</p>}
            </form>
          </div>
        )}

      </div>)}
      </AuthUserContext.Consumer>
    )
  }
}



export default OnderhoudsFactuurUploadenBase;
