import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class CompanyInvoicesBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      company: '',
      companyName: '',
      invoices: '',
      ...props.location.state
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    // .company(this.props.company.companyName)
    .company(this.props.company)
    .on('value', snapshot => {

    const invoicesObject = snapshot.val().invoices;

    if(invoicesObject) {
      const invoicesList = Object.keys(invoicesObject).map(key => ({
        ...invoicesObject[key],
        uid: key,
      })
    );

      this.setState({
        company: snapshot.val(),
        loading:false,
        invoices: invoicesList
      })
    } else {
      this.setState({
        loading: false
      })
    }
  })
}


  render() {
    const { loading, company, invoices } = this.state;
    // console.log('THIS.PROPS.COMPANY', this.props.company)

    return (
      <div>
            {loading && <div>Loading...</div>}
            {company.invoices && (
              <table className="Tabel-full-width">
                <th>Factuur datum</th><th>Factuur bedrag</th><th>Download</th>
                <tbody>
                  {Object.values(invoices)
                    .map(invoice => (
                  <tr key={invoice.uid}>
                    {invoice.invoiceDate && <td >{invoice.invoiceDate.slice(4,15)}</td>}
                    <td>€{invoice.invoiceAmount},-</td>
                    <td><a href={invoice.downloadInvoiceUrl} target="_blank" rel="noopener noreferrer">Download Factuur</a></td>
                  </tr>
                ))}
              </tbody>
              </table>
            )}
            {!company.invoices && <p>Er zijn nog geen facturen voor dit bedrijf.</p>}
      </div>
    )
  }
}

export default withRouter(CompanyInvoicesBase);
