import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
// import ObjectsDeelnemersList from './ObjectsDeelnemersList';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import './Objects.css';

class ObjectsListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      objects:[],
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "objectName",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.OBJECTS}/object/${params.value}`,
              state: { object: params.data },
            }}
            >
              {params.value}
            </Link>
      }
    }, {
      headerName: "Bedrijf", field: "company",
      cellRendererFramework: (params) => {
        return <Link to={{
            pathname: `${ROUTES.COMPANIES}/company/${params.value}`,
            // state: { company: params.data },
          }}
          >
            {params.value}
          </Link>
    }
  }, {
        headerName: "Actief", field: "active", width:"150",
        cellRendererFramework: (params) => {
          if(params.value) {
            return <p className="Check-ok">V</p>
          } else {
            return <p className="Check-not-ok">X</p>;
          }
    }
  }, {
    headerName: "Status", field: "status"
  },
    {
        headerName: "Poolfiets", field: "poolObject", width:"150",
        cellRendererFramework: (params) => {
          if(params.value) {
            return <p className="Check-ok">V</p>
          } else {
            return <p className="Check-not-ok">X</p>;
          }
      }
      }, {
        headerName: "Merk", field: "objectBrand"
      }, {
        headerName: "Type", field: "objectType"
      }, {
          headerName: "Verzekering", field: "insurance", width:"150",
          cellRendererFramework: (params) => {
            if(params.value) {
              return <p className="Check-ok">V</p>
            } else if(!params.value && !params.data.insuranceInAanvraag) {
              return <p className="Check-not-ok">X</p>;
            } else if(!params.value && params.data.insuranceInAanvraag) {
              return <p className="Check-not-ok-orange">X</p>;
            }
        }, filter: false
      }, {
          headerName: "Pechhulp", field: "pechhulp", width:"150",
          cellRendererFramework: (params) => {
            if(params.value) {
              return <p className="Check-ok">V</p>
            } else if(!params.value && !params.data.pechhulpInAanvraag) {
              return <p className="Check-not-ok">X</p>;
            } else if(!params.value && params.data.pechhulpInAanvraag) {
              return <p className="Check-not-ok-orange">X</p>;
            }
        }, filter: false,
      }, {
          headerName: "Dealer geïnformeerd", field: "readyForPickUp",
          cellRendererFramework: (params) => {
            if(params.value) {
              return <p className="Check-ok">V</p>
            } else {
              return <p className="Check-not-ok">X</p>;
            }
        }
      }],
        defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
      },
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase.objects()
    .on('value', snapshot => {
      const objectsObject = snapshot.val();

      const objectsList = Object.keys(objectsObject).map(key => ({
        ...objectsObject[key],
        uid: key,
      }));

      this.setState({
        objects: objectsList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.objects().off();
  }

render() {
  const { objects, loading } = this.state;

  return (
    <div className="Objects">
    <div className="Poolobject-aanmaken-link">
      <Link to={{
          pathname: `${ROUTES.OBJECTS}/pool/nieuw`,
          // state: { object },
        }}
        >
          Maak poolobject aan
      </Link>
    </div>
      {objects && <div className="Non-active-objects">
        <h2>Objecten</h2>
        {loading && <div>Loading...</div>}
        <div
          className="ag-theme-alpine"
          style={{
          height: '700px',
          }}
        >
          <AgGridReact
            reactNext={true} //
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            paginationPageSize={this.state.paginationPageSize}
            paginationAutoPageSize={true}
            rowData={objects.sort((a,b) => parseInt(a.uid.slice(7)) - parseInt(b.uid.slice(7)))}>
          </AgGridReact>
        </div>
          {/*<ObjectsDeelnemersList
            objects={Object.values(objects)
            .filter(object => {
              return object.active === false
            })}

            />*/}
      </div>}

      {/*objects && <div className="Active-objects">
        <h2>Actieve objecten</h2>
        {loading && <div>Loading...</div>}
          <ObjectsDeelnemersList
            objects={Object.values(objects)
            .filter(object => {
              return object.active === true && object.status === 'In gebruik'
            })}

            />
      </div>*/}

      {/*objects && <div className="Active-objects">
        <h2>Verkochte objecten</h2>
        {loading && <div>Loading...</div>}
          <ObjectsDeelnemersList
            objects={Object.values(objects)
            .filter(object => {
              return object.status === 'Verkocht'
            })}

            />
      </div>*/}

      {/*objects && <div className="Active-objects">
        <h2>Voorraad sQoot & Bike</h2>
        {loading && <div>Loading...</div>}
          <ObjectsDeelnemersList
            objects={Object.values(objects)
            .filter(object => {
              return object.status === 'Voorraad sQoot & Bike'
            })}

            />
      </div>*/}

      {/*objects && <div className="Active-objects">
        <h2>Gestolen fietsen</h2>
        {loading && <div>Loading...</div>}
          <ObjectsDeelnemersList
            objects={Object.values(objects)
            .filter(object => {
              return object.status === 'Gestolen'
            })}

            />
      </div>*/}
    </div>
  );
}
}

export default ObjectsListBase;
