import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';

import DatePicker from 'react-datepicker';

class ChangeContractDateBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      startDateContract: '',
      endDateContract: '',
      gegevensGewzijgd: false,
      uid: '',
      error: '',
      activity: [],
      date: '',
      ...props.location.state,
    };
  }

  handleDateChangeStartDate = date => {
    this.setState({
      startDateContract: date,
    })
  }

  handleDateChangeEndDate = date => {
    this.setState({
      endDateContract: date,
    })
  }

  onSubmit = event => {

    this.props.firebase
      .user(this.state.uid)
      .update({
        startDateContract: this.state.startDateContract.toString(),
        endDateContract: this.state.endDateContract.toString(),
      })


      //update user activity
      const ref = this.props.firebase
      .user(this.state.uid)

      const userActivity = ref.child('activity')

      userActivity.push({
        activity: 'Nieuwe start- en einddatum contract ingesteld door Backoffice.',
        createdAt: new Date().toString(),
      })

    this.setState({
      gegevensGewzijgd: true,
    })

    event.preventDefault();
  }

  render() {
    const { user, startDateContract, endDateContract, gegevensGewzijgd, loading } = this.state;
    // console.log('UID', this.state.uid)
    // console.log('LeaseContract', leaseContract)
    // console.log('startDateContract', startDateContract)
    // console.log('endDateContract', endDateContract)

    const isInvalid = startDateContract === '' | endDateContract === '';


    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {user  && (
          <div className="Werknemer-gegevens-edit">
            <div className="Werknemer-details">
            <div className="Werknemer-details-column">
            <h3>Start- en einddatum contract</h3>
            <p>Vul de nieuwe start- en einddatum in.</p>
            <form onSubmit={this.onSubmit}>
              <div>
                <label>Nieuwe startdatum contract</label>
                <div>
                  <DatePicker selected={this.state.startDateContract} onChange = {this.handleDateChangeStartDate} />
                </div>
                <label>Nieuwe einddatum contract</label>
                <div>
                  <DatePicker selected={this.state.endDateContract} onChange = {this.handleDateChangeEndDate} />
                </div>
              </div>
              <div>
                <button disabled={isInvalid} type='submit' className="Button-marge">Opslaan</button>
              </div>
              {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </form>
            </div>

          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(ChangeContractDateBase);
