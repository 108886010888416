// export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_LIST_VIEW = '/admin/list';
export const ADMIN_DETAILS = '/admin/user/:id';
export const ADMIN_DETAILS_EDIT = '/admin/user/edit/:id';
export const ADMIN_CONTRACT_DATE_EDIT = '/admin/user/contractdate/:id';
export const ADMIN_MELDINGEN = '/admin/meldingen';
export const ADMIN_END_CONTRACT = '/admin/contract';
export const ADMIN_CHANGE_OBJECT = '/admin/change-object';
export const PASSWORD_FORGET = '/pw-forget';
export const DEALER = '/dealer';
export const DEALER_USER_DETAIL = '/dealer/user/:id';
export const DEALER_USERS_LIST = '/dealer/users';
export const DEALER_MELDINGEN = '/dealer/meldingen';
export const DEALER_DETAIL_SERVICEAANVRAAG = '/dealer/meldingen/serviceaanvraag/:id';
export const DEALERS = '/dealers'
export const DEALERS_DETAIL = '/dealers/dealer/:id';
export const WERKNEMER = '/werknemer';
export const WERKNEMER_OBJECT = '/werknemer/object/:id';
export const WERKNEMER_SERVICE = '/werknemer/service';
export const WERKNEMER_SERVICE_SERVICE_FORMULIER = '/werknemer/service/service-formulier';
export const WERKNEMER_SERVICE_SCHADE_FORMULIER = '/werknemer/service/schade-formulier';
export const WERKNEMER_SERVICE_PECH_FORMULIER = '/werknemer/service/pech-formulier';
export const WERKNEMER_SERVICE_DIEFSTAL_FORMULIER = '/werknemer/service/diefstal-formulier';
export const WERKNEMER_EDIT = '/werknemer/edit';
export const WERKGEVER = '/werkgever';
export const WERKGEVER_WERKNEMER = '/werkgever/werknemer/:id';
export const WERKGEVER_DEELNEMERS = '/werkgever/deelnemers';
export const WERKGEVER_OBJECT = '/werkgever/object/:id';
export const WERKGEVER_FACTUREN = '/werkgever/facturen';
export const WERKGEVER_POOL_OBJECTS = '/werkgever/poolobjecten';
export const WERKGEVER_POOL_OBJECT_DIEFSTAL_FORMULIER = '/werkgever/diefstalformulier';
export const WERKGEVER_POOL_OBJECT_SCHADE_FORMULIER = '/werkgever/schadeformulier';
export const WERKGEVER_EDIT_POOLOBJECT = '/werkgever/object/:id/edit';
export const OBJECTS = '/objecten';
export const OBJECTS_DETAIL = '/objecten/object/:id';
export const OBJECTS_DETAIL_EDIT = '/objecten/object/edit/:id';
export const OBJECTS_DETAIL_SCHADEMELDING = '/objecten/object/schademelding/:id';
export const OBJECTS_DETAIL_SERVICEAANVRAAG = '/objecten/object/serviceaanvraag/:id';
export const OBJECTS_DETAIL_DIEFSTALMELDING = '/objecten/object/diefstalmelding/:id';
export const OBJECTS_OBJECT_STOLEN = '/objecten/object/gestolen';
export const OBJECTS_CREATE = '/objecten/nieuw';
export const OBJECTS_POOL_CREATE = '/objecten/pool/nieuw';
export const INSURANCE_CREATE = '/objecten/verzekering';
export const INSURANCE_DETAIL = '/objecten/verzekering/:id';
export const PECHHULP_CREATE = '/objecten/pechhulp';
export const PECHHULP_DETAIL = '/objecten/pechhulp/:id';
export const LEASEFEE_CREATE = '/objecten/leasebedrag';
export const BIJTELLING_CREATE = '/objecten/bijtelling';
export const SIGN_UP_DEALER = '/signupdealer';
export const SIGN_UP_WERKNEMER = '/signupwerknemer';
export const SIGN_UP_WERKGEVER = '/signupwerkgever';
export const COMPANIES = '/companies';
export const COMPANIES_DETAIL = '/companies/company/:id';
export const CONFIRM_WERKNEMER = '/confirm/:id';
