import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './Objects.css';

class CreatePechhulpBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // user: '',
      // uid: '',
      pechpasNumber: '',
      inAanvraag: false,
      pechpasNumberCreated: false,
      object: null,
      loading: false,
      error: '',
      value: '',
      ...props.location.state,
    }
  }

onSubmit = event => {
  const { pechpasNumber, object, inAanvraag } = this.state;

  this.props.firebase
  .object(object.objectName)
  .update({
    pechhulp: pechpasNumber,
    pechhulpInAanvraag: inAanvraag,
    pechhulpReady: true
  })
  .then(() => {
    this.setState({
      pechpasNumberCreated: true,
    })
  })
  .then(() => {
    //update object activity
    if(inAanvraag === false) {
      const ref = this.props.firebase
        .object(object.objectName)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `Pechhulp aangevraagd, pechpasnummer ingevuld.`,
        createdAt: new Date().toString(),
      })
    } else {
      const ref = this.props.firebase
        .object(object.objectName)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `Pechhulp aangevraagd, pechpasnummer nog niet bekend.`,
        createdAt: new Date().toString(),
      })
    }
  })
  .then(res => console.log('pechhulp succesvol aan object toegevoegd', res))
  .catch(err => console.error('Error', err))

  event.preventDefault();
}

handleCheckboxChange = event => {
  const target = event.target;
  const value = target.name === 'inAanvraag' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}

onChange = event => {
  this.setState({ [event.target.name]: event.target.value })
}

  render() {
    const {
      // users,
      object,
      pechpasNumber,
      pechpasNumberCreated,
      inAanvraag,
      error
    } = this.state;

    // const isInvalid = pechpasNumber === '' || inAanvraag === true;

    console.log('object', object)

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug naar {object.objectName}</button>

        <div className="Objects">
          <form onSubmit={this.onSubmit} className="CreateObjectForm">
          <h2>Pechpas nummer invoeren</h2>

            <label>Pechpasnummer</label>
              <input
              name = 'pechpasNumber'
              value = {pechpasNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            {!object.pechhulpInAanvraag && <label>
              <input
                type="checkbox"
                name="inAanvraag"
                checked={this.state.inAanvraag}
                onChange={this.handleCheckboxChange}
                className="form-check-input"
              />
              Pechhulp in aanvraag
            </label>}

            <div>
              {!pechpasNumberCreated && <button type='submit'>Maak pechhulp aan</button>}
              {pechpasNumberCreated && !inAanvraag && <p>Pechpasnummer succesvol aangemaakt.</p>}
              {pechpasNumberCreated && inAanvraag && <p>Pechhulp succesvol aangevraagd.</p>}
            </div>
            {error && <p>{error.message}</p>}

          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(CreatePechhulpBase);
