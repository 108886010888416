import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { withFirebase } from '../Firebase';

import CompanyInvoicesBase from './CompanyInvoicesBase';
import WerkgeverPoolObjectsListBase from '../Werkgever/WerkgeverPoolObjectsListBase';

import "react-datepicker/dist/react-datepicker.css";

import * as ROUTES from '../../constants/routes';

class CompanyItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      company: null,
      users: [],
      invoiceDate: new Date(),
      invoiceAmount: '',
      invoice: '',
      raamContract: '',
      downloadInvoiceUrl: '',
      success: false,
      error: false,
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "username",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.ADMIN}/user/${params.data.uid}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      }, {
        headerName: "Start contract", field: "startDateContract", width:180,
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }
      },
      {
        headerName: "Eind contract", field: "endDateContract", width:180,
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }
      }, {
        headerName: "Fase", field: "phase"
      }, ],
      defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        // floatingFilter: true,
        resizable: true,
    },
      ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.company) {
    //   return;
    // } //Deze staat nu uit, omdat anders werknemers niet geladen werden omdat company niet bekend is.

    this.setState({
      loading: true
    });

    this.props.firebase
    .company(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        company: snapshot.val(),
        // loading: false,
      });
    });

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });
}

  componentWillUnmount() {
    this.props.firebase.companies(this.props.match.params.id).off();
    this.props.firebase.users().off();
  }

  handleDateChange = date => {
    this.setState({
      invoiceDate: date,
    })
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onUpload = event => {
    this.setState({
      invoice: event.target.files[0],
    })
  }

  handleSubmit = event => {

      const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

      const uploadTask = this.props.firebase.companyInvoice(this.state.invoice, this.state.company.companyName, date);
      uploadTask.on('state_changed', snapshot => {

      }, error => {
          this.setState({
            error: true
          })

      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

          this.setState({
            downloadInvoiceUrl: downloadURL,
          })

        this.pushInvoiceToCompany();

        })
    })

    event.preventDefault();
}

pushInvoiceToCompany(event) {
  const ref = this.props.firebase
  .company(this.state.company.companyName)

  const objectInvoices = ref.child('invoices')
  console.log('objectInvoices', objectInvoices)

objectInvoices.push({
  invoiceDate: this.state.invoiceDate.toString(),
  invoiceAmount: this.state.invoiceAmount,
  invoice: this.state.invoice,
  downloadInvoiceUrl: this.state.downloadInvoiceUrl,

  })

  // this.sendEmailToWerkgever(event); //er hoeft geen mail naar werkgever na uploaden factuur.

  console.log('push uitgevoerd')

  this.setState({
    success: true
  })

  //set state to normal
    this.setState({
      invoiceDate: new Date(),
      invoiceAmount: '',
      invoice: '',
      downloadInvoiceUrl: '',
      error: false,
    })

}

onUploadRaamContract = event => {

  const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  // const { user } = this.state;

  // const dealer = event.target.name;

  const file = event.target.files[0];
  const uploadTask = this.props.firebase.companyRaamContract(file, this.state.company.companyName, date);

  uploadTask.on('state_changed', snapshot => {

  }, error => {
    this.setState({
      error: true
    })

  }, () => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

      this.props.firebase
      .company(this.state.company.companyName)
      .update({
        downloadUrlRaamContract: downloadURL,
      })
      .then(res => {
        this.setState({
          successUploadInvoice: true
        })
      })
  })
})
}

//Er hoeft toch geen mail naar werkgever na uploaden factuur
// sendEmailToWerkgever(event) {
// //Verstuur mail naar dealer
//   const templateId = 'notificatie_naar_werkgever_na_uploaden_factuur';
//   const userId = 'user_hxvxyKl4UEjwKu063L7iK';
//   const template_params = {
//     companyContact: this.state.company.companyContact,
//     receiverEmail: this.state.company.companyContactEmail,
//     downloadUrl: this.state.downloadInvoiceUrl,
//   }
//
//   this.sendFeedbackWerkgever(templateId, template_params, userId)
// }
//
// sendFeedbackWerkgever(templateId, template_params, userId) {
//   window.emailjs.send(
//     'default_service',
//     templateId,
//     template_params,
//     userId
//   )
//   .then(res => console.log('email verstuurd', res))
//   .catch(err => console.error('Error', err))
// }


  render() {
    const { company, users, loading, invoice, raamContract, invoiceAmount, success } = this.state;

    const isInvalid =
      invoiceAmount === '' ||
      invoice === '';

    return (
      <>
        <div className="Terug-naar-overzicht">
          <Link to={{
              pathname: `${ROUTES.COMPANIES}`,
              // state: { user },
            }}
            >
              Terug naar overzicht bedrijven
          </Link>
        </div>
        <div className="Company">
          <div className="Company-info-block">
            <h2>{this.props.match.params.id}</h2>

            {company && (
              <table>
                <tbody>
                  <tr>
                    <td><strong>Adres:</strong></td><td>{company.companyAddress}</td>
                  </tr>
                  <tr>
                    <td><strong>Plaats:</strong></td><td>{company.companyPlace}</td>
                  </tr>
                  <tr>
                    <td><strong>Contact:</strong></td><td>{company.companyContact}</td>
                  </tr>
                  <tr>
                    <td><strong>E-mail:</strong></td><td>{company.companyContactEmail}</td>
                  </tr>
                  <tr>
                    <td><strong>Raamcontract:</strong></td>
                  {company.downloadUrlRaamContract &&   <td> <a href={company.downloadUrlRaamContract} target="_blank" rel="noopener noreferrer">Download raamcontract</a></td>}
                    {!company.downloadUrlRaamContract &&  <td><form>
                      <input
                      name = 'Naam'
                      type = 'file'
                      value = {raamContract}
                      onChange = {this.onUploadRaamContract}
                      />
                    </form></td>}
                  </tr>
                </tbody>
              </table>
            )}
          </div>

          {/*<SignUpWerknemerForm/>*/}
          <div className="Company-werknemers">
            <h2>Deelnemers van dit bedrijf</h2>
            {loading && <div>Loading...</div>}
            <div
              className="ag-theme-alpine"
              style={{
              height: '350px',
              }}
            >
              <AgGridReact
                reactNext={true} //
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                paginationAutoPageSize={true}
                rowData={users
                  .filter(user => {
                  return user.company === company.companyName
                  })
                  .filter(user => {
                    return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                  })}>
              </AgGridReact>
            </div>
            {/*<table className="Tabel-full-width">
              <tbody>
                <tr>
                  <th>Naam</th><th>E-mailadres</th><th>Fase</th><th>Details</th>
                </tr>
              {users
                .sort((a, b) => {
                  if (a.username < b.username) {
                    return -1;
                  }
                  if (a.username > b.username) {
                    return 1;
                  }
                  return 0;
                })
                .filter(user => {
                  return user.company === company.companyName
                })
                .filter(user => {
                  return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                })
                .map(user => (
                <tr key={user.uid} >
                  <td>{user.username}</td><td>{user.email}</td><td>{user.phase}</td><td><Link to={{
                    pathname: `${ROUTES.ADMIN}/user/${user.uid}`,
                    state: { user },
                  }}
                  >
                    Details
                  </Link></td>
                </tr>
              ))}
            </tbody>
            </table>*/}
          </div>

          <div className="Company-info-block">
            <h2>Upload factuur</h2>

            {company && (
              <form>
                <label>Factuur datum</label>
                <div>
                  <DatePicker selected={this.state.invoiceDate} onChange = {this.handleDateChange} />
                </div>
                <label>Factuur bedrag</label>
                <input
                  name = 'invoiceAmount'
                  value = {invoiceAmount}
                  onChange = {this.onChange}
                  type = 'text'
                />
                <label>Upload factuur: </label>
                <div className="Upload-facturen">
                <input
                  name = 'file'
                  type = 'file'
                  onChange = {this.onUpload}
                />
                </div>
                <button disabled={isInvalid} onClick={this.handleSubmit}>Verzenden</button>
                {success &&  <p>De factuur is succesvol geupload.</p>}
              </form>
            )}
          </div>

          <div className="Company-werknemers">
            <h2>Overzicht facturen</h2>
            {company && <CompanyInvoices company={company.companyName}/>}
          </div>

          <div>
            {company && <WerkgeverPoolObjectsList company={company.companyName}/>}
          </div>

        </div>
      </>
    )
  }
}

const CompanyInvoices = withFirebase(CompanyInvoicesBase);
const WerkgeverPoolObjectsList = withFirebase(WerkgeverPoolObjectsListBase);

export default CompanyItemBase;
