import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

// import SignUpWerknemerForm from '../SignUpWerknemer';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import CompanyListBase from './CompanyListBase';
import CompanyItemBase from './CompanyItemBase';

import "./Companies.css";

const CompaniesPage = () => (
  <div className="Page">
    <h1>Bedrijven pagina</h1>

    <Switch>
      <React.Fragment>
        <div className="Overicht-bedrijven">
          <Route exact path={ROUTES.COMPANIES_DETAIL} component={CompanyItem} />
          <Route exact path={ROUTES.COMPANIES} component={CompanyList}/>
        </div>
      </React.Fragment>
    </Switch>
  </div>
)

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CompanyList = withFirebase(CompanyListBase);
const CompanyItem = withFirebase(CompanyItemBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(CompaniesPage);
