import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';

import * as ROUTES from '../../constants/routes';

import UserWithSqootIdBase from './UserWithSqootIdBase';
// import UsersDealerListBase from './UsersDealerListBase';
// import UserItemDealerBase from './UserItemDealerBase';

import "./Dealer.css";

const DealerPageBase = () => (
    <div>
      <div className='Dealer-items'>
        <UserWithSqootId />
        <div className="Dealer-knoppen">
          <Link to={{
            pathname: `${ROUTES.DEALER}/users`,
          }}
          >
            <div className="Melding-button">
              <h3>Overzicht klanten</h3>
            </div>
          </Link>

          <Link to={{
            pathname: `${ROUTES.DEALER_MELDINGEN}`,
          }}
          >
            <div className="Melding-button">
              <h3>Meldingen</h3>
            </div>
          </Link>
        </div>
      </div>
    </div>
)

const condition = authUser => !!authUser;

const UserWithSqootId = withFirebase(UserWithSqootIdBase);
// const UsersDealerList = withFirebase(UsersDealerListBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(DealerPageBase);
