import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import Autocomplete from '../SignUpWerknemer/Autosuggestion';

import './Objects.css';

class ObjectItemEditBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      companies:[],
      company: '',
      companyChanged: false,
      objectName: '',
      object: null,
      uid:'',
      user: null,
      objectLicensePlate: '',
      objectMeldCode: '',
      objectPrice:'',
      insurance: '',
      pechhulp: '',
      objectBrand: '',
      objectLock: '',
      objectLockNumber: '',
      objectBatteryKeyNumber: '',
      objectBatteryNumber: '',
      objectBatteryType: '',
      objectBijzonderheden: '',
      gearNumber: '',
      gegevensGewzijgd: false,
      error: '',
      value: '',
      insuranceName: '',
      leaseContractObject: '',
      downloadLeaseContractUrl: '',
      ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.object) {
    //   return;
    // }

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.match.params.id)
    .once('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        objectName: snapshot.val().objectName,
        company: snapshot.val().company,
        uid: snapshot.val().userUid,
        objectLicensePlate: snapshot.val().objectLicensePlate,
        objectBrand: snapshot.val().objectBrand,
        objectMeldCode: snapshot.val().objectMeldCode,
        insurance: snapshot.val().insurance,
        pechhulp: snapshot.val().pechhulp,
        objectLock: snapshot.val().objectLock,
        objectLockNumber: snapshot.val().objectLockNumber,
        objectBatteryKeyNumber: snapshot.val().objectBatteryKeyNumber,
        objectBatteryNumber: snapshot.val().objectBatteryNumber,
        objectBatteryType: snapshot.val().objectBatteryType,
        objectBijzonderheden: snapshot.val().objectBijzonderheden,
        objectPrice: snapshot.val().objectPrice,
        gearNumber: snapshot.val().gearNumber,
        downloadLeaseContractUrl: snapshot.val().downloadMaintenanceUrl,
        loading: false,
      });
    })
    .then(() => {
      this.props.firebase
      .companies()
      .on('value', snapshot => {
        const companiesObject = snapshot.val();

        const companiesList = Object.keys(companiesObject).map(key => ({
          ...companiesObject[key],
          uid: key,
        }));

        this.setState({
          companies: companiesList,
          loading: false,
        });
      });
    })
}

  componentWillUnmount() {
    this.props.firebase.companies(this.props.match.params.id).off();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  onWerkgeverSelectChange = (dataFromChild) => {
    this.setState({
      company: dataFromChild,
      companyChanged: true,
    })
  }

  onUpload = event => {
    this.setState({
      leaseContractObject: event.target.files[0],
    })
  }

  uploadLeaseContract = event => {

    console.log('uploadLeaseContract wordt uitgevoerd')

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

    const uploadTask = this.props.firebase.leaseContractObject(this.state.leaseContractObject, this.state.objectName, date);

    uploadTask.on('state_changed', snapshot => {

    }, error => {
        this.setState({
          error: true
        })

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

        console.log('downloadURL in uploadLeaseContract', downloadURL)

        this.props.firebase
        .object(this.state.objectName)
        .update({
          downloadLeaseContractUrl: downloadURL,
        })
        // .then(res => {
        //   this.setState({
        //     downloadLeaseContractUrl: downloadURL,
        //   })
        // })
      })
  })
  }

  onSubmit = event => {

    if(this.state.leaseContractObject) {
      this.uploadLeaseContract();
    }

    this.props.firebase
    .object(this.state.objectName)
    .update({
      objectLicensePlate: this.state.objectLicensePlate,
      objectMeldCode: this.state.objectMeldCode,
      objectBrand: this.state.objectBrand,
      insurance: this.state.insurance,
      pechhulp: this.state.pechhulp,
      objectLock: this.state.objectLock,
      objectLockNumber: this.state.objectLockNumber,
      objectBatteryKeyNumber: this.state.objectBatteryKeyNumber,
      objectBatteryNumber: this.state.objectBatteryNumber,
      objectBatteryType: this.state.objectBatteryType,
      objectBijzonderheden: this.state.objectBijzonderheden,
      // downloadLeaseContractUrl: this.state.downloadLeaseContractUrl,
      company: this.state.company,
      objectPrice: this.state.objectPrice,
      gearNumber: this.state.gearNumber,
      poolObjectDriver: '',
      poolObjectEndDate: '',
      poolObjectStartDate: '',
      poolObjectLocation: '',
      poolObjectDriverEmail: '',
      poolObjectDriverPhone:'',
    })
    .then(() => {
      if(this.state.company === 'sQoot & Bike') {
        this.props.firebase
        .object(this.state.objectName)
        .update({
          status: 'Voorraad sQoot & Bike',
        })
      } else {
        this.props.firebase
        .object(this.state.objectName)
        .update({
          status: 'In gebruik',
        })
      }
    })
    .then(() => {
      //update object activity
      if(this.state.companyChanged) {
        const ref = this.props.firebase
          .object(this.state.objectName)

        const objectActivity = ref.child('activity')

        objectActivity.push({
          activity: `Poolobject is gekoppeld aan werkgever ${this.state.company}`,
          createdAt: new Date().toString(),
        })
      }

    })
    .then(res => console.log('Gegevens succesvol aangepast', res))
    .catch(err => console.error('Error', err));

    //update user activity
    // const ref = this.props.firebase
    // .user(this.state.uid)
    //
    // const userActivity = ref.child('activity')
    //
    // userActivity.push({
    //   activity: 'Gegevens door Backoffice gewijzigd.',
    //   createdAt: new Date().toString(),
    // })

    this.setState({
      gegevensGewzijgd: true,
    })


    event.preventDefault();
  }

  onObjectSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { object,
            company,
            companies,
            objectPrice,
            objectLicensePlate,
            objectBrand,
            objectMeldCode,
            insurance,
            pechhulp,
            objectLock,
            objectLockNumber,
            objectBatteryKeyNumber,
            objectBatteryNumber,
            objectBatteryType,
            objectBijzonderheden,
            gegevensGewzijgd,
            gearNumber,
            value,
            leaseContractObject,
            downloadLeaseContractUrl,
            loading } = this.state;

    console.log('objectnaam', this.state.objectName)
    console.log('leaseContractObject', leaseContractObject)
    console.log('downloadLeaseContractUrl', downloadLeaseContractUrl)
    console.log('company', this.state.company)
    console.log('gearNumber', gearNumber)

    return (
      <div>

        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>

        <div className="Object">
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}

            {object && (
              <div className="Object-detail-tabel-edit">
              <form onSubmit={this.onSubmit}>
              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>Merk</strong></td>
                    <td>
                    <input
                      name = 'objectBrand'
                      value = {objectBrand}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {objectBrand}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Aantal versnellingen</strong></td>
                    <td>
                      <select
                      name = 'gearNumber'
                      value = {gearNumber}
                      onChange = {this.onObjectSelectChange}
                      >
                        <option value={value} disabled>Aantal versnellingen</option>
                        <option value='Niet ingevuld' key="Niet ingevuld">Niet ingevuld</option>
                        <option value='Geen versnelling' key="Geen versnelling">Geen versnelling</option>
                        <option value='Automatisch' key="Automatisch">Automatisch</option>
                        <option value='3' key="3">3</option>
                        <option value='5' key="5">5</option>
                        <option value='7' key="7">7</option>
                        <option value='8' key="8">8</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Polisnummer</strong></td>
                    <td><input
                      name = 'insurance'
                      value = {insurance}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {insurance}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Pechpasnummer</strong></td>
                    <td><input
                      name = 'pechhulp'
                      value = {pechhulp}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {pechhulp}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Kenteken</strong></td>
                    <td>
                    <input
                      name = 'objectLicensePlate'
                      value = {objectLicensePlate}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {objectLicensePlate}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Meldcode</strong></td>
                    <td>
                    <input
                      name = 'objectMeldCode'
                      value = {objectMeldCode}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {objectMeldCode}
                    /></td>
                  </tr>
                <tr>
                  <td><strong>Slotsoort</strong></td>
                  <td>
                  <input
                    name = 'objectLock'
                    value = {objectLock}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectLock}
                  /></td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer slot</strong></td>
                  <td>
                  <input
                    name = 'objectLockNumber'
                    value = {objectLockNumber}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectLockNumber}
                  /></td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer accu</strong></td>
                  <td>
                  <input
                    name = 'objectBatteryKeyNumber'
                    value = {objectBatteryKeyNumber}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectBatteryKeyNumber}
                  /></td>
                </tr>
                <tr>
                  <td><strong>Accunummer</strong></td>
                  <td>
                  <input
                    name = 'objectBatteryNumber'
                    value = {objectBatteryNumber}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectBatteryNumber}
                  /></td>
                </tr>
                <tr>
                  <td><strong>Soort accu</strong></td>
                  <td>
                  <input
                    name = 'objectBatteryType'
                    value = {objectBatteryType}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectBatteryType}
                  /></td>
                </tr>
                <tr>
                  <td><strong>Bruto aanschafprijs</strong></td>
                  <td>
                  <input
                    name = 'objectPrice'
                    value = {objectPrice}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {objectPrice}
                  /></td>
                </tr>
                {object.company && object.poolObject && <tr>
                  <td><strong>Huidig bedrijf</strong></td>
                  <td>
                  <input
                    name = 'company'
                    value = {company}
                    // onChange = {this.onChange}
                    type = 'text'
                    placeholder = {company}
                    disabled
                  /></td>
                </tr>}
                {object.company && object.poolObject && <tr>
                  <td><strong>Selecteer nieuw bedrijf</strong></td>
                  <td><span className="Toelichting-werkgever-keuze">Vul hieronder de naam van het bedrijf in. Nadat je begint met typen, verschijnt een dropdown lijst met bedrijven.</span>
                  <Autocomplete name='company' callbackFromParent={this.onWerkgeverSelectChange} suggestions={companies.map(company => {
                    return company.companyName;
                  })} /></td></tr>}
                <tr>
                  <td><strong>Upload leasecontract</strong></td>
                  <td>
                    <input
                      name = 'file'
                      type = 'file'
                      onChange = {this.onUpload}
                      />
                  </td>
                </tr>
                <tr>
                  <td><strong>Bijzonderheden</strong></td>
                  <td>
                  <textarea
                    onChange={this.onChange}
                    name= 'objectBijzonderheden'
                    value= {objectBijzonderheden}
                    type = 'text'
                    placeholder = {objectBijzonderheden}
                    /></td>
                </tr>
                </tbody>
              </table>
              <button type='submit'>Opslaan</button>
              {gegevensGewzijgd && <p>De gegevens zijn succesvol gewijzigd.</p>}
              </form>
            </div>
          )}
          </div>

        </div>
    </div>
    )
  }
}


export default withRouter(ObjectItemEditBase);
