import React , { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class UserItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      uid: '',
      dealer: '',
      value: '',
      error: '',
      activity: [],
      date: '',
      ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.user) {
    //   return;
    // }

    this.setState({
      loading: true
    });

    this.props.firebase
    .user(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        uid: this.props.match.params.id,
        loading: false,
      });
    });
}



  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
    // this.props.firebase.dealers().off();
  }

  onSendPasswordResetMail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
    alert('Nieuw wachtwoord is verstuurd.')
  }

  render() {
    const { user, uid, loading } = this.state;

    // const isInvalid = dealer === '';

    return (
      <div>
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.ADMIN}`,
            state: { user },
          }}
          >
            Terug naar dashboard
        </Link>
      </div>
      <div className="Knoppen-rechts-boven">
        <div className="Contract-eindigen">
          <Link to={{
            pathname: `${ROUTES.ADMIN}/contract/`,
            state: {user, uid }
            }}
            >
              Contract beëindigen
          </Link>
        </div>
        <div className="Wijzigen">
          <Link to={{
              pathname: `${ROUTES.ADMIN}/user/edit/${uid}`,
              state: { user },
            }}
            >
              Wijzig gebruiker
          </Link>
        </div>
      </div>
        {loading && <div>Loading...</div>}

        {user && (
          <div className="Werknemer-gegevens">
            <div className="Werknemer-details">
            <div className="Werknemer-details-column">
            <h3>Peroonsgegevens</h3>

              <table>
                <tbody>
                  <tr>
                    <td><strong>Naam:</strong></td><td>{user.username}</td>
                  </tr>
                  <tr>
                    <td><strong>E-mail:</strong></td><td>{user.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Telefoonnummer:</strong></td><td>{user.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td><strong>Adres:</strong></td><td>{user.address}</td>
                  </tr>
                  <tr>
                    <td><strong>Postcode:</strong></td><td>{user.zipCode}</td>
                  </tr>
                  <tr>
                    <td><strong>Plaats:</strong></td><td>{user.place}</td>
                  </tr>
                  <tr>
                    <td><strong>Werkgever</strong></td><td><Link to={{
                    pathname: `${ROUTES.COMPANIES}/company/${user.company}`,
                    state: { companyName: user.company },
                  }}
                  >
                    {user.company}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>sQootID:</strong></td><td>{this.props.match.params.id.substring(0,5)}</td>
                  </tr>
                  <tr>
                    <td colSpan='2' className="Tabel-rij-titel"><h3>Contract gegevens</h3></td>
                  </tr>
                  <tr>
                    <td><strong>Leasecontract:</strong></td><td>{user.downloadUrlLeaseContract &&  <a href={user.downloadUrlLeaseContract} target="_blank" rel="noopener noreferrer">Download leasecontract</a>
                    }</td>
                  </tr>
                  <tr>
                    <td><strong>Maximale waarde fiets:</strong></td><td>€{user.leasebudget}</td>
                  </tr>
                  <tr>
                    <td><strong>Type bijdrage werkgever:</strong></td><td>{user.bijdrageWerkgeverType}</td>
                  </tr>
                  <tr>
                    <td><strong>Bijdrage werkgever:</strong></td><td>€{user.bijdrageWerkgever}</td>
                  </tr>
                  <tr>
                    <td><strong>Maandelijks leasebedrag werknemer:</strong></td><td>€{user.monthlyLeaseFee}</td>
                  </tr>
                  <tr>
                    <td><strong>Bijtelling:</strong></td><td>€{user.bijtelling}</td>
                  </tr>
                  <tr>
                    <td><strong>Start contract:</strong></td>{user.startDateContract && <td>{user.startDateContract.slice(4,16)} <Link to={{
                    pathname: `${ROUTES.ADMIN}/user/contractdate/${uid}`,
                    state: { uid: this.state.uid, user: this.state.user },
                  }}
                  >
                    (Wijzig)
                  </Link></td>}
                  {!user.startDateContract && <td><Link to={{
                  pathname: `${ROUTES.ADMIN}/user/contractdate/${uid}`,
                  state: { uid: this.state.uid, user: this.state.user },
                }}
                >
                  (Wijzig)
                </Link></td>}
                  </tr>
                  <tr>
                    <td><strong>Einde contract:</strong></td>{user.endDateContract && <td>{user.endDateContract.slice(4,16)}</td>}
                  </tr>
                  <tr>
                    <td><strong>3 maanden mail verstuurd:</strong></td>
                    {user.onderhoudsMailDrieMaandenVerstuurd && <td>ja</td>}
                    {!user.onderhoudsMailDrieMaandenVerstuurd && <td>nee</td>}
                  </tr>

                </tbody>
              </table>
            </div>
            <div className="Werknemer-details-column">
            <h3>Extra gegevens</h3>
              <table>
                <tbody>
                  <tr>
                    <td><strong>Factuur fiets:</strong></td><td>{user.downloadUrl &&  <a href={user.downloadUrl} target="_blank" rel="noopener noreferrer">Download Factuur</a>
                    }</td>
                  </tr>
                  <tr>
                    <td><strong>Proforma factuur fiets:</strong></td><td>{user.downloadUrlProforma &&  <a href={user.downloadUrlProforma} target="_blank" rel="noopener noreferrer">Download Proforma factuur</a>
                    }</td>
                  </tr>
                <tr>
                  <td><strong>Dealer:</strong></td>
                  <td><Link to={{
                  pathname: `${ROUTES.DEALERS}/dealer/${user.dealer}`,
                  state: { dealer: user.dealer },
                }}
                >
                  {user.dealer}
                </Link>
                  </td>
                </tr>
                <tr>
                  <td><strong>Object:</strong></td>
                  {/*if user already had object, show object name and link*/}
                  {user.object && <td><Link to={{
                  pathname: `${ROUTES.OBJECTS}/object/${user.object}`,
                  state: { user: user },
                }}
                >
                  {user.object}
                </Link>
                  </td>}
                  {/*if user has no object yet, show 'create object link'*/}
                  {!user.object && <td className="Create-new-object-link"><Link to={{
                  pathname: `${ROUTES.OBJECTS_CREATE}`,
                  state: { user, userUid: uid, company: user.company },
                }}
                >
                  Maak object aan
                </Link> /
                <Link to={{
                pathname: `${ROUTES.ADMIN}/change-object`,
                state: { user, uid: uid },
              }}
              >
                Koppel aan bestaand object
              </Link>
                  </td>}
                </tr>
                <tr>
                  <td><strong>Fase:</strong></td><td>{user.phase}</td>
                </tr>
                <tr>
                  <td><strong>Rol:</strong></td><td>{Object.keys(user.roles)}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div className="Werknemer-activiteiten">
          <h3>Activiteiten</h3>
          {user.activity &&
            <ul className="Activiteiten-lijst">
              {Object.values(user.activity)
                .sort(function compare(a, b) {
                  var dateA = new Date(a.createdAt);
                  var dateB = new Date(b.createdAt);
                  return dateA - dateB;
                })
                .map(active => (
                <li key={active.createdAt} className="Activiteit">
                  <div className="Created-at">{active.createdAt.slice(0,25)}</div>
                  <div>{active.activity}</div>
                </li>
              ))}
            </ul>
          }
          </div>
          <div className="Werknemer-verstuur-nieuw-wachtwoord">
            <h3>Verstuur nieuw wachtwoord</h3>
              <button
                type='button'
                onClick={this.onSendPasswordResetMail}
                >Verstuur nieuw wachtwoord</button>
          </div>

        </div>
        )}

      </div>
    )
  }
}

export default UserItemBase;
