import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

class ServiceFormulierBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: '',
      descriptionServicebeurt: '',
      cost: '',
      selectedOption: "",
      user: '',
      uid: '',
      success: false,
      error: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.firebase
    .user(this.props.uid)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        object: this.props.object.objectName,
        uid: this.props.uid,
      })
    })
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = event => {

    const schadeRef = this.props.firebase
    .object(this.state.object)

    const objectServiceAanvragen = schadeRef.child('serviceaanvragen')
    console.log('objectServiceAanvragen', objectServiceAanvragen)

    const date = new Date().toString();
    const dateForName = date.slice(4,21);

    objectServiceAanvragen.push({
      nameMelding: `serviceaanvraag - ${dateForName}`,
      object: this.state.object,
      typeAanvraag: this.state.selectedOption,
      descriptionServicebeurt: this.state.descriptionServicebeurt,
      cost: this.state.cost,
      dateMelding: new Date().toString(),
      aanvraagApproved: '',
      aanvraagAfgerond: false,
      descriptionBO: '',
      dealer: this.props.dealer,
    })

    console.log('push uitgevoerd')

    this.sendEmailToBackoffice(event);

    //set state to normal
      this.setState({
        selectedOption: '',
        descriptionServicebeurt: '',
        cost: '',
        error: false,
      })

    event.preventDefault();
}


  sendEmailToBackoffice(event) {
  //Verstuur mail naar dealer
    const templateId = 'notificatie_naar_backoffice_serviceaanvraag_geupload';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      werknemerNaam: this.state.user.username,
      linkToObject: `https://portal.sqoot.nl/objecten/object/${this.state.object}`,
    }

    this.sendFeedbackBackoffice(templateId, template_params, userId)
  }

  sendFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }


  render() {
    const { selectedOption, descriptionServicebeurt, cost, success} = this.state;

    const isInvalid = selectedOption === '' ||
                      descriptionServicebeurt === '' ||
                      cost === '';


    return (
      <div>
        <p>Vraag via onderstaand formulier service aan voor dit object</p>
        <form className="Service-aanvraag-formulier" onSubmit={this.handleSubmit}>
          {/*<label>Naam klant</label>
          <input
            name = 'user.username'
            value = {user.username}
            type = 'text'
            disabled
          />
          <label>Objectnummer</label>
          <input
            name = 'object'
            value = {object}
            type = 'text'
            disabled
          />*/}
          <div className="Servicebeurt-keuze">
            <label>De aanvraag betreft een:</label>
            <div className="Servicebeurt-keuzes">
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Servicebeurt"
                  checked={selectedOption === "Servicebeurt"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Aanvraag servicebeurt (jaarlijks onderhoud)
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Verzekeringsclaim"
                  checked={selectedOption === "Verzekeringsclaim"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Aanvraag herstel schade (verzekeringsclaim)
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Garantieclaim"
                  checked={selectedOption === "Garantieclaim"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Aanvraag garantieclaim
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Overige service"
                  checked={selectedOption === "Overige service"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Aanvraag overige service
              </label>
            </div>
          </div>
          <div>
            <div className="Bijzonderheden">
              <label>Omschrijving werkzaamheden</label>
              <div>
              <textarea
                onChange={this.onChange}
                name= 'descriptionServicebeurt'
                value= {descriptionServicebeurt}
                type = 'text'
              />
              </div>
          </div>

            <label>Kosten reparatie</label>
            <input
              name = 'cost'
              value = {cost}
              onChange = {this.onChange}
              type = 'text'
              />
          </div>

            {!success && <button disabled={isInvalid} onClick={this.handleSubmit}>Verzenden</button>}
            {success &&  <p>Het serviceformulier is succesvol verstuurd.</p>}
      </form>
    </div>
    )
  }
}

export default withRouter(ServiceFormulierBase);
