import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import InsuranceItemBase from './InsuranceItemBase';
import ObjectsListBase from './ObjectsListBase';
import ObjectItemBase from './ObjectItemBase';
import CreateObjectBase from './CreateObjectBase';
import CreateInsuranceBase from './CreateInsuranceBase';
import CreatePechhulpBase from './CreatePechhulpBase';
import PechhulpItemBase from './PechhulpItemBase';
import SchademeldingDetailBase from './SchademeldingDetailBase';
// import MonthlyLeaseFeeBase from './MonthlyLeaseFeeBase';
// import CreateBijtellingBase from './CreateBijtellingBase';
import ServiceAanvraagDetailBase from './ServiceAanvraagDetailBase';
import DiefstalmeldingenDetailBase from './DiefstalmeldingenDetailBase';
import ObjectItemEditBase from './ObjectItemEditBase';
import CreatePoolObjectBase from './CreatePoolObjectBase';
import ObjectStolenBase from './ObjectStolenBase';

import './Objects.css';

const ObjectsPage = () => (
      <div className="Page">
        <h1>Objecten pagina</h1>
        <p>Op deze pagina vind je een overzicht van alle objecten.</p>

        <Switch>
          <Route exact path={ROUTES.OBJECTS_OBJECT_STOLEN} component={ObjectStolen} />
          <Route exact path={ROUTES.OBJECTS_POOL_CREATE} component={CreatePoolObject} />
          <Route exact path={ROUTES.OBJECTS_DETAIL_EDIT} component={ObjectItemEdit} />
          <Route exact path={ROUTES.OBJECTS_DETAIL_DIEFSTALMELDING} component={DiefstalmeldingenDetail} />
          <Route exact path={ROUTES.OBJECTS_DETAIL_SERVICEAANVRAAG} component={ServiceAanvraagDetail} />
          <Route exact path={ROUTES.OBJECTS_DETAIL_SCHADEMELDING} component={SchademeldingDetail} />
          <Route exact path={ROUTES.PECHHULP_DETAIL} component={PechhulpItem} />
          <Route exact path={ROUTES.PECHHULP_CREATE} component={CreatePechhulp} />
          <Route exact path={ROUTES.INSURANCE_CREATE} component={CreateInsurance} />
          <Route exact path={ROUTES.OBJECTS_CREATE} component={CreateObject} />
          <Route exact path={ROUTES.OBJECTS_DETAIL} component={ObjectItem} />
          <Route exact path={ROUTES.INSURANCE_DETAIL} component={InsuranceItem} />
          <Route exact path={ROUTES.OBJECTS} component={ObjectsList}/>
        </Switch>
      </div>
)



const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const ObjectsList = withFirebase(ObjectsListBase);
const ObjectItem = withFirebase(ObjectItemBase);
const InsuranceItem = withFirebase(InsuranceItemBase);
const CreateObject = withFirebase(CreateObjectBase);
const CreateInsurance = withFirebase(CreateInsuranceBase);
const CreatePechhulp = withFirebase(CreatePechhulpBase);
const PechhulpItem = withFirebase(PechhulpItemBase);
const SchademeldingDetail = withFirebase(SchademeldingDetailBase);
const ServiceAanvraagDetail = withFirebase(ServiceAanvraagDetailBase);
const DiefstalmeldingenDetail = withFirebase(DiefstalmeldingenDetailBase);
// const MonthlyLeaseFee = withFirebase(MonthlyLeaseFeeBase);
// const CreateBijtelling = withFirebase(CreateBijtellingBase);
const ObjectItemEdit = withFirebase(ObjectItemEditBase);
const CreatePoolObject = withFirebase(CreatePoolObjectBase);
const ObjectStolen = withFirebase(ObjectStolenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(ObjectsPage);
