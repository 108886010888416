import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

import "./PasswordChangeForm.css";


const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase.doPasswordUpdate(passwordOne)
    .then(() => {
      this.setState({...INITIAL_STATE});
    })
    .catch(error => {
      this.setState({error})
    });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return(
      <div className="PasswordChangeForm">
        <h2>Wachtwoord wijzigen</h2>
        <p>Via onderstaand formulier wijzig je jouw wachtwoord.</p>
          <form onSubmit={this.onSubmit} className="Wachtwoord-formulier">
            <label>Wachtwoord</label>
            <input
            name = 'passwordOne'
            value = {passwordOne}
            onChange = {this.onChange}
            type = 'password'
            />
            <label>Bevestig wachtwoord</label>
            <input
            name = 'passwordTwo'
            value = {passwordTwo}
            onChange = {this.onChange}
            type = 'password'
            />
            <button disabled={isInvalid} type='submit'>Reset wachtwoord</button>

            {error && <p>{error.message}</p>}
          </form>
      </div>
    );
  }
}

export default withFirebase(PasswordChangeForm);
