import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isSent: false,
      };
    }

    onSendEmailVerification = () => {
      this.props.firebase.doSendEmailVerification()
      .then(() => this.setState({ isSent: true })
    )}

    render() {
      return (
        <AuthUserContext.Consumer>
            {authUser =>
              needsEmailVerification(authUser) ? (
                <div className="Page">
                {this.state.isSent ? (
                  <p>Bevestigings e-mail is verstuurd. Check je e-mail en klik op de link in de e-mail om jouw e-mailadres te bevestigen.</p>
                ) : (
                  <p>Bevestig je e-mailadres. Er is een bevestigingsmail naar jouw e-mailadres verstuurd. Indien je deze niet hebt gekregen, bekijk je spamfolder, of verstuur een nieuwe mail via onderstaande knop.</p>
                )}

                  <button type='button' onClick={this.onSendEmailVerification} disabled={this.state.isSent}>Bevestigingse-mail opnieuw versturen</button>
                </div>
              ) : (
                <Component {...this.props} />
              )}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
