import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import * as ROUTES from '../../constants/routes';

import Autocomplete from '../SignUpWerknemer/Autosuggestion';

import './Objects.css';

class CreatePoolObjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: '',
      user: '',
      userUid: '',
      active: true,
      readyForPickUp: true,
      dealer: 'sQoot & Bike',
      company: '',
      companyContactEmail: '',
      companyContact: '',
      companyContactUid: '',
      companies: [],
      poolObject: true,
      objectName: '',
      objectBijzonderheden: '',
      objectType: '',
      objectBrand: '',
      objectModel: '',
      objectPrice: '',
      objectFramesize: '',
      objectColor:'',
      objectGender: '',
      objectFrameNumber: '',
      objectLock: '',
      objectLockNumber: '',
      objectBatteryNumber: '',
      objectBatteryKeyNumber: '',
      objectBatteryType: '',
      objectLicensePlate:'',
      objectMeldCode: '',
      engineType: '',
      gearType: '',
      gearNumber: '',
      insurance: '',
      pechhulp: '',
      accessoires: '',
      loading: false,
      error: '',
      value: '',
      leaseContractObject: '',
      downloadLeaseContractUrl: '',
      poolObjectDriver: '',
      poolObjectEndDate: '',
      poolObjectStartDate: '',
      poolObjectLocation: '',
      poolObjectDriverEmail: '',
      poolObjectDriverPhone:'',
      ...props.location.state,
    }
  }

componentDidMount() {
  this.setState({
    loading:true
  });

  this.props.firebase
  .companies()
  .on('value', snapshot => {
    const companiesObject = snapshot.val();

    const companiesList = Object.keys(companiesObject).map(key => ({
      ...companiesObject[key],
      uid: key,
    }));

    this.setState({
      companies: companiesList,
      loading: false,
    });
}
)

  //Aantal objecten binnenhalen voor juiste nummering van objectName
  this.props.firebase
  .objects()
  .on('value', snapshot => {
    const objectsObject = snapshot.val();

    const numberOfObjects = Object.keys(objectsObject).length;

    this.setState({
      objectName: `Object-${numberOfObjects + 244}`
    })
  })
}

componentWillUnmount() {
  this.props.firebase.companies().off();
  this.props.firebase.objects().off();
}

onUpload = event => {
  this.setState({
    leaseContractObject: event.target.files[0],
  })
}

uploadLeaseContract = event => {

  const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

  const uploadTask = this.props.firebase.leaseContractObject(this.state.leaseContractObject, this.state.objectName, date);
  uploadTask.on('state_changed', snapshot => {

  }, error => {
      this.setState({
        error: true
      })

  }, () => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

      console.log('DownloadURL in uploadLeaseContract', downloadURL)

      this.props.firebase
      .object(this.state.objectName)
      .update({
        downloadLeaseContractUrl: downloadURL,
      })
    })
})
}

onSubmit = event => {
  const { objectName,
          objectType,
          objectBrand,
          objectBijzonderheden,
          objectModel,
          objectPrice,
          objectFramesize,
          objectColor,
          objectGender,
          objectFrameNumber,
          objectLock,
          objectLockNumber,
          objectBatteryNumber,
          objectBatteryKeyNumber,
          objectBatteryType,
          objectLicensePlate,
          objectMeldCode,
          engineType,
          gearType,
          gearNumber,
          accessoires,
          insurance,
          pechhulp,
          active,
          dealer,
          company,
          poolObject,
          downloadLeaseContractUrl,
          readyForPickUp,
          poolObjectDriver,
          poolObjectEndDate,
          poolObjectStartDate,
          poolObjectLocation,
          poolObjectDriverEmail,
          poolObjectDriverPhone,
           } = this.state;


  return this.props.firebase
  .object(objectName)
  .set({  objectName,
          objectType,
          objectBrand,
          objectBijzonderheden,
          objectModel,
          objectPrice,
          objectFramesize,
          objectColor,
          objectGender,
          objectFrameNumber,
          objectLock,
          objectLockNumber,
          objectBatteryNumber,
          objectBatteryKeyNumber,
          objectBatteryType,
          objectLicensePlate,
          objectMeldCode,
          engineType,
          gearType,
          gearNumber,
          accessoires,
          insurance,
          pechhulp,
          active,
          dealer,
          company,
          poolObject,
          readyForPickUp,
          downloadLeaseContractUrl,
          poolObjectDriver,
          poolObjectEndDate,
          poolObjectStartDate,
          poolObjectLocation,
          poolObjectDriverEmail,
          poolObjectDriverPhone
   })
   .then(() => {
     //upload leasecontract naar object toe indien een bestand is geselecteerd
     if(this.state.leaseContractObject) {
       this.uploadLeaseContract()
     }
   })
  .then(() => {
    if(company === 'sQoot & Bike') {
      this.props.firebase
      .object(objectName)
      .update({
        status: 'Voorraad sQoot & Bike',
      })
    } else {
      this.props.firebase
      .object(objectName)
      .update({
        status: 'In gebruik',
      })
    }
  })
  .then(() => {
    //update object activity
    const ref = this.props.firebase
      .object(objectName)

    const objectActivity = ref.child('activity')

    objectActivity.push({
      activity: `Object is aangemaakt als poolobject en gekoppeld aan ${company}`,
      createdAt: new Date().toString(),
    })
  })
  // .then(() => {
  //   this.addObjectToCompany(objectName, company)
  // })
  .then(this.setState({
    // users: '',
    user: '',
    // uid: '',
    userUid: '',
    active: false,
    dealer: 'sQoot & Bike',
    company: '',
    poolObject: true,
    objectName: '',
    objectType: '',
    objectBrand: '',
    objectBijzonderheden: '',
    objectModel: '',
    objectPrice: '',
    objectFramesize: '',
    objectColor: '',
    objectGender: '',
    objectFrameNumber: '',
    objectLock: '',
    objectLockNumber: '',
    objectBatteryNumber: '',
    objectBatteryKeyNumber: '',
    objectBatteryType: '',
    objectLicensePlate: '',
    objectMeldCode: '',
    engineType: '',
    gearType: '',
    gearNumber: '',
    leaseContractObject: '',
    downloadLeaseContractUrl: '',
    accessoires: '',
    loading: false,
    error: '',
    value: '',
  },
  this.props.history.push(ROUTES.OBJECTS)))
  .catch(error => {
    this.setState({error});
  })

  // event.preventDefault();
};

// onderstaande functie werd gebruikt om poolobjecten in het bedrijf te zetten, maar gaat nu anders, dus functie wordt niet meer aangeroepen
addObjectToCompany(objectName, company) {
  const objectRef = this.props.firebase
  .company(company)

  const companyObjecten = objectRef.child('objecten')

  companyObjecten.push({
    objectName: objectName,
  })

  console.log('push uitgevoerd')
}

onChange = event => {
  this.setState({ [event.target.name]: event.target.value })
}

handleAccessoiresChange = event => {

  const options = event.target.options;
  const value = [];
    for(let i = 0, l = options.length; i < l; i++) {
      if(options[i].selected) {
        value.push(options[i].value);
      }
    }
  this.setState({accessoires: value})
}

onObjectSelectChange = event => {
  this.setState({ [event.target.name]: event.target.value })
}

onWerkgeverSelectChange = (dataFromChild) => {

  this.setState({company: dataFromChild})

  this.props.firebase
  .company(dataFromChild) //haalt de company gegevens op na selectie van werkgever
  .on('value', snapshot => {
    this.setState({
      companyContactEmail: snapshot.val().companyContactEmail, //selecteert emailadres contactpersoon werkgever
      companyContact: snapshot.val().companyContact, //selecteert contactpersoon werkgever
      companyContactUid: snapshot.val().companyContactUid, //selecteert UID contactpersoon werkgever
      userUid: snapshot.val().companyContactUid, //selecteert UID contactpersoon werkgever
      loading: false,
    });
  });

}

  render() {
    const {
      // users,
      value,
      companies,
      objectName,
      objectType,
      objectBrand,
      objectModel,
      objectPrice,
      objectBijzonderheden,
      objectFramesize,
      objectColor,
      objectGender,
      objectFrameNumber,
      objectLockNumber,
      objectLock,
      objectBatteryNumber,
      objectBatteryKeyNumber,
      objectBatteryType,
      objectLicensePlate,
      objectMeldCode,
      engineType,
      gearType,
      gearNumber,
      leaseContractObject,
      downloadLeaseContractUrl,
      accessoires,
      error
    } = this.state;

    const isInvalid = objectName === '' ||
                      objectBrand === '' ||
                      objectModel === '' ||
                      objectType === '';

  console.log("leaseContractObject", leaseContractObject)
  console.log('downloadLeaseContractUrl', downloadLeaseContractUrl)
  console.log('Objectname', this.state.objectName)

    return (
      <>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
      <div className="Objects">
        <form onSubmit={this.onSubmit} className="CreateObjectForm">
        <h2>Maak een poolobject aan</h2>
        <label>Selecteer werkgever</label>
        <Autocomplete name='company' callbackFromParent={this.onWerkgeverSelectChange} suggestions={companies.map(company => {
          return company.companyName;
        })} />
          <label>Naam object</label>
          <input
          name = 'objectName'
          value = {objectName}
          onChange = {this.onChange}
          type = 'text'
          disabled
          />
          <label>Merk fiets</label>
            <input
            name = 'objectBrand'
            value = {objectBrand}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Type</label>
            <input
            name = 'objectModel'
            value = {objectModel}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Soort fiets</label>
          <div>
            <select
            name = 'objectType'
            value = {objectType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Selecteer object type</option>
              <option value='Stadsfiets' key="Stadsfiets">Stadsfiets</option>
              <option value='Elektrische stadsfiets' key="Elektrische stadsfiets">Elektrische stadsfiets</option>
              <option value='Speedpedelec' key="Speedpedelec">Speedpedelec</option>
              <option value='Mountainbike' key="Mountainbike">Mountainbike</option>
              <option value='Hybride fiets' key="Hybride fiets">Hybride fiets</option>
              <option value='Elektrische hybride fiets' key="Elektrische hybride fiets">Elektrische hybride fiets</option>
              <option value='Bakfiets' key="Bakfiets">Bakfiets</option>
              <option value='Elektrische bakfiets' key="Elektrische bakfiets">Elektrische bakfiets</option>
              <option value='Racefiets' key="Racefiets">Racefiets</option>
              <option value='Elektrische racefiets' key="Elektrische racefiets">Elektrische racefiets</option>
              <option value='Vouwfiets' key="Vouwfiets">Vouwfiets</option>
              <option value='Elektrische vouwfiets' key="Elektrische vouwfiets">Elektrische vouwfiets</option>
              <option value='Scooter' key="Scooter">Scooter</option>
            </select>
          </div>
          <label>Motor positie</label>
          <div>
            <select
            name = 'engineType'
            value = {engineType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Selecteer motor positie</option>
              <option value='Voor' key="Voor">Voor</option>
              <option value='Midden' key="Midden">Midden</option>
              <option value='Achter' key="Achter">Achter</option>
            </select>
          </div>
          <label>Type versnelling</label>
          <div>
            <select
            name = 'gearType'
            value = {gearType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Type versnelling</option>
              <option value='Derailleur' key="Derailleur">Derailleur</option>
              <option value='Naaf' key="Naaf">Naaf</option>
            </select>
          </div>
          <label>Aantal versnellingen</label>
          <div>
            <select
            name = 'gearNumber'
            value = {gearNumber}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Aantal versnellingen</option>
              <option value='Geen versnelling' key="Geen versnelling">Geen versnelling</option>
              <option value='Automatisch' key="Automatisch">Automatisch</option>
              <option value='3' key="3">3</option>
              <option value='5' key="5">5</option>
              <option value='7' key="7">7</option>
              <option value='8' key="8">8</option>
            </select>
          </div>
          <label>Framemaat</label>
            <input
            name = 'objectFramesize'
            value = {objectFramesize}
            onChange = {this.onChange}
            type = 'text'
            />
            <label>Kleur</label>
              <input
              name = 'objectColor'
              value = {objectColor}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Dames/heren</label>
              <input
              name = 'objectGender'
              value = {objectGender}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Framenummer</label>
              <input
              name = 'objectFrameNumber'
              value = {objectFrameNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Slotsoort</label>
              <input
              name = 'objectLock'
              value = {objectLock}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Sleutelnummer slot</label>
              <input
              name = 'objectLockNumber'
              value = {objectLockNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Sleutelnummer accu</label>
              <input
              name = 'objectBatteryKeyNumber'
              value = {objectBatteryKeyNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Accunummer</label>
              <input
              name = 'objectBatteryNumber'
              value = {objectBatteryNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Soort accu</label>
              <input
              name = 'objectBatteryType'
              value = {objectBatteryType}
              onChange = {this.onChange}
              type = 'text'
              />
          <label>Accessoires</label>
          <span className="Toelichting">Houd ctrl ingedrukt als je meerdere accessoires wilt selecteren.</span>
          <div>
            <select
              multiple={true}
              value={accessoires}
              onChange={this.handleAccessoiresChange}
            >
              <option value='Slot' key='Slot'>Slot</option>
              <option value='Kinderzitje' key='Kinderzitje'>Kinderzitje</option>
              <option value='Helm' key='Helm'>Helm</option>
            </select>
          </div>
          <label>Bruto aanschafprijs</label>
            <input
            name = 'objectPrice'
            value = {objectPrice}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Kenteken</label>
            <input
            name = 'objectLicensePlate'
            value = {objectLicensePlate}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Meldcode</label>
            <input
            name = 'objectMeldCode'
            value = {objectMeldCode}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Upload leasecontract: </label>
            <input
              name = 'file'
              type = 'file'
              onChange = {this.onUpload}
              />
          <label>Bijzonderheden</label>
            <textarea
              onChange={this.onChange}
              name= 'objectBijzonderheden'
              value= {objectBijzonderheden}
              type = 'text'
              />
          <div>
            <button disabled={isInvalid} type='submit'>Maak object aan</button>
          </div>
          {error && <p>{error.message}</p>}

        </form>
      </div>
      </>
    )
  }
}

export default withRouter(CreatePoolObjectBase);
