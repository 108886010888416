import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import HomePage from '../Home';
// import LandingPage from '../Landing';
import PasswordForgetPage from '../PasswordForget';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import DealerPage from '../Dealer';
import DealersPage from '../Dealers';
import WerkgeverPage from '../Werkgever';
import WerknemerPage from '../Werknemer';
import ObjectsPage from '../Objects';
import SignUpDealerPage from '../SignUpDealer';
import SignUpWerknemerPage from '../SignUpWerknemer';
import SignUpWerkgeverPage from '../SignUpWerkgever';
import CompaniesPage from '../Companies';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import "./App.css";


const App = () => (
      <Router>
        <div>
          <Navigation/>
          <Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
          <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
          <Route exact path={ROUTES.HOME} component={HomePage}/>
          <Route path={ROUTES.ACCOUNT} component={AccountPage}/>
          <Route path={ROUTES.ADMIN} component={AdminPage}/>
          <Route path={ROUTES.DEALER} component={DealerPage}/>
          <Route path={ROUTES.DEALERS} component={DealersPage}/>
          <Route path={ROUTES.WERKGEVER} component={WerkgeverPage}/>
          <Route path={ROUTES.WERKNEMER} component={WerknemerPage}/>
          <Route path={ROUTES.OBJECTS} component={ObjectsPage}/>
          <Route path={ROUTES.SIGN_UP_DEALER} component={SignUpDealerPage}/>
          <Route path={ROUTES.SIGN_UP_WERKNEMER} component={SignUpWerknemerPage}/>
          <Route path={ROUTES.SIGN_UP_WERKGEVER} component={SignUpWerkgeverPage}/>
          <Route path={ROUTES.COMPANIES} component={CompaniesPage}/>
        </div>
      </Router>
    );

export default withAuthentication(App);
