import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

class CompanyListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      companies:[],
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Bedrijfsnaam", field: "companyName"
      }, {
        headerName: "Adres", field: "companyAddress"
      }, {
        headerName: "Plaats", field: "companyPlace"
      }, {
        headerName: "Contactpersoon", field: "companyContact"
      }, {
        headerName: "Details", field: "companyName", filter: false, sortable: false,
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.COMPANIES}/company/${params.value}`,
              state: { company: params.data },
            }}
            >
              Details
            </Link>
      }}],
      defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    },
    };
  }


  componentDidMount() {

    this.setState({
      loading:true
    });

    this.props.firebase.companies().on('value', snapshot => {
      const companiesObject = snapshot.val();

      const companiesList = Object.keys(companiesObject).map(key => ({
        ...companiesObject[key],
        uid: key,
      }));

      this.setState({
        companies: companiesList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.companies().off();
  }

render() {
  const { companies, loading } = this.state;
  // console.log('companies', companies)

  return (
    <div className="Companies">
      <h2>Overzicht bedrijven</h2>
      <div className="Nieuw-bedrijf-aanmaken">
      <Link to={{
          pathname: `${ROUTES.SIGN_UP_WERKGEVER}`,
          // state: { user },
        }}
        >
          Nieuwe werkgever aanmaken
      </Link>
      </div>
      {loading && <div>Loading...</div>}
      <div
        className="ag-theme-alpine"
        style={{
        height: '500px',
        }}
      >
        <AgGridReact
          reactNext={true} //
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          pagination={true}
          paginationPageSize={this.state.paginationPageSize}
          paginationAutoPageSize={true}
          rowData={companies}>
        </AgGridReact>
      </div>
      {/*<table className="Tabel-full-width">
        <tbody>
          <tr>
            <th>Bedrijfsnaam</th><th>Adres</th><th>Plaats</th><th>Details</th>
          </tr>

          {Object.values(companies)
            .sort((a, b) => {
              if (a.username < b.username) {
                return -1;
              }
              if (a.username > b.username) {
                return 1;
              }
              return 0;
            })
            .map(company => (
            <tr key={company.uid}>
              <td>{company.uid}</td>
              <td>{company.companyAddress}</td>
              <td>{company.companyPlace}</td>
              <td>  <Link to={{
                  pathname: `${ROUTES.COMPANIES}/company/${company.uid}`,
                  state: { company },
                }}
                >
                  Details
                </Link></td>
            </tr>
          ))}
        </tbody>
        </table>*/}
    </div>
  );
}
}

export default CompanyListBase;
