import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './Objects.css';

class CreateInsuranceBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // users: '',
      user: '',
      uid: '',
      insurance: '',
      inAanvraag: false,
      objectType:'',
      // insuranceName: '',
      polisnumber: '',
      polisnumberCreated: false,
      object: null,
      loading: false,
      error: '',
      value: '',
      ...props.location.state,
    }
  }

  onSubmit = event => {
    const { polisnumber, object, inAanvraag } = this.state;

    this.props.firebase
    .object(object.objectName)
    .update({
      insurance: polisnumber,
      insuranceInAanvraag: inAanvraag,
      insuranceReady: true
    })
    .then(() => {
      this.setState({
        polisnumberCreated: true,
      })
    })
    .then(() => {
      //update object activity
      if(inAanvraag === false) {
        const ref = this.props.firebase
          .object(object.objectName)

        const objectActivity = ref.child('activity')

        objectActivity.push({
          activity: `Verzekering aangevraagd, polisnummer ingevuld.`,
          createdAt: new Date().toString(),
        })
      } else {
        const ref = this.props.firebase
          .object(object.objectName)

        const objectActivity = ref.child('activity')

        objectActivity.push({
          activity: `Verzekering aangevraagd, polisnummer nog niet bekend.`,
          createdAt: new Date().toString(),
        })
      }
    })
    .then(res => console.log('verzekering succesvol aan object toegevoegd', res))
    .catch(err => console.error('Error', err))

    event.preventDefault();
  };


  handleCheckboxChange = event => {
    const target = event.target;
    const value = target.name === 'inAanvraag' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      // users,
      object,
      polisnumber,
      polisnumberCreated,
      inAanvraag,
      error
    } = this.state;

    console.log('object', object)
    console.log('inAanvraag', inAanvraag)

    // const isInvalid = polisnumber === '' || inAanvraag === '';

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug naar object</button>

        <div className="Objects">
          <form onSubmit={this.onSubmit} className="CreateObjectForm">
          <h2>Verzeker dit object</h2>

            <label>Polisnummer</label>
              <input
              name = 'polisnumber'
              value = {polisnumber}
              onChange = {this.onChange}
              type = 'text'
              />
            {!object.insuranceInAanvraag && <label>
              <input
                type="checkbox"
                name="inAanvraag"
                checked={this.state.inAanvraag}
                onChange={this.handleCheckboxChange}
                className="form-check-input"
              />
              Verzekering in aanvraag
            </label>}

            <div>
              {!polisnumberCreated && <button type='submit'>Maak verzekering aan</button>}
              {polisnumberCreated && !inAanvraag && <p>Polisnummer succesvol aangemaakt.</p>}
              {polisnumberCreated && inAanvraag && <p>Verzekering succesvol aangevraagd.</p>}
            </div>
            {error && <p>{error.message}</p>}

          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(CreateInsuranceBase);
