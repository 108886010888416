import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';

import './Objects.css';

class PechhulpItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      id: '',
      user: '',
      pechhulp: '',
      insuranceName: '',
      // ...props.location.state,
    };
  }

  componentDidMount() {
    // if(this.state.object) {
    //   return;
    // }

    this.setState({
      loading: true
    });

    this.props.firebase
    .pechhulp(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        pechhulp: snapshot.val(),
        loading: false,
      });
    })
}

  componentWillUnmount() {
    this.props.firebase.pechhulp(this.props.match.params.id).off();
  }

  render() {
    const { pechhulp, loading } = this.state;


    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug naar object</button>
        <div className="Object">
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}
            {pechhulp && (
              <table>
                <tbody>
                <tr>
                  <td><strong>Pechhulpnaam</strong></td><td>{pechhulp.pechhulpName}</td>
                </tr>
                  <tr>
                    <td><strong>Pechhulpnummer</strong></td><td>{pechhulp.pechhulpNumber}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PechhulpItemBase);
