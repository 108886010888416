import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import UserItemDealerBase from './UserItemDealerBase';
import DealerPageBase from './DealerPageBase';
import UsersDealerListBase from './UsersDealerListBase';
import DealerMeldingenBase from './DealerMeldingenBase';
import ServiceAanvraagDetailDealerBase from './ServiceAanvraagDetailDealerBase';

import "./Dealer.css";

const DealerPage = () => (
  <div className="Page">
    <h1>Dealer dashboard</h1>

    <Switch>
      <Route exact path={ROUTES.DEALER_DETAIL_SERVICEAANVRAAG} component={ServiceAanvraagDetailDealer} />
      <Route exact path={ROUTES.DEALER_MELDINGEN} component={DealerMeldingen} />
      <Route exact path={ROUTES.DEALER_USERS_LIST} component={UsersDealerList} />
      <Route exact path={ROUTES.DEALER_USER_DETAIL} component={UserItemDealer} />
      <Route exact path={ROUTES.DEALER} component={DealerPageItems}/>
    </Switch>
  </div>
)

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.DEALER];

const UserItemDealer = withFirebase(UserItemDealerBase);
const DealerPageItems = withFirebase(DealerPageBase);
const UsersDealerList = withFirebase(UsersDealerListBase);
const DealerMeldingen = withFirebase(DealerMeldingenBase);
const ServiceAanvraagDetailDealer = withFirebase(ServiceAanvraagDetailDealerBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(DealerPage);
