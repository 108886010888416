import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import WerknemerObjectBase from './WerknemerObjectBase';
import WerknemerOverzichtBase from './WerknemerOverzichtBase';
import WerknemerServiceBase from './WerknemerServiceBase';
import SchadeFormulierBase from './SchadeFormulierBase';
// import ServiceFormulierBase from './ServiceFormulierBase';
import DiefstalFormulierBase from './DiefstalFormulierBase';
import PechFormulierBase from './PechFormulierBase';
import WerknemerItemEditBase from './WerknemerItemEditBase';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import './Werknemer.css';

const WerknemerPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="Page">
          <Switch>
            <Route exact path={ROUTES.WERKNEMER_EDIT} component={WerknemerItemEdit} />
            <Route exact path={ROUTES.WERKNEMER_SERVICE_PECH_FORMULIER} component={PechFormulier} />
            <Route exact path={ROUTES.WERKNEMER_SERVICE_DIEFSTAL_FORMULIER} component={DiefstalFormulier} />
            <Route exact path={ROUTES.WERKNEMER_SERVICE_SCHADE_FORMULIER} component={SchadeFormulier} />
            <Route exact path={ROUTES.WERKNEMER_SERVICE} component={WerknemerService} />
            <Route exact path={ROUTES.WERKNEMER_OBJECT} component={WerknemerObject} />
            <Route exact path={ROUTES.WERKNEMER} component={() => <WerknemerOverzicht uid={authUser.uid} object={authUser.object}/>} />
          </Switch>
      </div>
      )}
    </AuthUserContext.Consumer>

);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.WERKNEMER];

const WerknemerObject = withFirebase(WerknemerObjectBase);
const WerknemerOverzicht = withFirebase(WerknemerOverzichtBase);
const WerknemerService = withFirebase(WerknemerServiceBase);
const SchadeFormulier = withFirebase(SchadeFormulierBase);
// const ServiceFormulier = withFirebase(ServiceFormulierBase);
const DiefstalFormulier = withFirebase(DiefstalFormulierBase);
const PechFormulier = withFirebase(PechFormulierBase);
const WerknemerItemEdit = withFirebase(WerknemerItemEditBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(WerknemerPage);
