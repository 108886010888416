import React, { Component } from 'react';

import { format } from 'date-fns';

import { AuthUserContext } from '../Session';

class UserWithSqootIdBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      users: [],
      sqootID: '',
      invoice: '',
      proformaInvoice: '',
      downloadUrl: '',
      loading: false,
      success: false,
      error: false,
      noUser: false,
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    this.setState({loading: true})

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      const userWithMatchingID = usersList.filter(user => {
        if(user.uid.substring(0, 5) === this.state.sqootID) {
          return user;
        } else {
          this.setState({
            noUser: true
          })
          return null;
        }
      })

      const [userWithMatchingIDArray] = userWithMatchingID; //destructerd een object uit een array

      this.setState({
        users: usersList,
        user: userWithMatchingIDArray,
        loading: false,
      });

});
    event.preventDefault();

  }

  onUploadProforma = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const { user } = this.state;

    const dealer = event.target.name;

    const file = event.target.files[0];
    const uploadTask = this.props.firebase.invoice(file, user.uid, date);

    uploadTask.on('state_changed', snapshot => {

    }, error => {
      this.setState({
        error: true
      })

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

        this.props.firebase
        .user(user.uid)
        .update({
          downloadUrlProforma: downloadURL,
          phase: "Aanvragen verzekering",
          dealer: dealer, //voor koppelen van dealer aan user
        })
        .then(res => {
          this.setState({
            success: true
          })
        })
        .then(() => {
          this.sendInvoiceEmailToBackoffice(event);
        })
        .catch(err => console.error('Error', err));
    })
  })

  //update user activity
  const ref = this.props.firebase
  .user(user.uid)

  const userActivity = ref.child('activity')

  userActivity.push({
    activity: 'Proforma factuur geupload en dealer gekoppeld -> Naar fase Aanvragen verzekering',
    createdAt: new Date().toString(),
  })
}


  onUpload = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const { user } = this.state;

    const dealer = event.target.name;

    const file = event.target.files[0];
    const uploadTask = this.props.firebase.invoice(file, user.uid, date);

    uploadTask.on('state_changed', snapshot => {

    }, error => {
      this.setState({
        error: true
      })

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

        this.props.firebase
        .user(user.uid)
        .update({
          downloadUrl: downloadURL,
          phase: "Aanvragen verzekering",
          dealer: dealer, //voor koppelen van dealer aan user
        })
        .then(res => {
          this.setState({
            success: true
          })
        })
        .then(() => {
          this.sendInvoiceEmailToBackoffice(event);
        })
        .catch(err => console.error('Error', err));
    })
  })

  //update user activity
  const ref = this.props.firebase
  .user(user.uid)

  const userActivity = ref.child('activity')

  userActivity.push({
    activity: 'Factuur geupload en dealer gekoppeld -> Naar fase Aanvragen verzekering',
    createdAt: new Date().toString(),
  })
}

  sendInvoiceEmailToBackoffice(event) {
  //Verstuur mail naar BO
    const templateId = 'factuur_geupload_door_dealer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      userName: this.state.user.username,
      linkToUser: `https://portal.sqoot.nl/admin/user/${this.state.user.uid}`
    }

    this.sendInvoiceFeedbackBackoffice(templateId, template_params, userId)
  }

  sendInvoiceFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

render() {

    const { user, loading, noUser, sqootID, invoice, proformaInvoice } = this.state;

    const isInvalid =
      sqootID === '' ;

    return(
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="Search-sqootId">
          <h2>Zoek klant op met sQootID</h2>
            <form onSubmit={this.onSubmit}>
              <input
                name = 'sqootID'
                value = {sqootID}
                onChange = {this.onChange}
                type = 'text'
                placeholder = 'Vul hier het sQootID in'
              />

              <button disabled={isInvalid} type='submit' className="Button-marge">Zoek klant</button>
            </form>

            {user && (
              <div className="Klantgegevens-block">
                <h2>Klantgegevens</h2>
                {loading && <div>Loading...</div>}
                  <div className="Klantgegevens">
                    <table className="Tabel-gegevens">
                      <tbody>
                        <tr>
                          <td><strong>Naam:</strong></td><td>{user.username}</td>
                        </tr>
                        <tr>
                          <td><strong>E-mail:</strong></td><td>{user.email}</td>
                        </tr>
                        <tr>
                          <td><strong>Maximale waarde fiets:</strong></td><td>€{user.leasebudget} o.b.v. bruto adviesprijs</td>
                        </tr>
                        <tr>
                          <td><strong>Dealer:</strong></td><td>{authUser.dealerName}</td>
                        </tr>
                        {user.downloadUrlProforma && <tr>
                          <td><strong>Offerte/Proforma factuur:</strong></td><td><a href={user.downloadUrlProforma} target="_blank" rel="noopener noreferrer">Download proforma factuur</a></td>
                        </tr>}
                        {user.downloadUrl && <tr>
                          <td><strong>Definitieve factuur:</strong></td><td><a href={user.downloadUrl} target="_blank" rel="noopener noreferrer">Download definitieve factuur</a></td>
                        </tr>}
                      </tbody>
                    </table>
                    {!user.downloadUrlProforma && <span className="Upload-factuur">
                      <strong>Upload offerte/proforma factuur: </strong>
                      <form>
                        <input
                        name = {authUser.dealerName}
                        type = 'file'
                        value = {proformaInvoice}
                        onChange = {this.onUploadProforma}
                        />
                      </form>
                      <div>
                      {this.state.success &&  <p>De offerte/proforma factuur is succesvol geupload. U kunt de definitieve factuur uploaden zodra deze beschikbaar is.</p>}
                      {this.state.error &&  <p>Er ging iets mis tijdens het uploaden. Probeer het opnieuw.</p>}
                      </div>
                    </span>}
                    {!user.downloadUrl && <span className="Upload-factuur">
                      <strong>Upload definitieve factuur: </strong>
                      <form>
                        <input
                        name = {authUser.dealerName}
                        type = 'file'
                        value = {invoice}
                        onChange = {this.onUpload}
                        />
                      </form>
                      <div>
                      {this.state.success &&  <p>De factuur is succesvol geupload. Nadat sQoot & Bike de verzekering van de fiets heeft geregeld, ontvangt u een e-mail zodat de fiets aan de klant uitgegeven kan worden.</p>}
                      {this.state.error &&  <p>Er ging iets mis tijdens het uploaden. Probeer het opnieuw.</p>}
                      </div>
                    </span>}
                    {/*{user.readyForPickUp && !user.startDateContract && <button onClick={this.bikeIsPickedUp}>Fiets is opgehaald door klant</button>}
                    {user.startDateContract && <p>Bedankt voor het bevestigen!</p>}*/}
                  </div>
                </div>
          )}
          {!user && noUser && (
            <div>Geen gebruiker gevonden.</div>
          )}
          </div>
        )}
      </AuthUserContext.Consumer>
)}
};

export default UserWithSqootIdBase;
