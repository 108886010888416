import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import WerkgeverOverzichtBase from './WerkgeverOverzichtBase';
import WerkgeverDeelemerDetailBase from './WerkgeverDeelemerDetailBase';
import WerkgeverDeelnemersBase from './WerkgeverDeelnemersBase';
import WerkgeverFacturenBase from './WerkgeverFacturenBase';
import WerkgeverObjectDetailBase from './WerkgeverObjectDetailBase';
import WerkgeverPoolObjectsListBase from './WerkgeverPoolObjectsListBase';
import DiefstalFormulierPoolobjectBase from './DiefstalFormulierPoolobjectBase';
import SchadeFormulierPoolobjectBase from './SchadeFormulierPoolobjectBase';
import WerkgeverEditPoolobjectBase from './WerkgeverEditPoolobjectBase';

import './Werkgever.css';

class WerkgeverPage extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }
  render() {
    return(
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="Page">
          <h1>{authUser.companyName}</h1>
          <Switch>
            <Route exact path={ROUTES.WERKGEVER_POOL_OBJECT_SCHADE_FORMULIER} component={SchadeFormulierPoolobject} />
            <Route exact path={ROUTES.WERKGEVER_POOL_OBJECT_DIEFSTAL_FORMULIER} component={() => <DiefstalFormulierPoolobject uid={authUser.uid}/>} />
            <Route exact path={ROUTES.WERKGEVER_POOL_OBJECTS} component={() => <WerkgeverPoolObjectsList company={authUser.companyName}/>} />
            <Route exact path={ROUTES.WERKGEVER_OBJECT} component={() => <WerkgeverObjectDetail uid={authUser.uid}/>} />
            <Route exact path={ROUTES.WERKGEVER_FACTUREN} component={WerkgeverFacturen} />
            <Route exact path={ROUTES.WERKGEVER_DEELNEMERS} component={WerkgeverDeelnemers} />
            <Route exact path={ROUTES.WERKGEVER_WERKNEMER} component={WerkgeverDeelemerDetail} />
            <Route exact path={ROUTES.WERKGEVER} component={() => <WerkgeverOverzicht company={authUser.companyName}/>}/>
            <Route exact path={ROUTES.WERKGEVER_EDIT_POOLOBJECT} component={WerkgeverEditPoolobject} />
          </Switch>
          </div>
      )}
    </AuthUserContext.Consumer>
  )}
};

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.WERKGEVER];

  const WerkgeverOverzicht = withFirebase(WerkgeverOverzichtBase);
  const WerkgeverDeelemerDetail = withFirebase(WerkgeverDeelemerDetailBase);
  const WerkgeverDeelnemers = withFirebase(WerkgeverDeelnemersBase);
  const WerkgeverFacturen = withFirebase(WerkgeverFacturenBase);
  const WerkgeverObjectDetail = withFirebase(WerkgeverObjectDetailBase);
  const WerkgeverPoolObjectsList = withFirebase(WerkgeverPoolObjectsListBase);
  const DiefstalFormulierPoolobject = withFirebase(DiefstalFormulierPoolobjectBase);
  const SchadeFormulierPoolobject = withFirebase(SchadeFormulierPoolobjectBase);
  const WerkgeverEditPoolobject = withFirebase(WerkgeverEditPoolobjectBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(WerkgeverPage);
