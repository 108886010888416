import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';

class WerknemerServiceBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      user: null,
      dealer: '',
      error: '',
      service: false,
      diefstal: false,
      schade: false,
      pech: false,
      melding: '',
      selectedButton: null,
      insuranceName: '',
      ...props.location.state,
    };
  }

  onServiceClick = event => {
    this.setState(prevState => ({
      service: !prevState.service,
      diefstal: false,
      schade: false,
      pech: false,
    }));
  }

  onPechClick = event => {
    this.setState(prevState => ({
      pech: !prevState.pech,
      diefstal: false,
      schade: false,
      service: false,
    }));
  }

  onDiefstalClick = event => {
    this.setState(prevState => ({
      diefstal: !prevState.diefstal,
      pech: false,
      schade: false,
      service: false,
    }));
  }

  onSchadeClick = event => {
    this.setState(prevState => ({
      schade: !prevState.schade,
      pech: false,
      diefstal: false,
      service: false,
    }));
  }


  render() {
    const { object, uid } = this.state;
    // console.log('user', user)
    // console.log('uid', uid)
    // console.log('object', object)

    return (
        <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <div className="Terug-naar-overzicht">
              <Link to={{
                  pathname: `${ROUTES.WERKNEMER}`,
                  // state: { user },
                }}
                >
                  Terug naar overzicht
              </Link>
            </div>

            <div className="Service-pagina">
              <div className="Service-pagina-inleiding">
                <h2>Servicepagina</h2>
                <p>Op deze pagina vindt u diverse handleidingen over wat u in bepaalde gevallen moet doen. Lees eerst de handleiding voordat u een melding maakt.</p>
                {object && <p>Via onderstaande knoppen kunt u een melding maken van pech, diefstal of schade. Voor reperatie of een servicebeurt neemt u direct contact op met uw dealer.</p>}
                {!object && <p>Zodra er een object aan uw account is gekoppeld kunt u hieronder een melding maken van een servicebeurt, pech, diefstal of schade.</p>}
              </div>
              <div className="Handleidingen">
                <h2>Handleidingen</h2>
                <div className="Handleidingen-blok">
                  <div className="Handleiding"><h3>Service</h3><a href="https://sqoot.nl/wp-content/uploads/2022/02/Service.pdf" target="_blank" rel="noopener noreferrer">Bekijk de handleiding</a></div>
                  <div className="Handleiding"><h3>Pech</h3><a  href="https://sqoot.nl/wp-content/uploads/2022/02/Pech-onderweg.pdf" target="_blank" rel="noopener noreferrer">Bekijk de handleiding</a></div>
                  <div className="Handleiding"><h3>Diefstal</h3><a  href="https://sqoot.nl/wp-content/uploads/2022/02/Diefstal-fiets.pdf" target="_blank" rel="noopener noreferrer">Bekijk de handleiding</a></div>
                  <div className="Handleiding"><h3>Schade</h3><a  href="https://sqoot.nl/wp-content/uploads/2022/02/Schade.pdf" target="_blank" rel="noopener noreferrer">Bekijk de handleiding</a></div>
                </div>
              </div>
            </div>
            <div >
            {object && <div className="Melding-maken">
            <h2>Melding maken van...</h2>
                <div className="Meldingen-buttons">

                  <Link to={{
                    pathname: `${ROUTES.WERKNEMER}/service/pech-formulier`,
                    state: { object, uid }
                  }}
                  >
                    <div className="Melding-button">
                      <h3>Pech</h3>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: `${ROUTES.WERKNEMER}/service/diefstal-formulier`,
                    state: { object, uid }
                  }}
                  >
                    <div className="Melding-button">
                      <h3>Diefstal</h3>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: `${ROUTES.WERKNEMER}/service/schade-formulier`,
                    state: { object, uid }
                  }}
                  >
                    <div className="Melding-button">
                      <h3>Schadeclaim</h3>
                    </div>
                  </Link>
                </div>
            </div>}
            <div className="Service-formulieren">

            </div>
            </div>
          </div>
        )}
        </AuthUserContext.Consumer>
  )}}

export default WerknemerServiceBase;
