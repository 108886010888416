import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Autocomplete from '../SignUpWerknemer/Autosuggestion';

class ChangeObjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      object: '',
      objectName: '',
      gegevensGewzijgd: false,
      uid: '',
      error: '',
      activity: [],
      companyObjects: [],
      date: '',
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.firebase
    .objects()
    .once('value', snapshot => {
      const objectsObject = snapshot.val();

      const objectsList = Object.keys(objectsObject).map(key => ({
        ...objectsObject[key],
        uid: key,
      }));

      this.setState({
        objects: objectsList,
        loading: false,
      });
    })
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  onObjectSelectChange = (dataFromChild) => {

  // er worden alleen maar poolfietsen getoond uit de voorraad van sQoot & Bike.
  // Na selectie van fiets, wordt de fiets gekoppeld aan juiste werknemer.

    this.setState({
      objectName: dataFromChild
    })

  //haalt het object binnen van de fiets die is geselecteerd
      this.props.firebase
      .object(dataFromChild)
      .once('value', snapshot => {
        this.setState({
          object: snapshot.val(),
        })
      })
  //haalt de lijst met poolfietsen binnen van het bedrijf dat aan de geselecteerde fiets is gekoppeld, om fiets te kunnen verwijderen uit deze lijst
  // dit werkt niet meer zo
    // .then(() => {
    //   this.props.firebase
    //   .companyObjects(this.state.object.company)
    //   .once('value', snapshot => {
    //     const objectsObject = snapshot.val();
    //
    //     const objectsList = Object.keys(objectsObject).map(key => ({
    //       ...objectsObject[key],
    //       uid: key,
    //     }));
    //
    //     this.setState({
    //       companyObjects: objectsList,
    //       loading: false,
    //     });
    //   })
  //filter de geselecteerde poolfiets uit lijst met poolfietsen om de UID te krijgen, zodat deze verwijderd kan worden uit de lijst
  //   .then(() => {
  //     this.state.companyObjects.filter(object => {
  //       return object.objectName === dataFromChild;
  //     })
  //     .forEach(object => {
  //       console.log('MAPPED OBJECT', object.uid)
  //       this.setState({
  //         uidPoolobject: object.uid
  //       })
  //     })
  //   })
  // })}
}

  onSubmit = event => {

  //koppelt de fiets aan de gebruiker en zet dealer op sQoot & Bike

    this.props.firebase
      .user(this.state.uid)
      .update({
        object: this.state.objectName,
        dealer: 'sQoot & Bike',
      })
  //update de gegevens in het object met gegevens van de nieuwe gebruiker
    .then(() => {
      this.props.firebase
      .object(this.state.objectName)
      .update({
        userUid: this.state.uid,
        company: this.state.user.company,
        poolObject: false,
        status: 'In gebruik',
        dealer: 'sQoot & Bike',
      })
    })
  //verwijdert de poolfiets uit de lijst met poolfietsen van sQoot & Bike
    // .then(() => {
    //   this.props.firebase
    //   .companyObject(this.state.object.company, this.state.uidPoolobject)
    //   .remove()
    // })

    //update object activity
    const objectRef = this.props.firebase
      .object(this.state.objectName)

    const objectActivity = objectRef.child('activity')

    objectActivity.push({
      activity: `Object is gekoppeld aan ${this.state.user.username} van ${this.state.user.company}`,
      createdAt: new Date().toString(),
    })

      //update user activity
      const ref = this.props.firebase
      .user(this.state.uid)

      const userActivity = ref.child('activity')

      userActivity.push({
        activity: 'Bestaande poolfiets gekoppeld aan werknemer.',
        createdAt: new Date().toString(),
      })

    this.setState({
      gegevensGewzijgd: true,
    })

    event.preventDefault();
  }

  render() {
    const { objects, user, gegevensGewzijgd, loading } = this.state;

    // const isInvalid = startDateContract === '' | endDateContract === '';
    // console.log('OBJECTS', objects)
    // console.log('Object', object)
    // console.log('OBJECTNAME', objectName)
    // console.log('USER', user)
    // console.log('UID', uid)
    // console.log('companyObjects', companyObjects)
    // console.log('uidPoolobject', uidPoolobject)

    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {user && objects && (
          <div className="Werknemer-gegevens-edit">
            <div className="Werknemer-details">
            <div className="Werknemer-details-column">
              <h3>Selecteer een poolobject voor deze gebruiker</h3>
              <form onSubmit={this.onSubmit}>
                <div>
                  <label>Selecteer nieuw object</label>
                  <span className="Toelichting-werkgever-keuze">Vul hieronder de objectnaam in. Nadat je begint met typen, verschijnt een dropdown lijst met poolobjecten. In onderstaande lijst staan alleen de voorraad fietsen van sQoot & Bike.</span>
                    <Autocomplete name='dealer' callbackFromParent={this.onObjectSelectChange} suggestions={objects
                      .sort((a,b) => parseInt(a.uid.slice(7)) - parseInt(b.uid.slice(7)))
                      .filter(object => {
                      return object.poolObject === true})
                      .filter(object => {
                      return object.company === 'sQoot & Bike'})
                      .map(object => {
                    return object.objectName;
                  })} />
                </div>
                <div>
                <button  type='submit' className="Button-marge">Opslaan</button>
            </div>
              {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </form>
            </div>

          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(ChangeObjectBase);
