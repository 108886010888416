import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

// import SignUpWerknemerForm from '../SignUpWerknemer';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import DealersListBase from './DealersListBase';
import DealerItemBase from './DealerItemBase';

import "./Dealers.css";

const DealersPage = () => (
  <div className="Page">
    <h1>Dealers pagina</h1>
    <p>Op deze pagina vind je een overzicht van alle dealers.</p>

    <Switch>
      <div className="Overicht-dealers">
        <Route exact path={ROUTES.DEALERS_DETAIL} component={DealerItem} />
        <Route exact path={ROUTES.DEALERS} component={DealersList}/>
      </div>
    </Switch>
  </div>
)

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const DealersList = withFirebase(DealersListBase);
const DealerItem = withFirebase(DealerItemBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(DealersPage);
