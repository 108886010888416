import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import './Objects.css';

class SchademeldingenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: '',
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.object)
    .on('value', snapshot => {


    const meldingenObject = snapshot.val().schademeldingen;

    if(meldingenObject) {
      const meldingenList = Object.keys(meldingenObject).map(key => ({
        ...meldingenObject[key],
        uid: key,
      })
    );

      this.setState({
        object: snapshot.val(),
        loading:false,
        meldingen: meldingenList
      })
    } else {
      this.setState({
        loading: false
      })
    }
  })
}


  render() {
    const { loading, object, meldingen } = this.state;

    return (
      <div>
            {loading && <div>Loading...</div>}
            {object.schademeldingen && (
              <table className="Tabel-full-width">
              <thead>
                <tr>
                  <th>Melding</th><th>Melding afgehandeld</th>
                </tr>
              </thead>
              <tbody>
              {Object.values(meldingen)
                .map(melding => (
                <tr key={melding.uid} className="Activiteit">
                  <td><Link to={{
                  pathname: `${ROUTES.OBJECTS}/object/schademelding/${melding.uid}`,
                  state: { meldingUid: melding.uid, object: object.objectName },
                  }}
                  >
                    {melding.nameMelding}
                  </Link></td>
                  {melding.meldingAfgehandeld && <td className="Check-ok left">V</td>}
                  {!melding.meldingAfgehandeld && <td className="Check-not-ok left">X</td>}
                </tr>
              ))}
              </tbody>
              </table>
            )}
            {!object.schademeldingen && <p>Er zijn nog geen schademeldingen voor dit object.</p>}
      </div>
    )
  }
}

export default withRouter(SchademeldingenBase);
