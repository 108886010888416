import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles'

const SignUpPage = () => (
  <div>
    <h1>SignUpPage</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  isDealer: false,
  isWerknemer: false,
  isWerkgever: false,
  error: null,
}

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin, isDealer, isWerknemer, isWerkgever} = this.state;

    const roles = {};

    if(isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    if(isDealer) {
      roles[ROLES.DEALER] = ROLES.DEALER;
    }

    if(isWerknemer) {
      roles[ROLES.WERKNEMER] = ROLES.WERKNEMER;
    }

    if(isWerkgever) {
      roles[ROLES.WERKGEVER] = ROLES.WERKGEVER;
    }

    this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      //Create a user in Firebase realtime database
      return this.props.firebase
      .user(authUser.user.uid)
      .set({username, email, roles,})
    })
    .then(() => {
      return this.props.firebase.doSendEmailVerification();
    })
    .then(authUser => {
      this.setState({...INITIAL_STATE});
      this.props.history.push(ROUTES.HOME);
    })
    .catch(error => {
      this.setState({error});
    });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      isDealer,
      isWerknemer,
      isWerkgever,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return(
      <form onSubmit={this.onSubmit}>
        <input
        name = 'username'
        value = {username}
        onChange = {this.onChange}
        type = 'text'
        placeholder = 'Volledige naam'
        />
        <input
        name = 'email'
        value = {email}
        onChange = {this.onChange}
        type = 'text'
        placeholder = 'E-mailadres'
        />
        <input
        name = 'passwordOne'
        value = {passwordOne}
        onChange = {this.onChange}
        type = 'password'
        placeholder = 'Wachtwoord'
        />
        <input
        name = 'passwordTwo'
        value = {passwordTwo}
        onChange = {this.onChange}
        type = 'password'
        placeholder = 'Bevestig wachtwoord'
        />
        <label>
          Admin:
            <input
              name='isAdmin'
              type='checkbox'
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
        </label>
        <label>
          Dealer:
            <input
              name='isDealer'
              type='checkbox'
              checked={isDealer}
              onChange={this.onChangeCheckbox}
            />
        </label>
        <label>
          Werknemer:
            <input
              name='isWerknemer'
              type='checkbox'
              checked={isWerknemer}
              onChange={this.onChangeCheckbox}
            />
        </label>
        <label>
          Werkgever:
            <input
              name='isWerkgever'
              type='checkbox'
              checked={isWerkgever}
              onChange={this.onChangeCheckbox}
            />
        </label>
        <button disabled={isInvalid} type='submit'>Aanmelden</button>

        {error && <p>{error.message}</p>}

      </form>
    )
  }
}

const SignUpLink = () => (
  <p>
    Heb je nog geen account? <Link to={ROUTES.SIGN_UP}>Meld je hier aan</Link>
  </p>
)

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
