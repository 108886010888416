import React, { Component } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import { withRouter } from 'react-router-dom';

class DiefstalFormulierPoolobjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: '',
      selectedOption: "ja",
      diefstalDate: new Date(),
      user: '',
      aangifte: '',
      geenAangifte: '',
      schadesList: '',
      downloadUrlAangifte: '',
      uid: '',
      nameSchademelding: '',
      success: false,
      error: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.firebase
    .user(this.state.uid)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
      })
    })
  }


  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleDateChange = date => {
    this.setState({
      diefstalDate: date,
    })
  }

  handleSubmit = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    if(this.state.aangifte) {
      const uploadTask = this.props.firebase.diefstal(this.state.aangifte, this.state.object, date);
      uploadTask.on('state_changed', snapshot => {

      }, error => {
        this.setState({
          error: true
        })

      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

          this.setState({
            downloadUrlAangifte: downloadURL
          })

          this.pushDiefstalmeldingToObject();
      })
    })
  } else {
    this.pushDiefstalmeldingToObject();
  }

    event.preventDefault();
}



  onUpload = event => {
    this.setState({
      aangifte: event.target.files[0],
    })
  }

  pushDiefstalmeldingToObject(event) {
    const schadeRef = this.props.firebase
    .object(this.state.object)

    const objectDiefstallen = schadeRef.child('diefstallen')
    console.log('objectDiefstallen', objectDiefstallen)

    const date = new Date().toString();
    const dateForName = date.slice(4,21);

    objectDiefstallen.push({
      diefstalDate: this.state.diefstalDate.toString(),
      nameMelding: `aangifte - ${dateForName}`,
      geenAangifte: this.state.geenAangifte,
      downloadUrlAangifte: this.state.downloadUrlAangifte,
      dateMelding: new Date().toString(),
      copyAangifte: false,
      diefstalGemeldEnra: false,
      sleutelsOntvangen: false,
      sleutelsVerstuurdEnra: false,
      schadeBedragOntvangenEnra: false,
      eigenRisicoGebruiker: false,
      nieuweFietsInBestelling: false,
      meldingAfgehandeld: false,
      description: '',
      object: this.state.object,
    })

    console.log('push uitgevoerd')

    this.sendEmailToBackoffice(event);

    //update user activity
    // const ref = this.props.firebase
    // .user(this.state.uid)
    //
    // const userActivity = ref.child('activity')
    //
    // userActivity.push({
    //   activity: 'Diefstal gemeld door werknemer',
    //   createdAt: new Date().toString(),
    // })
    //
    // this.setState({
    //   success: true
    // })

    //set state to normal
      this.setState({
        selectedOption: "nee",
        selectedOptionIncident: 'nee',
        dateSchade: '',
        aangifte: '',
        downloadUrlAangifte: '',
        nameSchademelding: '',
        success: true,
        error: false,
      })

  }

  sendEmailToBackoffice(event) {
  //Verstuur mail naar dealer
    const templateId = 'notificatie_naar_backoffice_diefstalformulier_geupload';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      werknemerNaam: `${this.state.user.username} (Betreft poolobject)`,
      linkToObject: `https://portal.sqoot.nl/objecten/object/${this.state.object}`,
}

    this.sendFeedbackBackoffice(templateId, template_params, userId)
  }

  sendFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }


  render() {
    const {selectedOption, aangifte, success, error} = this.state;

    // console.log('datum', diefstalDate)
    // console.log('state UID', this.state.uid)
    // console.log('aangifte render', this.state.aangifte)
    // console.log('object', object)
    // console.log('user', user)

    const isInvalid = selectedOption === false || aangifte === '';


    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        <h2>Vul hieronder het diefstalformulier in</h2>
          <div className="Schadeformulier">
          <form onSubmit={this.handleSubmit}>
            <div>
            <label>Datum van de diefstal</label>
              <div>
                <DatePicker selected={this.state.diefstalDate} onChange = {this.handleDateChange} />
              </div>
            </div>
            <div></div>
            <label>Heeft u aangifte van diefstal gedaan?</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="ja"
                  checked={this.state.selectedOption === "ja"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Ja
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="nee"
                  checked={this.state.selectedOption === "nee"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Nee
              </label>
            </div>

            {selectedOption === 'nee' && !success && <p>
              U dient altijd aangifte te doen van diefstal. U leest er meer over in onze <a href="https://www.sqoot.nl" target="_blank" rel="noopener noreferrer">handleiding</a>.
            </p>}
            {selectedOption === "ja" && <div className="Upload-schadeformulier">
              <span >
                <label>Upload aangifte: </label>
                <input
                  name = 'file'
                  type = 'file'
                  onChange = {this.onUpload}
                  />
                <div>
                </div>
              </span>
            </div>}

            {aangifte && <p>
              Nadat u deze diefstalmelding heeft voltooid, dient u de fietssleutels aangetekend op te sturen naar sQoot & Bike.
              <br/><br/>Ons adres is:
              <br/><br/>sQoot & Bike
              <br/>Kanaalweg 4
              <br/>2628 EB Delft
            </p>}

            {!success && selectedOption === "ja" && <button className="Verzend-knop" disabled={isInvalid} onClick={this.handleSubmit}>Verzenden</button>}
            {success &&  <p>Het diefstalformulier is succesvol verstuurd.</p>}
            {error &&  <p>Er ging iets mis tijdens het uploaden. Probeer het opnieuw.</p>}
          </form>
      </div>
      </div>
    )
  }
}
export default withRouter(DiefstalFormulierPoolobjectBase);
