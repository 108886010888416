import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import MeldingenUsersNoInvoiceBase from './MeldingenUsersNoInvoiceBase';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

import '../Objects/Objects.css';

class DealerMeldingenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      objects:[],
      alleServiceAanvragen: [],
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    const alleServiceAanvragen = []
    const serviceAanvragen = []

    this.props.firebase
    .objects()
    .once('value', snapshot => {
      const objectsObject = snapshot.val();

      const objectsList = Object.keys(objectsObject).map(key => ({
        ...objectsObject[key],
        uid: key,
      }));

      this.setState({
        objects: objectsList,
        loading: false,
      });
    })
    .then(() => {
      this.state.objects.forEach(object => {

        if(object.serviceaanvragen) {
        const serviceAanvragenList = Object.keys(object.serviceaanvragen).map(key => ({
          ...object.serviceaanvragen[key],
          uid: key,
        }))

        alleServiceAanvragen.push(serviceAanvragenList)
      }
      })
    })
    .then(() => {
      alleServiceAanvragen.forEach(alleServiceAanvraag => {
        alleServiceAanvraag.forEach(aanvraag => {
          serviceAanvragen.push(aanvraag)
        })
      })
      this.setState({
        alleServiceAanvragen: serviceAanvragen
      })
    })
    ;
  }


render() {
  const { alleServiceAanvragen, loading } = this.state;

  return (
    <AuthUserContext.Consumer>
    {authUser => (
    <div className="Objects">

        <div className="Non-active-objects">
          <h2>Openstaande serviceaanvragen</h2>
          {loading && <div>Loading...</div>}
          {alleServiceAanvragen &&
          <table className="Tabel-full-width">
          <thead>
            <tr>
              <td><strong>Melding</strong></td>
              <td><strong>Object</strong></td>
              <td><strong>Melding afgehandeld</strong></td>
              <td></td>
              <td><strong>Melding goedgekeurd</strong></td>
              <td><strong>Details</strong></td>
            </tr>
          </thead>
            <tbody>
          {Object.values(alleServiceAanvragen)
            .filter(aanvraag => {
              return aanvraag.dealer === authUser.dealerName
            })
            .filter(melding => {
            return melding.aanvraagAfgerond === false
          }).map(melding => (
            <tr key={melding.uid}>
              <td>{melding.nameMelding}</td>
              <td>{melding.object}</td>
              {melding.aanvraagAfgerond && <td className="Check-ok left">V</td>}
              {!melding.aanvraagAfgerond && <td className="Check-not-ok left">X</td>}
              <td className="Tabel-cel-smal"></td>
              {melding.aanvraagApproved === 'Goedgekeurd' && <td className="Check-ok left">V</td>}
              {melding.aanvraagApproved === '' && <td className="Check-not-ok-orange left">Wacht op beoordeling</td>}
              {melding.aanvraagApproved === 'Afgekeurd' && <td className="Check-not-ok left">X</td>}
              <td>
                <Link to={{
                  pathname: `${ROUTES.DEALER}/meldingen/serviceaanvraag/${melding.uid}`,
                  state: { object: melding.object, aanvraagUid: melding.uid },
                }}
                >
                  Details
                </Link>
              </td>
            </tr>
          ))
          }
          </tbody>
          </table>}
          </div>

          <MeldingenUsersNoInvoice />
      </div>


    )}
    </AuthUserContext.Consumer>
  );
}
}

const MeldingenUsersNoInvoice = withFirebase(MeldingenUsersNoInvoiceBase);

export default DealerMeldingenBase;
