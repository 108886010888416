import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import "./Navigation.css";

const Navigation = () => (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div className="Navigation">
  <a href="https://portal.sqoot.nl" className="logo">sQoot & Bike</a>
  <input className="menu-btn" type="checkbox" id="menu-btn" />
    <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
    <ul className='menu'>
      <li>
        <Link to={ROUTES.HOME}>Home</Link>
      </li>
      <li>
        <Link to={ROUTES.ACCOUNT}>Account</Link>
      </li>
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.ADMIN}>Werknemers</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.COMPANIES}>Bedrijven</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.DEALERS}>Dealers</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.OBJECTS}>Objecten</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.ADMIN_MELDINGEN}>Meldingen</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.DEALER] && (
        <li>
          <Link to={ROUTES.DEALER}>Dashboard</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.DEALER] && (
        <li>
          <Link to={ROUTES.DEALER_USERS_LIST}>Klanten</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.DEALER] && (
        <li>
          <Link to={ROUTES.DEALER_MELDINGEN}>Meldingen</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.WERKGEVER] && (
        <li>
          <Link to={ROUTES.WERKGEVER}>Dashboard</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.WERKGEVER] && (
        <li>
          <Link to={ROUTES.WERKGEVER_DEELNEMERS}>Deelnemers fietsplan</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.WERKGEVER] && (
        <li>
          <Link to={ROUTES.WERKGEVER_POOL_OBJECTS}>Poolobjecten</Link>
        </li>
      )}

      {!!authUser.roles[ROLES.WERKNEMER] && (
        <li>
          <Link to={ROUTES.WERKNEMER}>Dashboard</Link>
        </li>
      )}
      {!!authUser.roles[ROLES.WERKNEMER] && (
        <li>
          <Link to={{
            pathname: `${ROUTES.WERKNEMER}/object/${authUser.object}`,
            state: {objectName: authUser.object }
          }}
          >Objectgegevens
          </Link>
        </li>
      )}
      {!!authUser.roles[ROLES.WERKNEMER] && (
        <li>
          <Link to={{
            pathname: `${ROUTES.WERKNEMER}/service`,
            state: { uid: authUser.uid, object: authUser.object },
          }}
          >Melding maken
          </Link>
        </li>
      )}

      <li>
        <SignOutButton />
      </li>
    </ul>
  </div>
)

const NavigationNonAuth = () => (
  <div className="Navigation">
  <a href="https://portal.sqoot.nl" className="logo">sQoot & Bike</a>
  <input className="menu-btn" type="checkbox" id="menu-btn" />
    <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
    <ul className='menu'>
      <li>
        <Link to={ROUTES.SIGN_IN}>Sign In</Link>
      </li>
      
    </ul>
  </div>
);

export default Navigation;
