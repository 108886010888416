import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import PasswordChangeForm from '../PasswordChange';

import "./Account.css";

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="Page">
        <h1>Account: {authUser.username}</h1>
        <p>{authUser.email}</p>
        <div className="Account-formulieren">
          <PasswordChangeForm />
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>

);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPage);
