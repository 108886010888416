import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import { addMonths, format } from 'date-fns';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import ServiceFormulierBase from './ServiceFormulierBase';
import OnderhoudsFacturenBase from '../Objects/OnderhoudsFacturenBase';
import ServiceAanvraagBase from '../Objects/ServiceAanvraagBase';

// import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

class UserItemDealerBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      downloadMaintenanceUrl: '',
      uid: '',
      dealer: '',
      invoiceAmount: '',
      invoice: '',
      invoiceBike: '',
      endDate: '',
      object: '',
      // maintenanceInvoice: '',
      invoices: '',
      value: '',
      success: '',
      successUploadInvoice: '',
      error: '',
      activity: [],
      date: '',
      visibility: true,
      ...props.location.state,
    };
  }

  componentDidMount() {

    // var result = addMonths(new Date(), 36)

    this.setState({
      loading: true,
    });

    // this.props.firebase
    // .user(this.props.match.params.id)
    // .once('value', snapshot => {
    //   this.setState({
    //     user: snapshot.val(),
    //     uid: this.props.match.params.id,
    //     // loading: false,
    //   });
    // })
    // .then(() => {
    //   if(this.state.user.object) {
    //   this.props.firebase
    //   .object(this.state.user.object)
    //   .once('value', snapshot => {
    //     this.setState({
    //       object: snapshot.val(),
    //       loading: false,
    //     });
    //   })};
    // })

    this.props.firebase
    .user(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        uid: this.props.match.params.id,
        loading: false,
      });
    })

    if(this.state.user.object) {
    this.props.firebase
    .object(this.state.user.object)
    .on('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        loading: false,
      });
    })}

  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
    this.props.firebase.object(this.state.user.object).off();
  }

  onUploadInvoice = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    // const { user } = this.state;

    // const dealer = event.target.name;

    const file = event.target.files[0];
    const uploadTask = this.props.firebase.invoice(file, this.state.uid, date);

    uploadTask.on('state_changed', snapshot => {

    }, error => {
      this.setState({
        error: true
      })

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

        this.props.firebase
        .user(this.state.uid)
        .update({
          downloadUrl: downloadURL,
          // phase: "Aanvragen verzekering",
          // dealer: dealer, //voor koppelen van dealer aan user
        })
        .then(res => {
          this.setState({
            successUploadInvoice: true
          })
        })
        .then(() => {
          this.sendInvoiceEmailToBackoffice(event);
        })
        .catch(err => console.error('Error', err));
    })
  })

  //update user activity
  const ref = this.props.firebase
  .user(this.state.uid)

  const userActivity = ref.child('activity')

  userActivity.push({
    activity: 'Definitieve factuur geupload door dealer',
    createdAt: new Date().toString(),
  })
}

  sendInvoiceEmailToBackoffice(event) {
  //Verstuur mail naar BO
    const templateId = 'factuur_geupload_door_dealer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      userName: this.state.user.username,
      linkToUser: `https://portal.sqoot.nl/admin/user/${this.state.uid}`
    }

    this.sendInvoiceFeedbackBackoffice(templateId, template_params, userId)
  }

  sendInvoiceFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }


  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }


  bikeIsPickedUp = event => {

    this.props.firebase
    .user(this.state.uid)
    .once('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        uid: this.state.uid,
        visibility: false,
        // loading: false,
      })
    })
    .then(() => {
      this.props.firebase
      .user(this.state.uid)
      .update({
        startDateContract: new Date().toString(),
        endDateContract: addMonths(new Date(), 36).toString(),
        phase: "Lopend contract"
      })
    })
    .then(() => {
      this.props.firebase
      .object(this.state.user.object)
      .update({
        active: true,
        status: 'In gebruik',
      })
    })
    .then(() => {
      //update user activity
      const ref = this.props.firebase
      .user(this.state.uid)

      const userActivity = ref.child('activity')

      userActivity.push({
        activity: 'Fiets is opgehaald en leasecontract is gestart -> Naar fase Lopend contract',
        createdAt: new Date().toString(),
      })
    })
    .then(() => {
      //send email to backoffice
      this.sendEmailToBackoffice(event);
    })
    .then(() => {
      //send email to backoffice
      this.sendEmailToWerknemer(event);
    })
  }

  sendEmailToBackoffice(event) {
  //Verstuur mail naar BO
    const templateId = 'fiets_is_opgehaald_door_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      werknemerNaam: this.state.user.username,
      werkgeverNaam: this.state.user.company,
      werknemerMail: this.state.user.email,
      dealer: this.state.user.dealer,
      linkToUser: `https://portal.sqoot.nl/admin/user/${this.state.uid}`,
      uid: this.state.uid,
    }
    this.sendFeedbackBackoffice(templateId, template_params, userId)
  }

  sendFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  sendEmailToWerknemer(event) {
  //Verstuur mail naar BO
    const templateId = 'fiets_is_opgehaald_door_werknemer_mail_naar_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      werknemerNaam: this.state.user.username,
      receiverEmail: this.state.user.email,
      dealer: this.state.user.dealer,
    }
    this.sendFeedbackWerknemer(templateId, template_params, userId)
  }

  sendFeedbackWerknemer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const { user, uid, object, invoiceBike, loading, visibility, successUploadInvoice } = this.state;

    return (
      <AuthUserContext.Consumer>
      {authUser => (
      <div>
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.DEALER_USERS_LIST}`,
            // state: { user },
          }}
          >
            Terug naar overzicht klanten
        </Link>
      </div>

      {object && object.readyForPickUp && !user.startDateContract && visibility && user.downloadUrl &&
        <div className="Dealer-informeer-balk">
        <p className="Dealer-informeer-tekst">Is de fiets opgehaald door de klant? Klik op deze knop.</p>
        <button className="Ophalen-button" onClick={this.bikeIsPickedUp} name={uid}>Fiets is opgehaald door klant</button>
        </div>
      }

      {object && object.readyForPickUp && !user.startDateContract && visibility && !user.downloadUrl &&
        <div className="Dealer-informeer-balk-rood">
        <p className="Dealer-informeer-tekst-rood">Voordat de fiets uitgegeven kan worden dient u de definitieve factuur te uploaden. Klik hieronder onder Objectgegevens achter 'Definitieve verkoopfactuur fiets' op Bladeren en upload de factuur.</p>
        </div>
      }

        {loading && <div>Loading...</div>}

        {user &&  (
          <div>
            <div className="Werknemer-details-dealerpagina">
            <div className="Werknemer-object-gegevens">
            <div className="Werknemer-details-column">
            <h3>Peroonsgegevens</h3>
              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>Naam:</strong></td><td>{user.username}</td>
                  </tr>
                  <tr>
                    <td><strong>E-mail:</strong></td><td>{user.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Werkgever</strong></td><td>{user.company}</td>
                  </tr>
                  <tr>
                    <td><strong>sQootID:</strong></td><td>{this.props.match.params.id.substring(0,5)}</td>
                  </tr>
                  <tr>
                    <td><strong>Leasebudget:</strong></td><td>€{user.leasebudget}</td>
                  </tr>
                  {user.startDateContract && <tr>
                    <td><strong>Start contract:</strong></td><td>{user.startDateContract.slice(4,15)}</td>
                  </tr>}
                  {user.endDateContract && <tr>
                    <td><strong>Einde contract:</strong></td><td>{user.endDateContract.slice(4,15)}</td>
                  </tr>}
                  <tr>
                    <td><strong>Fiets opgehaald?</strong></td>
                    {/*{object.readyForPickUp && !user.startDateContract && <button className="Ophalen-button" onClick={this.bikeIsPickedUp} name={uid}>Opgehaald door klant</button>}*/}
                    {user.startDateContract && <td>Ja</td>}
                    {object && !object.readyForPickUp && !user.startDateContract && <td>Nee</td>}
                  </tr>
                </tbody>
              </table>
              {/*<span>
                <strong>Role:</strong> {JSON.stringify(user.roles)}
              </span>*/}
            </div>
            <div className="Werknemer-details-column">
            <h3>Objectgegevens</h3>
              <table className="Tabel-gegevens">
                <tbody>
                {object &&  <>
                  <tr>
                    <td><strong>Objectnaam:</strong></td><td>{object.objectName}</td>
                  </tr>
                  <tr>
                    <td><strong>Merk:</strong></td><td>{object.objectBrand}</td>
                  </tr>
                  <tr>
                    <td><strong>Model:</strong></td><td>{object.objectModel}</td>
                  </tr>
                  <tr>
                    <td><strong>Type:</strong></td><td>{object.objectType}</td>
                  </tr>
                  <tr>
                    <td><strong>Motor positie:</strong></td><td>{object.engineType}</td>
                  </tr>
                  <tr>
                    <td><strong>Versnelling:</strong></td><td>{object.gearType}</td>
                  </tr>
                  <tr>
                    <td><strong>Prijs:</strong></td><td>€{object.objectPrice}</td>
                  </tr>
                  <tr>
                    <td><strong>Accessoires:</strong></td>{object.accessoires && <td><ul className="Accessoires-lijst">{object.accessoires.map(accessoire => {return <li key={accessoire}>{accessoire}</li>})}</ul></td>}
                  </tr>
                  </>}
                  {!object &&  <tr><td colSpan="2">Er is nog geen object gekoppeld aan deze werknemer.</td></tr>}
                  <tr>
                    <td><strong>Definitieve verkoopfactuur fiets:</strong></td>
                  {user.downloadUrl &&   <td> <a href={user.downloadUrl} target="_blank" rel="noopener noreferrer">Download Factuur</a></td>}
                    {!user.downloadUrl &&  <td><form>
                      <input
                      name = {authUser.dealerName}
                      type = 'file'
                      value = {invoiceBike}
                      onChange = {this.onUploadInvoice}
                      />
                    </form></td>}
                  </tr>
                  <tr>
                    <td><strong>Proforma factuur fiets:</strong></td><td>{user.downloadUrlProforma &&  <a href={user.downloadUrlProforma} target="_blank" rel="noopener noreferrer">Download Proforma factuur</a>
                    }</td>
                  </tr>
                  {successUploadInvoice &&  <tr><td colSpan="2">De factuur is succesvol geupload.</td></tr>}
                </tbody>
              </table>
            </div>

          </div>

          <div className="Test">
          <div className="Service-aanvragen">
            <h3>Service aanvraagformulier</h3>
            {object && <ServiceFormulier object={object} uid={uid} dealer={authUser.dealerName}/>}
            {!object && <p>Er is nog geen object gekoppeld aan deze werknemer.</p>}
          </div>
          <div className="Service-aanvragen">
            <h3>Service aanvragen</h3>
            {object && <ServiceAanvraag object={object.objectName} />}
            {!object && <p>Er is nog geen object gekoppeld aan deze werknemer.</p>}
          </div>
          </div>
          <div className="Test">
          <div className="Onderhoudsfacturen-lijst">
            <h3>Onderhoudsfacturen</h3>
            {user.object && <OnderhoudsFacturen object={user.object} />}
            {!user.object && <p>Er is nog geen object gekoppeld aan deze werknemer.</p>}
          </div>
          </div>
          </div>
        </div>
        )}

      </div>)}
      </AuthUserContext.Consumer>
    )
  }
}

const ServiceFormulier = withFirebase(ServiceFormulierBase);
const OnderhoudsFacturen = withFirebase(OnderhoudsFacturenBase);
const ServiceAanvraag = withFirebase(ServiceAanvraagBase);

export default UserItemDealerBase;
