import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import OnderhoudsFactuurUploadenBase from './OnderhoudsFactuurUploadenBase';

// import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// import * as ROLES from '../../constants/roles';

import '../Objects/Objects.css';

class ServiceAanvraagDetailDealerBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: '',
      aanvraagUid: '',
      descriptionBO: '',
      aanvraag: '',
      dealer: '',
      dealerName: '',
      user: '',
      uid: '',
      meldingUid: '',
      dealerContactEmail: '',
      selectedOption: '',
      beoordelingUpdate: false,
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    // .serviceaanvraag(this.state.object, this.state.aanvraagUid)
    .serviceaanvraag(this.state.object, this.props.match.params.id)
    .once('value', snapshot => {
      this.setState({
        aanvraag: snapshot.val(),
        descriptionBO: snapshot.val().descriptionBO,
        dealerName: snapshot.val().dealer,
        meldingUid: this.props.match.params.id,
      })
    })
    .then(() => {
      this.props.firebase
      .dealer(this.state.dealerName)
      .once('value', snapshot => {
        this.setState({
          dealerContactEmail: snapshot.val().dealerContactEmail,
          loading:false,
        })
      })
    })
    .then(() => {
      this.props.firebase
      .object(this.state.object)
      .once('value', snapshot => {
        this.setState({
          userUid: snapshot.val().userUid,
        })
      })
    })
}

  // handleCheckboxChange = event => {
  //   const target = event.target;
  //   const value = target.name ? target.checked : target.value;
  //   const name = target.name;
  //
  //   this.setState({
  //     [name]: value
  //   });
  // }

  // onChange = event => {
  //   this.setState({ [event.target.name]: event.target.value })
  // }

  // onSubmit = event => {
  //   const { descriptionBO, selectedOption, object } = this.state;
  //
  //   this.props.firebase
  //     .user(this.state.userUid)
  //     .once('value', snapshot => {
  //       this.setState({
  //         user: snapshot.val(),
  //       })
  //     })
  //   .then(() => {
  //     this.props.firebase
  //     .serviceaanvraag(object, this.props.match.params.id)
  //     .update({
  //       descriptionBO: descriptionBO,
  //       aanvraagApproved: selectedOption,
  //     })
  //     .then(() => {
  //       this.setState({
  //         beoordelingUpdate: true
  //       })
  //     })
  //   })
  //   .then(() => {
  //     if(this.state.selectedOption === 'Afgekeurd') {
  //       this.props.firebase
  //       .serviceaanvraag(object, this.props.match.params.id)
  //       .update({
  //         aanvraagAfgerond: true,
  //       })
  //     }
  //   })
  //   .then(() => {
  //     this.sendEmailToBackoffice(event)
  //   })
  //   .then(() => {
  //     this.sendEmailToWerknemer(event)
  //   })
  //   .then(res => console.log('Melding succesvol beoordeeld', res))
  //   .catch(err => console.error('Error', err))
  //
  //   event.preventDefault();
  // };

//   sendEmailToBackoffice(event) {
//   //Verstuur mail naar dealer
//     const templateId = 'notificatie_naar_dealer_beoordeling_serviceaanvraag';
//     const userId = 'user_hxvxyKl4UEjwKu063L7iK';
//     const template_params = {
//       descriptionBO: this.state.descriptionBO,
//       aanvraagApproved: this.state.selectedOption,
//       object: this.state.object,
//       linkToMelding: `https://portal.sqoot.nl/objecten/serviceaanvraag/${this.props.match.params.id}`,
//       receiverEmail: this.state.dealerContactEmail,
//       dealerName: this.state.dealerName,
// }
//
//     this.sendFeedbackBackoffice(templateId, template_params, userId)
//   }
//
//   sendFeedbackBackoffice(templateId, template_params, userId) {
//     window.emailjs.send(
//       'default_service',
//       templateId,
//       template_params,
//       userId
//     )
//     .then(res => console.log('email verstuurd', res))
//     .catch(err => console.error('Error', err))
//   }
//
//   sendEmailToWerknemer(event) {
//
//   //Verstuur mail naar werknemer
//     const templateId = 'notificatie_naar_werknemer_beoordeling_serviceaanvraag';
//     const userId = 'user_hxvxyKl4UEjwKu063L7iK';
//     const template_params = {
//       descriptionBO: this.state.descriptionBO,
//       aanvraagApproved: this.state.selectedOption,
//       object: this.state.object,
//       receiverEmail: this.state.user.email,
//       username: this.state.user.username,
//       dealerName: this.state.dealerName,
// }
//
//     this.sendFeedbackWerknemer(templateId, template_params, userId)
//   }
//
//   sendFeedbackWerknemer(templateId, template_params, userId) {
//     window.emailjs.send(
//       'default_service',
//       templateId,
//       template_params,
//       userId
//     )
//     .then(res => console.log('email verstuurd', res))
//     .catch(err => console.error('Error', err))
//   }


  render() {
    const { loading, object, uid, meldingUid, aanvraag } = this.state;

    // const isInvalid = selectedOption === '';

    return (
      <AuthUserContext.Consumer>
      {authUser => (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        <div className="Schademelding-detail">
          <h2>Serviceaanvraag</h2>
          {loading && <div>Loading...</div>}
          <div className="Schademelding-detail-tabel">
          {aanvraag &&
          <div className="Schademelding-details">
          <h3>Overzicht aanvraag</h3>
            <table className="Tabel-gegevens">
              <tbody>
                <tr>
                  <td><strong>Datum melding</strong></td>
                  <td>{aanvraag.dateMelding.substring(0,24)}</td>
                </tr>
                <tr>
                  <td><strong>Type serviceaanvraag</strong></td>
                  <td>{aanvraag.typeAanvraag}</td>
                </tr>
                <tr>
                  <td><strong>Dealer</strong></td>
                  <td>{aanvraag.dealer}</td>
                </tr>
                <tr>
                  <td><strong>Beschrijving serviceaanvraag</strong></td>
                  <td>{aanvraag.descriptionServicebeurt}</td>
                </tr>
                <tr>
                  <td><strong>Indicatie kosten</strong></td>
                  <td>€{aanvraag.cost}</td>
                </tr>
                <tr>
                  <td><strong>Status aanvraag</strong></td>
                  {aanvraag.aanvraagApproved === 'Goedgekeurd' && <td className="Check-ok left">Goedgekeurd</td>}
                  {aanvraag.aanvraagApproved === '' && <td className="Check-not-ok-orange left">In behandeling</td>}
                  {aanvraag.aanvraagApproved === 'Afgekeurd' && <td className="Check-not-ok left">Afgekeurd</td>}
                </tr>
                <tr>
                  <td><strong>Aanvraag afgerond</strong></td>
                  {aanvraag.aanvraagAfgerond && <td className="Check-ok left">Serviceaanvraag voltooid</td>}
                  {!aanvraag.aanvraagAfgerond && <td className="Check-not-ok-orange left">Serviceaanvraag nog bezig</td>}
                </tr>
                <tr>
                  <td><strong>Opmerking van sQoot & Bike</strong></td>
                  <td>{aanvraag.descriptionBO}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      {/*  {aanvraag && !!authUser.roles[ROLES.ADMIN] &&
        <div className="Service-aanvraag-beoordelen">
        <h3>Beoordelen aanvraag</h3>
        <form onSubmit={this.onSubmit}>
        <div className="Knop-met-marge">
        <label>Opmerking voor dealer</label>
          <textarea
            onChange={this.onChange}
            name= 'descriptionBO'
            value= {descriptionBO}
            type = 'text'
            placeholder = {descriptionBO}
            />
            </div>
          <div>
        {aanvraag.aanvraagApproved === '' &&   <div className="Servicebeurt-keuze">
            <label>Beoordeling aanvraag</label>
            <div className="Servicebeurt-keuzes">
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Goedgekeurd"
                  checked={selectedOption === "Goedgekeurd"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Goedgekeurd
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="Afgekeurd"
                  checked={selectedOption === "Afgekeurd"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Afgekeurd
              </label>
            </div>
            <button disabled={isInvalid} className="Knop-met-marge" type='submit'>Opslaan</button>
            {beoordelingUpdate && <p>Beoordeling succesvol verstuurd.</p>}
          </div>}
          {aanvraag.aanvraagApproved !== '' && <p>Deze aanvraag is reeds beoordeeld.</p>}

          </div>
        </form>
        </div>}*/}

        {aanvraag && aanvraag.aanvraagApproved && !aanvraag.aanvraagAfgerond &&
          <div className="Onderhoudsfactuur-uploaden">
            <h3>Onderhoudsfactuur uploaden</h3>
            <p>Zodra de servicebeurt is voltooid, kunt u hierdonder de factuur uploaden. Hierdoor wordt de melding automatisch voltooid.</p>
            <OnderhoudsFactuurUploaden object={object} uid={uid} meldingUid={meldingUid} />
          </div>}
      </div>
    </div>
  </div>)}
  </AuthUserContext.Consumer>
    )
  }
}

const OnderhoudsFactuurUploaden = withFirebase(OnderhoudsFactuurUploadenBase);


export default withRouter(ServiceAanvraagDetailDealerBase);
