import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import CompanyInvoicesBase from '../Companies/CompanyInvoicesBase';

import * as ROUTES from '../../constants/routes';

class WerkgeverFacturenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      companyName: '',
      company:'',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.props.firebase
    .company(this.state.companyName)
    .on('value', snapshot => {
      this.setState({
        company:snapshot.val()
      })
    })

  }

  componentWillUnmount() {
    this.props.firebase.company(this.state.companyName).off();
  }

render() {
  const { loading, company } = this.state;
  // console.log('company', this.state.company)
  // console.log('companyName', companyName)

  return (
    <AuthUserContext.Consumer>

      {authUser => (
      <div className="Overzicht-werknemers">
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.WERKGEVER}`,
          }}
          >
            Terug naar overzicht
        </Link>
      </div>
      <div className="Facturen-werkgever">
        <div className="Goedgekeurde-werknemers">
          <h2>Raamcontract</h2>
          {loading && <div>Loading...</div>}
          {company.downloadUrlRaamContract && <a href={company.downloadUrlRaamContract} target="_blank" rel="noopener noreferrer">Download raamcontract</a>}
          {!company.downloadUrlRaamContract && <p>Raamcontract nog niet beschikbaar.</p>}
        </div>
        <div className="Goedgekeurde-werknemers">
          <h2>Overzicht facturen</h2>
          {loading && <div>Loading...</div>}
          <CompanyInvoices company={authUser.companyName}/>
        </div>
      </div>
    </div>
  )}
</AuthUserContext.Consumer>
  )}
}

const CompanyInvoices = withFirebase(CompanyInvoicesBase);


export default WerkgeverFacturenBase;
