import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

// import * as ROUTES from '../../constants/routes';

class SchadeFormulierPoolobjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: '',
      selectedOption: "nee",
      selectedOptionIncident: 'nee',
      startDate: new Date(),
      dateSchade: '',
      name:'',
      description: '',
      descriptionOntstaanSchade: '',
      user: '',
      email: '',
      phoneNumber: '',
      address: '',
      zipCode: '',
      place: '',
      insurance: '',
      schadeFormulier: '',
      schadesList: '',
      downloadUrlSchademelding: '',
      uid: '',
      nameSchademelding: '',
      numberOfSchademelding: '',
      success: false,
      error: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.firebase
    .user(this.state.uid)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
      })
    })
  }


  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleDateChange = date => {
    this.setState({
      startDate: date,
      dateSchade: date
    })
  }

  handleSubmit = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    if(this.state.schadeFormulier) {
      const uploadTask = this.props.firebase.schade(this.state.schadeFormulier, this.state.object, date);
      uploadTask.on('state_changed', snapshot => {

      }, error => {
        this.setState({
          error: true
        })

      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

          this.setState({
            downloadUrlSchademelding: downloadURL
          })

          this.pushSchademeldingToObject();

          // this.props.firebase
          // .user(this.state.uid)
          // .update({
          //   // downloadMaintenanceUrl: downloadURL,
          // })
          // .then(res => {
          //   this.setState({
          //     downloadUrlSchademelding: downloadURL
          //   })
          // })
          // .then(() => {
          //   this.pushSchademeldingToObject();
          // })
      })
    })
  } else {
    this.pushSchademeldingToObject();
  }

    event.preventDefault();
}



  onUpload = event => {
    this.setState({
      schadeFormulier: event.target.files[0],
    })
  }

  pushSchademeldingToObject(event) {
    const schadeRef = this.props.firebase
    .object(this.state.object)

    const objectSchademeldingen = schadeRef.child('schademeldingen')
    console.log('objectSchademeldingen', objectSchademeldingen)

    const date = new Date().toString();
    const dateForName = date.slice(4,21);

    objectSchademeldingen.push({
      dateSchade: this.state.startDate.toString(),
      nameMelding: `schademelding - ${dateForName}`,
      description: this.state.description,
      descriptionOntstaanSchade: this.state.descriptionOntstaanSchade,
      downloadUrlSchademelding: this.state.downloadUrlSchademelding,
      derden: this.state.selectedOption,
      naamTegenPartij: this.state.name,
      mailTegenPartij: this.state.email,
      phoneTegenPartij: this.state.phoneNumber,
      addressTegenPartij: this.state.address,
      zipCodeTegenPartij: this.state.zipCode,
      placeTegenPartij: this.state.place,
      incidentTegenPartij: this.state.selectedOptionIncident,
      dateMelding: new Date().toString(),
      schadeFormulierOntvangen: false,
      schadeBedragOntvangen: false,
      schadeFormulierVerstuurdEnra: false,
      akkoordEnra: false,
      akkoordGevenHerstel: false,
      eigenRisicoGebruiker: false,
      meldingAfgehandeld: false,
      descriptionBO: '',
      object: this.state.object,
    })

    console.log('push uitgevoerd')

    this.sendEmailToBackoffice(event);

    //update user activity
    // const ref = this.props.firebase
    // .user(this.state.uid)
    //
    // const userActivity = ref.child('activity')
    //
    // userActivity.push({
    //   activity: 'Schade gemeld door werknemer',
    //   createdAt: new Date().toString(),
    // })
    //
    // this.setState({
    //   success: true
    // })

    //set state to normal
      this.setState({
        selectedOption: "nee",
        selectedOptionIncident: 'nee',
        dateSchade: '',
        name:'',
        description: '',
        descriptionOntstaanSchade: '',
        email: '',
        phoneNumber: '',
        address: '',
        zipCode: '',
        place: '',
        insurance: '',
        schadeFormulier: '',
        downloadUrlSchademelding: '',
        nameSchademelding: '',
        numberOfSchademelding: '',
        success: true,
        error: false,
      })

  }

  sendEmailToBackoffice(event) {
  //Verstuur mail naar dealer
    const templateId = 'notificatie_naar_backoffice_schadeformulier_geupload';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      werknemerNaam: `${this.state.user.username} (Betreft poolobject)`,
      linkToObject: `https://portal.sqoot.nl/objecten/object/${this.state.object}`,
}

    this.sendFeedbackBackoffice(templateId, template_params, userId)
  }

  sendFeedbackBackoffice(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }


  render() {
    const {selectedOption, selectedOptionIncident, description, descriptionOntstaanSchade, name, email, phoneNumber, address, zipCode, place, insurance, success, error} = this.state;

    // console.log('datum', startDate)
    // console.log('UID', uid)
    // console.log('object FORMULIER', this.props.object)
    // console.log('schadeformulier render', this.state.schadeFormulier)
    // console.log('numberOfSchademelding', numberOfSchademelding)
    // console.log('schadesList', schadesList)
    // console.log('nameSchademelding', nameSchademelding)
    // console.log('object', object)
    // console.log('user', user)


    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        <h2>Vul hieronder het schade/reparatie formulier in</h2>
          <div className="Schadeformulier">
          <p>In geval van een reparatieverzoek of schademelding kunt u onderstaand formulier invullen.</p>
          <form onSubmit={this.handleSubmit}>
            <div>
            <label>Datum van de reparatie / schade</label>
              <div>
                <DatePicker selected={this.state.startDate} onChange = {this.handleDateChange} />
              </div>
            </div>
            <div>
            <label>Beschrijving van de reparatie / schade</label>
            <textarea
              onChange={this.onChange}
              name= 'description'
              value= {description}
              type = 'text'
              />
            </div>
            <div>
            <label>Hoe is de schade ontstaan?</label>
            <textarea
              onChange={this.onChange}
              name= 'descriptionOntstaanSchade'
              value= {descriptionOntstaanSchade}
              type = 'text'
              />
            </div>
            <label>Heeft u gegevens van de eventuele tegenpartij?</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="ja"
                  checked={this.state.selectedOption === "ja"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Ja
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="selectedOption"
                  value="nee"
                  checked={this.state.selectedOption === "nee"}
                  onChange={this.onChange}
                  className="form-check-input"
                />
                Nee
              </label>
            </div>

            {selectedOption === 'ja' && <div className="Gegevens-tegenpartij">
            <h3>Vul hieronder de gegevens in van de tegenpartij</h3>
              <label>Naam</label>
              <input
              name = 'name'
              value = {name}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Mailadres</label>
              <input
              name = 'email'
              value = {email}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Telefoonnummer</label>
              <input
              name = 'phoneNumber'
              value = {phoneNumber}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Adres</label>
              <input
              name = 'address'
              value = {address}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Postcode</label>
              <input
              name = 'zipCode'
              value = {zipCode}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Plaats</label>
              <input
              name = 'place'
              value = {place}
              onChange = {this.onChange}
              type = 'text'
              />
              <label>Gegevens verzekering</label>
              <input
              name = 'insurance'
              value = {insurance}
              onChange = {this.onChange}
              type = 'text'
              />
            </div>}
            <div className="Betrokken-bij-ongeval">
              <label>Bent u betrokken geweest bij een ongeval?</label>
              <div>
                <label>
                  <input
                    type="radio"
                    name="selectedOptionIncident"
                    value="ja"
                    checked={this.state.selectedOptionIncident === "ja"}
                    onChange={this.onChange}
                    className="form-check-input"
                  />
                  Ja
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="selectedOptionIncident"
                    value="nee"
                    checked={this.state.selectedOptionIncident === "nee"}
                    onChange={this.onChange}
                    className="form-check-input"
                  />
                  Nee
                </label>
              </div>


            {selectedOptionIncident === 'ja' && <div className="Upload-schadeformulier">
            <span >
              <label>Upload schadeformulier: </label>
              <input
                name = 'file'
                type = 'file'
                onChange = {this.onUpload}
                />
              <div>


              </div>
            </span>
            </div>}
            </div>

            {!success && <button className="Verzend-knop" onClick={this.handleSubmit}>Verzenden</button>}
            {success &&  <p>Het schadeformulier is succesvol verstuurd.</p>}
            {error &&  <p>Er ging iets mis tijdens het uploaden. Probeer het opnieuw.</p>}
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(SchadeFormulierPoolobjectBase);
