import React , { Component } from 'react';
import { Link } from 'react-router-dom';

import { differenceInMonths } from 'date-fns';

// import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

class UserListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      phases:["Nieuw", "Werkgever akkoord", "Aanvragen verzekering", "Wacht op uitgifte fiets", "Lopend contract"],
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      })
    );

    this.checkDrieMaandenOnderhoud(usersList); //checkt bij users of contract al langer dan 3 maanden loopt, en zo ja, verstuurd mail over onderhoud.

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

checkDrieMaandenOnderhoud = users => {

  const date = new Date();

  users
  .filter(user => {
    return !user.onderhoudsMailDrieMaandenVerstuurd // filter op iedereen waarbij onderhoudsMailDrieMaandenVerstuurd niet true is
  })
  .filter(user => {
    return user.startDateContract // toont alleen users waarbij startdatum is ingevuld
  })
  .filter(user => {

  const result = differenceInMonths(date, new Date(user.startDateContract)) //toont verschil in maanden tussen vandaag en startDatumContract

    console.log('Verschil in datum', result)
    if(result >= 3) { //als verschil gelijk of groter dan 3 maanden is, wordt mail verstuurd en user geupdate
    return user}
    return null //returned null als if statement niks returned
  })
  .forEach(user => {
    this.sendEmailToWerknemer(user)

    this.props.firebase
    .user(user.uid)
    .update({
      onderhoudsMailDrieMaandenVerstuurd: true
    })

    //update user activity
    const ref = this.props.firebase
    .user(user.uid)

    const userActivity = ref.child('activity')

    userActivity.push({
      activity: '3 maanden onderhoudse-mail is verstuurd.',
      createdAt: new Date().toString(),
    })

  })
}

sendEmailToWerknemer(user) {
//Verstuur mail naar werkgever
  const templateId = '3_maanden_onderhoud';
  const userId = 'user_hxvxyKl4UEjwKu063L7iK';
  const template_params = {
    receiverEmail: user.email,
    name: user.username,
    dealer: user.dealer
  }

  this.sendFeedbackWerknemer(templateId, template_params, userId)
}

sendFeedbackWerknemer(templateId, template_params, userId) {
  window.emailjs.send(
    'default_service',
    templateId,
    template_params,
    userId
  )
  .then(res => console.log('email verstuurd', res))
  .catch(err => console.error('Error', err))
}

render() {
  const { users, loading, phases } = this.state;

  return (
      <div>
      <div className="Poolobject-aanmaken-link">
        <Link to={{
            pathname: `${ROUTES.ADMIN}/list`,
            // state: { object },
          }}
          >
            Lijstweergave
        </Link>
      </div>
      <div className='Phases'>
      {phases.map(phase => {
        return (
          <div key={phase} className='Phase'>
            <h3 className="Phase-header">{phase}</h3>
            {loading && <div>Loading...</div>}

              {users
                .sort((a, b) => {
                  if (a.username < b.username) {
                    return -1;
                  }
                  if (a.username > b.username) {
                    return 1;
                  }
                  return 0;
                })
                .filter(user => {
                  return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                })
                .filter(user => {
                  return user.phase === phase
                })
                .map(user => (
                <div key={user.uid} className="Werknemer">
                  <span className="Werknemer-naam">
                    <Link to={{
                      pathname: `${ROUTES.ADMIN}/user/${user.uid}`,
                      state: { user },
                    }}
                    >
                      <strong>{user.username}</strong>
                    </Link>
                  </span>
                  <span className="Werknemer-naam-werkgever">
                    <Link to={{
                      pathname: `${ROUTES.COMPANIES}/company/${user.company}`,
                      state: { user },
                    }}
                    >
                      {user.company}
                    </Link>
                  </span>

                </div>
              ))}

          </div>

        )
      })}
    </div>
    </div>
  );
}
}

export default UserListBase;
