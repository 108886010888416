import React , { Component } from 'react';
import { Link } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

class UserListViewBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "username",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.ADMIN}/user/${params.data.uid}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      }, {
        headerName: "Bedrijf", field: "company",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.COMPANIES}/company/${params.value}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      },
      {
        headerName: "Object", field: "object",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.OBJECTS}/object/${params.value}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      },
      {
        headerName: "Start contract", field: "startDateContract",
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }, filter: false
      },
      {
        headerName: "Eind contract", field: "endDateContract",
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }, filter: false
      },
      {
        headerName: "Fase", field: "phase"
      },
    ],
    defaultColDef: {
    width: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  },
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      })
    );

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

render() {
  const { users, loading } = this.state;

  return (

    <div className="Company-werknemers">
    <div className="Poolobject-aanmaken-link">
      <Link to={{
          pathname: `${ROUTES.ADMIN}`,
          // state: { object },
        }}
        >
          Kanban weergave
      </Link>
    </div>
      <h2>Overzicht werknemers</h2>
      {loading && <div>Loading...</div>}
      <div
        className="ag-theme-alpine"
        style={{
        height: '500px',
        }}
      >
        <AgGridReact
          reactNext={true} //
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          pagination={true}
          paginationPageSize={this.state.paginationPageSize}
          paginationAutoPageSize={true}
          rowData={users.filter(user => {
            return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
          })}>
        </AgGridReact>
      </div>
      {/*<table className="Tabel-full-width">
        <tbody>
          <tr>
            <th>Naam</th><th>Bedrijf</th><th>Fase</th>
          </tr>
        {users
          .sort((a, b) => {
            if (a.username < b.username) {
              return -1;
            }
            if (a.username > b.username) {
              return 1;
            }
            return 0;
          })
          .filter(user => {
            return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
          })
          .map(user => (
          <tr key={user.uid} >
            <td><Link to={{
              pathname: `${ROUTES.ADMIN}/user/${user.uid}`,
              state: { user },
            }}
            >
              {user.username}
            </Link></td>
            <td>
              <Link to={{
                pathname: `${ROUTES.COMPANIES}/company/${user.company}`,
                state: { user },
              }}
              >
                {user.company}
              </Link>
            </td>
            <td>{user.phase}</td>
          </tr>
        ))}
      </tbody>
    </table>*/}
  </div>

  );
}
}

export default UserListViewBase;
