import React , {Component} from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import "./PasswordForgetForm.css";


const PasswordForgetPage = () => (
  <div className="Page">
    <h1>Wachtwoord vergeten</h1>
      <div className='Wachtwoord-formulier'>
        <PasswordForgetForm />
      </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase.doPasswordReset(email)
    .then(() => {
      this.setState({...INITIAL_STATE});
    })
    .catch(error => {
      this.setState({error});
    });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };

  render() {
    const {email, error} = this.state;

    const isInvalid = email === '';

    return(
      <div className="PasswordForgetForm">
        <h2>Wachtwoord vergeten?</h2>
        <p>Vul hieronder je e-mailadres in om een nieuw wachtwoord aan te vragen.</p>
        <form onSubmit={this.onSubmit} className="Wachtwoord-formulier">
          <label>E-mailadres</label>
          <input
            name = 'email'
            value = {email}
            onChange = {this.onChange}
            type = 'text'
          />
          <button disabled={isInvalid} type='submit'>Reset mijn wachtwoord</button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Wachtwoord vergeten?</Link>
  </p>
)

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
