import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';

import './Objects.css';

class DiefstalmeldingenDetailBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: '',
      meldingUid: '',
      copyAangifte: false,
      diefstalGemeldEnra: false,
      sleutelsOntvangen: false,
      sleutelsVerstuurdEnra: false,
      schadeBedragOntvangenEnra: false,
      eigenRisicoGebruiker: false,
      nieuweFietsInBestelling: false,
      stappenUpdate: false,
      meldingAfgehandeld: false,
      description: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .diefstalmelding(this.state.object, this.state.meldingUid)
    .on('value', snapshot => {
      this.setState({
        melding: snapshot.val(),
        loading:false,
        copyAangifte: snapshot.val().copyAangifte,
        diefstalGemeldEnra: snapshot.val().diefstalGemeldEnra,
        sleutelsOntvangen: snapshot.val().sleutelsOntvangen,
        sleutelsVerstuurdEnra: snapshot.val().sleutelsVerstuurdEnra,
        schadeBedragOntvangenEnra: snapshot.val().schadeBedragOntvangenEnra,
        eigenRisicoGebruiker: snapshot.val().eigenRisicoGebruiker,
        nieuweFietsInBestelling: snapshot.val().nieuweFietsInBestelling,
        meldingAfgehandeld: snapshot.val().meldingAfgehandeld,
        description: snapshot.val().description,
      })
    })
}

  handleCheckboxChange = event => {
    const target = event.target;
    const value = target.name ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }


  onSubmit = event => {
    const { copyAangifte, diefstalGemeldEnra, sleutelsOntvangen, sleutelsVerstuurdEnra, schadeBedragOntvangenEnra, eigenRisicoGebruiker,  nieuweFietsInBestelling, meldingAfgehandeld, description, object } = this.state;

    this.props.firebase
    .diefstalmelding(object, this.props.match.params.id)
    .update({
      copyAangifte: copyAangifte,
      diefstalGemeldEnra: diefstalGemeldEnra,
      sleutelsOntvangen: sleutelsOntvangen,
      sleutelsVerstuurdEnra: sleutelsVerstuurdEnra,
      schadeBedragOntvangenEnra: schadeBedragOntvangenEnra,
      eigenRisicoGebruiker: eigenRisicoGebruiker,
      nieuweFietsInBestelling: nieuweFietsInBestelling,
      meldingAfgehandeld: meldingAfgehandeld,
      description: description,
    })
    .then(() => {
      this.setState({
        stappenUpdate: true
      })
    })
    .then(res => console.log('Stappenplan succesvol bijgewerkt', res))
    .catch(err => console.error('Error', err))

    event.preventDefault();
  };


  render() {
    const { loading,
            melding,
            copyAangifte,
            diefstalGemeldEnra,
            sleutelsOntvangen,
            sleutelsVerstuurdEnra,
            schadeBedragOntvangenEnra,
            eigenRisicoGebruiker,
            nieuweFietsInBestelling,
            stappenUpdate,
            description,
            meldingAfgehandeld } = this.state;

    console.log('meldingAfgehandeld', meldingAfgehandeld)

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        <div className="Schademelding-detail">
          <h2>Diefstalmelding</h2>
          {loading && <div>Loading...</div>}
          <div className="Schademelding-detail-tabel">
          {melding &&
          <div>
            <table className="Tabel-gegevens">
              <tbody>
                <tr>
                  <td><strong>Datum melding</strong></td>
                  <td>{melding.dateMelding.substring(0,24)}</td>
                </tr>
                <tr>
                  <td><strong>Datum diefstal</strong></td>
                  <td>{melding.diefstalDate.substring(0,24)}</td>
                </tr>
                <tr>
                  <td><strong>Download aangifte</strong></td>
                  {melding.downloadUrlAangifte && <td><a href={melding.downloadUrlAangifte} target="_blank" rel="noopener noreferrer">Download aangifte</a></td>}
                </tr>
              </tbody>
            </table>
          </div>
        }
        {melding &&
        <div>
          <form className="Stappen" onSubmit={this.onSubmit}>
          <label>
            <input
              type="checkbox"
              name="copyAangifte"
              checked={copyAangifte}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Kopie van aangifte ontvangen
          </label>
          <label>
            <input
              type="checkbox"
              name="diefstalGemeldEnra"
              checked={diefstalGemeldEnra}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Diefstal gemeld bij Enra
          </label>
          <label>
            <input
              type="checkbox"
              name="sleutelsOntvangen"
              checked={sleutelsOntvangen}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Sleutels ontvangen van gebruiker
          </label>
          <label>
            <input
              type="checkbox"
              name="sleutelsVerstuurdEnra"
              checked={sleutelsVerstuurdEnra}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Sleutels verstuurd naar Enra
          </label>
          <label>
            <input
              type="checkbox"
              name="schadeBedragOntvangenEnra"
              checked={schadeBedragOntvangenEnra}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Schadebedrag ontvangen vanuit Enra
          </label>
          <label>
            <input
              type="checkbox"
              name="eigenRisicoGebruiker"
              checked={eigenRisicoGebruiker}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Eventueel eigen risico in rekening gebracht bij gebruiker
          </label>
          <label>
            <input
              type="checkbox"
              name="nieuweFietsInBestelling"
              checked={nieuweFietsInBestelling}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Nieuwe fiets in bestelling voor gebruiker
          </label>
          <label>
            <input
              type="checkbox"
              name="meldingAfgehandeld"
              checked={meldingAfgehandeld}
              onChange={this.handleCheckboxChange}
              className="form-check-input"
            />
            Melding afgehandeld
          </label>
          <div className="Knop-met-marge">
          <label>Opmerkingen</label>
            <textarea
              onChange={this.onChange}
              name= 'description'
              value= {description}
              type = 'text'
              placeholder = {description}
              />
          </div>
          {stappenUpdate && <p>Succesvol aangepast.</p>}

          <button className="Knop-met-marge" type='submit'>Opslaan</button>

          </form>
        </div>}
      </div>
    </div>
  </div>
    )
  }
}

export default withRouter(DiefstalmeldingenDetailBase);
