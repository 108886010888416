import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

class PechFormulierBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: '',
      pechhulp: '',
      ...props.location.state
    };
  }

  componentDidMount() {
    this.props.firebase
    .object(this.state.object)
    .once('value', snapshot => {
      this.setState({
        pechhulp: snapshot.val().pechhulp
      })
    })
  }



  render() {

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        <h3>Pech onderweg</h3>
        <p>Kunt u onverwachts niet verder? Neem dan contact op met de alarmservice van ENRA (verzekeringsmaatschappij e-bike) via telefoonnummer 088-8778044 en houd uw polisnummer bij de hand. Uw polisnummer kunt u in de portal terugvinden onder het tabblad “objectgegevens”.
        <br/><br/>ENRA pechhulpservice repareert uw fiets niet, maar zorgt ervoor dat u en uw fiets teruggebracht worden naar de startplaats van de fietstocht. Meer informatie kunt u terugvinden in de <a  href="https://sqoot.nl/wp-content/uploads/2022/02/Pech-onderweg.pdf" target="_blank" rel="noopener noreferrer">handleiding</a>. </p>.
      </div>
    )
  }
}

export default withRouter(PechFormulierBase);
