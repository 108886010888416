import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import DatePicker from 'react-datepicker';

import './Werkgever.css';

class WerkgeverEditPoolobjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      user: null,
      poolObjectDriver: '',
      poolObjectDriverEmail: '',
      poolObjectDriverPhone: '',
      poolObjectLocation: '',
      poolObjectStartDate: '',
      poolObjectEndDate: '',
      gegevensGewzijgd: false,
      error: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        uid: snapshot.val().userUid,
        poolObjectDriver: snapshot.val().poolObjectDriver,
        poolObjectDriverEmail: snapshot.val().poolObjectDriverEmail,
        poolObjectDriverPhone: snapshot.val().poolObjectDriverPhone,
        poolObjectLocation: snapshot.val().poolObjectLocation,
        loading: false,
      });
    })
}

  componentWillUnmount() {
    this.props.firebase.object(this.props.match.params.id).off();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleDateChangeStartDate = date => {
    this.setState({
      poolObjectStartDate: date,
    })
  }

  handleDateChangeEndDate = date => {
    this.setState({
      poolObjectEndDate: date,
    })
  }

  onSubmit = event => {

    const { poolObjectDriver, poolObjectDriverEmail, poolObjectStartDate, poolObjectEndDate, poolObjectLocation} = this.state;

    this.props.firebase
    .object(this.props.match.params.id)
    .update({
      poolObjectDriver: this.state.poolObjectDriver,
      poolObjectDriverEmail: this.state.poolObjectDriverEmail,
      poolObjectDriverPhone: this.state.poolObjectDriverPhone,
      poolObjectLocation: this.state.poolObjectLocation,
      poolObjectStartDate: this.state.poolObjectStartDate.toString(),
      poolObjectEndDate: this.state.poolObjectEndDate.toString(),
    })
    .then(() => {
      //update object activity

      const ref = this.props.firebase
        .object(this.props.match.params.id)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `Gegevens berijder poolobject gewijzigd: ${poolObjectDriver} (${poolObjectDriverEmail}) van ${poolObjectStartDate.toString().slice(4,16)} tot ${poolObjectEndDate.toString().slice(4,16)} op locatie ${poolObjectLocation}`,
        createdAt: new Date().toString(),
      })
    })
    .then(res => console.log('Gegevens succesvol aangepast', res))
    .catch(err => console.error('Error', err));

    this.setState({
      gegevensGewzijgd: true,
    })


    event.preventDefault();
  }

  render() {
    const { object, poolObjectDriver, poolObjectDriverEmail, poolObjectDriverPhone, poolObjectLocation, gegevensGewzijgd, loading } = this.state;

    console.log('object', object)
    console.log('objectName', this.props.match.params.id)

    return (
      <div>

        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>

        <div className="Object">
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}

            {object && (
              <div className="Object-detail-tabel-edit">
              <form onSubmit={this.onSubmit}>
              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>Naam berijder</strong></td>
                    <td>
                    <input
                      name = 'poolObjectDriver'
                      value = {poolObjectDriver}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {poolObjectDriver}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>E-mailadres berijder</strong></td>
                    <td><input
                      name = 'poolObjectDriverEmail'
                      value = {poolObjectDriverEmail}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {poolObjectDriverEmail}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Telefoonnummer berijder</strong></td>
                    <td><input
                      name = 'poolObjectDriverPhone'
                      value = {poolObjectDriverPhone}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {poolObjectDriverPhone}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Locatie poolobject</strong></td>
                    <td>
                    <input
                      name = 'poolObjectLocation'
                      value = {poolObjectLocation}
                      onChange = {this.onChange}
                      type = 'text'
                      placeholder = {poolObjectLocation}
                    /></td>
                  </tr>
                  <tr>
                    <td><strong>Startdatum</strong></td>
                    <td>
                      <DatePicker selected={this.state.poolObjectStartDate} onChange = {this.handleDateChangeStartDate} />
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Einddatum</strong></td>
                    <td>
                      <DatePicker selected={this.state.poolObjectEndDate} onChange = {this.handleDateChangeEndDate} />
                    </td>
                  </tr>

                </tbody>
              </table>
              <button type='submit'>Opslaan</button>
              {gegevensGewzijgd && <p>De gegevens zijn succesvol gewijzigd.</p>}
              </form>
            </div>
          )}
          </div>

        </div>
    </div>
    )
  }
}


export default withRouter(WerkgeverEditPoolobjectBase);
