import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import './Dealer.css';

class UsersDealerListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      uid: '',
      user: '',
      company:'',
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "username"
    },    {
        headerName: "E-mailadres", field: "email"
    }, {
        headerName: "Telefoonnummer", field: "phoneNumber", sortable: false,
    },    {
        headerName: "Bedrijf", field: "company"
    }, {
        headerName: "Object", field: "object"
    }, {
        headerName: "Def factuur geupload", field: "downloadUrl",
          cellRendererFramework: (params) => {
            if(params.value) {
              return 'Ja'
            } else {
              return 'Nee'
            }
        }
    }, {
        headerName: "Fiets opgehaald?", field: "readyForPickUp",
          cellRendererFramework: (params) => {
            // console.log('params', params)
            if(params.value && !params.data.startDateContract) {
              return 'Klaar voor ophalen'
            } else if(params.data.startDateContract) {
              return 'Ja'
            } else if(!params.value && !params.data.startDateContract) {
              return 'Nee'
            }
        }
    }, {
      headerName: "Details", field: "username", filter: false, sortable: false,
      cellRendererFramework: (params) => {
        // console.log('params', params)
        return <Link to={{
          pathname: `${ROUTES.DEALER}/user/${params.data.uid}`,
          state: { user: params.data },
        }}
        >
          <strong>Details</strong>
        </Link>
    }}],
        defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
      },
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  // bikeIsPickedUp = event => {
  //
  //   this.props.firebase
  //   .user(event.target.name)
  //   .once('value', snapshot => {
  //     this.setState({
  //       user: snapshot.val(),
  //       uid: event.target.name,
  //       // loading: false,
  //     })
  //   })
  //   .then(() => {
  //     this.props.firebase
  //     .user(this.state.uid)
  //     .update({
  //       startDateContract: new Date().toString(),
  //       phase: "Lopend contract"
  //     })
  //   })
  //   .then(() => {
  //     this.props.firebase
  //     .object(this.state.user.object)
  //     .update({
  //       active: true,
  //     })
  //   })
  //   .then(() => {
  //     //update user activity
  //     const ref = this.props.firebase
  //     .user(this.state.uid)
  //
  //     const userActivity = ref.child('activity')
  //
  //     userActivity.push({
  //       activity: 'Fiets is opgehaald en leasecontract is gestart -> Naar fase Lopend contract',
  //       createdAt: new Date().toString(),
  //     })
  //   })
  //   .then(() => {
  //     //send email to backoffice
  //     this.sendEmailToBackoffice(event);
  //   })
  // }
  //
  // sendEmailToBackoffice(event) {
  // //Verstuur mail naar BO
  //   const templateId = 'fiets_is_opgehaald_door_werknemer';
  //   const userId = 'user_hxvxyKl4UEjwKu063L7iK';
  //   const template_params = {
  //     werknemerNaam: this.state.user.username,
  //     werkgeverNaam: this.state.user.company,
  //     werknemerMail: this.state.user.email,
  //     dealer: this.state.user.dealer,
  //     linkToUser: `https://portal.sqoot.nl/admin/user/${this.state.uid}`,
  //     uid: this.state.uid,
  //   }
  //   this.sendFeedbackBackoffice(templateId, template_params, userId)
  // }
  //
  // sendFeedbackBackoffice(templateId, template_params, userId) {
  //   window.emailjs.send(
  //     'default_service',
  //     templateId,
  //     template_params,
  //     userId
  //   )
  //   .then(res => console.log('email verstuurd', res))
  //   .catch(err => console.error('Error', err))
  // }

render() {
  const { users, loading } = this.state;

  return (
    <AuthUserContext.Consumer>
      {authUser => (
      <div>
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.DEALER}`,
            // state: { user },
          }}
          >
            Naar dashboard
        </Link>
      </div>
      <div className="Dealer-klanten">
        <h2>Klanten</h2>
        {loading && <div>Loading...</div>}
        <div
          className="ag-theme-alpine"
          style={{
          height: '600px',
          }}
        >
          <AgGridReact
            reactNext={true} //
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            pagination={true}
            paginationPageSize={this.state.paginationPageSize}
            paginationAutoPageSize={true}
            rowData={users
              .sort((a, b) => {
                if (a.username < b.username) {
                  return -1;
                }
                if (a.username > b.username) {
                  return 1;
                }
                return 0;
              })
              .filter(user => {
                return user.dealer === authUser.dealerName
              })}>
          </AgGridReact>
        </div>
        </div>
    </div>
  )}
</AuthUserContext.Consumer>
  )}
}

export default UsersDealerListBase;
