import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import UserListBase from './UserListBase';
import UserListViewBase from './UserListViewBase';
import UserItemBase from './UserItemBase';
import MeldingenBase from './MeldingenBase';
import UserItemEditBase from './UserItemEditBase';
import ChangeContractDateBase from './ChangeContractDateBase';
import EndContractBase from './EndContractBase';
import ChangeObjectBase from './ChangeObjectBase';

import "./Admin.css";

const AdminPage = () => (
  <div className="Page">
    <h1>Dashboard</h1>
    <p>Hier vind je een overzicht van alle deelnemende werknemers.</p>

    <Switch>
      <React.Fragment>
      <div className="Overicht-werknemers">
      <Route exact path={ROUTES.ADMIN_CHANGE_OBJECT} component={ChangeObject} />
      <Route exact path={ROUTES.ADMIN_END_CONTRACT} component={EndContract} />
      <Route exact path={ROUTES.ADMIN_CONTRACT_DATE_EDIT} component={ChangeContractDate} />
      <Route exact path={ROUTES.ADMIN_DETAILS_EDIT} component={UserItemEdit} />
      <Route exact path={ROUTES.ADMIN_MELDINGEN} component={Meldingen} />
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.ADMIN_LIST_VIEW} component={UserListView}/>
      <Route exact path={ROUTES.ADMIN} component={UserList}/>
      </div>
      </React.Fragment>
    </Switch>
  </div>
)

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const UserList = withFirebase(UserListBase);
const UserListView = withFirebase(UserListViewBase);
const UserItem = withFirebase(UserItemBase);
const Meldingen = withFirebase(MeldingenBase);
const UserItemEdit = withFirebase(UserItemEditBase);
const ChangeContractDate = withFirebase(ChangeContractDateBase);
const EndContract = withFirebase(EndContractBase);
const ChangeObject = withFirebase(ChangeObjectBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
