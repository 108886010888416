import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import '../Objects/Objects.css';

class MeldingenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      objects:[],
      alleMeldingen: [],
      alleSchadeMeldingen: [],
      alleServiceAanvragen: [],
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    const alleMeldingen = []
    const meldingen = []
    const alleSchadeMeldingen = []
    const schadeMeldingen = []
    const alleServiceAanvragen = []
    const serviceAanvragen = []

    this.props.firebase
    .objects()
    .once('value', snapshot => {
      const objectsObject = snapshot.val();

      const objectsList = Object.keys(objectsObject).map(key => ({
        ...objectsObject[key],
        uid: key,
      }));

      this.setState({
        objects: objectsList,
        loading: false,
      });
    })
    .then(() => {
      this.state.objects.forEach(object => {

        if(object.diefstallen) {
        const meldingenList = Object.keys(object.diefstallen).map(key => ({
          ...object.diefstallen[key],
          uid: key,
        }))

        alleMeldingen.push(meldingenList)
      }
      })
    })
    .then(() => {
      alleMeldingen.forEach(alleMelding => {
        alleMelding.forEach(melding => {
          meldingen.push(melding)
        })
      })
      this.setState({
        alleMeldingen: meldingen
      })
    })
    .then(() => {
      this.state.objects.forEach(object => {

        if(object.schademeldingen) {
        const schademeldingenList = Object.keys(object.schademeldingen).map(key => ({
          ...object.schademeldingen[key],
          uid: key,
        }))

        alleSchadeMeldingen.push(schademeldingenList)
      }
      })
    })
    .then(() => {
      alleSchadeMeldingen.forEach(alleSchadeMelding => {
        alleSchadeMelding.forEach(melding => {
          schadeMeldingen.push(melding)
        })
      })
      this.setState({
        alleSchadeMeldingen: schadeMeldingen
      })
    })
    .then(() => {
      this.state.objects.forEach(object => {

        if(object.serviceaanvragen) {
        const serviceAanvragenList = Object.keys(object.serviceaanvragen).map(key => ({
          ...object.serviceaanvragen[key],
          uid: key,
        }))

        alleServiceAanvragen.push(serviceAanvragenList)
      }
      })
    })
    .then(() => {
      alleServiceAanvragen.forEach(alleServiceAanvraag => {
        alleServiceAanvraag.forEach(aanvraag => {
          serviceAanvragen.push(aanvraag)
        })
      })
      this.setState({
        alleServiceAanvragen: serviceAanvragen
      })
    })
    ;
  }

  componentWillUnmount() {
    // this.props.firebase.objects().off();
  }

render() {
  const { alleMeldingen, alleSchadeMeldingen, alleServiceAanvragen, loading } = this.state;

  // console.log('meldingen', meldingen)
  // console.log('objects', objects)
  // console.log('Alle meldingen', alleMeldingen)
  // console.log('Alle schademeldingen', alleSchadeMeldingen)

  return (
    <div className="Objects">
      <div className="Non-active-objects">
        <h2>Openstaande diefstalmeldingen</h2>
        {loading && <div>Loading...</div>}
        {alleMeldingen &&
        <table className="Tabel-full-width">
        <thead>
          <tr>
            <td><strong>Melding</strong></td>
            <td><strong>Object</strong></td>
            <td><strong>Melding afgehandeld</strong></td>
            <td><strong>Details</strong></td>
          </tr>
        </thead>
          <tbody>
        {Object.values(alleMeldingen).filter(melding => {
          return melding.meldingAfgehandeld === false
        }).map(melding => (
          <tr key={melding.uid}>
            <td>{melding.nameMelding}</td>
            <td>{melding.object}</td>
            {melding.meldingAfgehandeld && <td className="Check-ok left">V</td>}
            {!melding.meldingAfgehandeld && <td className="Check-not-ok left">X</td>}
            <td>
              <Link to={{
                pathname: `${ROUTES.OBJECTS}/object/diefstalmelding/${melding.uid}`,
                state: { object: melding.object, meldingUid: melding.uid },
              }}
              >
                Details
              </Link>
            </td>
          </tr>
        ))
        }
        </tbody>
        </table>}
        </div>

      <div className="Non-active-objects">
        <h2>Openstaande schademeldingen</h2>
        {loading && <div>Loading...</div>}
        {alleSchadeMeldingen &&
        <table className="Tabel-full-width">
        <thead>
          <tr>
            <td><strong>Melding</strong></td>
            <td><strong>Object</strong></td>
            <td><strong>Melding afgehandeld</strong></td>
            <td><strong>Details</strong></td>
          </tr>
        </thead>
          <tbody>
        {Object.values(alleSchadeMeldingen).filter(melding => {
          return melding.meldingAfgehandeld === false
        }).map(melding => (
          <tr key={melding.uid}>
            <td>{melding.nameMelding}</td>
            <td>{melding.object}</td>
            {melding.meldingAfgehandeld && <td className="Check-ok left">V</td>}
            {!melding.meldingAfgehandeld && <td className="Check-not-ok left">X</td>}
            <td>
              <Link to={{
                pathname: `${ROUTES.OBJECTS}/object/schademelding/${melding.uid}`,
                state: { object: melding.object, meldingUid: melding.uid },
              }}
              >
                Details
              </Link>
            </td>
          </tr>
        ))
        }
        </tbody>
        </table>}
        </div>

        <div className="Non-active-objects">
          <h2>Openstaande serviceaanvragen</h2>
          {loading && <div>Loading...</div>}
          {alleServiceAanvragen &&
          <table className="Tabel-full-width">
          <thead>
            <tr>
              <td><strong>Melding</strong></td>
              <td><strong>Object</strong></td>
              <td><strong>Melding afgehandeld</strong></td>
              <td></td>
              <td><strong>Melding goedgekeurd</strong></td>
              <td><strong>Details</strong></td>
            </tr>
          </thead>
            <tbody>
          {Object.values(alleServiceAanvragen).filter(melding => {
            return melding.aanvraagAfgerond === false
          }).map(melding => (
            <tr key={melding.uid}>
              <td>{melding.nameMelding}</td>
              <td>{melding.object}</td>
              {melding.aanvraagAfgerond && <td className="Check-ok left">V</td>}
              {!melding.aanvraagAfgerond && <td className="Check-not-ok left">X</td>}
              <td className="Tabel-cel-smal"></td>
              {melding.aanvraagApproved === 'Goedgekeurd' && <td className="Check-ok left">V</td>}
              {melding.aanvraagApproved === '' && <td className="Check-not-ok-orange left">Nog beoordelen</td>}
              {melding.aanvraagApproved === 'Afgekeurd' && <td className="Check-not-ok left">X</td>}
              <td>
                <Link to={{
                  pathname: `${ROUTES.OBJECTS}/object/serviceaanvraag/${melding.uid}`,
                  state: { object: melding.object, aanvraagUid: melding.uid },
                }}
                >
                  Details
                </Link>
              </td>
            </tr>
          ))
          }
          </tbody>
          </table>}
          </div>

      </div>
  );
}
}

export default MeldingenBase;
