import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class ObjectStolenBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      uid: '',
      gegevensGewzijgd: false,
      object: '',
      ...props.location.state,
    };
  }

  objectIsStolen = event => {
    this.props.firebase
      .object(this.state.object.objectName)
      .update({ //verwijdert koppelingen naar werknemer, werkgever, dealer en zet status
        userUid: '',
        company: '',
        dealer: '',
        status: 'Gestolen',
        active: true,
    })
    .then(() => {
      if(this.state.uid) { // verwijdert alleen object indien er werknemer aan object gekoppeld zit. Bij poolobject wordt dit overgeslagen
        this.props.firebase
          .user(this.state.uid) //verwijdert object bij werknemer
          .update({
            object: '',
          })
      }
    })
    .then(() => {
      //update object activity
      const ref = this.props.firebase
        .object(this.state.object.objectName)

      const objectActivity = ref.child('activity')

      objectActivity.push({
        activity: `De fiets is gestolen.`,
        createdAt: new Date().toString(),
      })
    })
    .catch((error) => {
      console.log('Error', error)
    })
    .then(() => {
      this.props.history.push(ROUTES.OBJECTS);
    })

    this.setState({
      gegevensGewzijgd: true,
    })
  }

  render() {
    const { object, loading, gegevensGewzijgd } = this.state;

    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {object && (
          <div className="Werknemer-gegevens-edit">
            <div className="Einde-contract">
              <div className="Einde-contract-column">
                <h2>Fiets gestolen</h2>
                <p>Klik op onderstaande knop om de fiets als gestolen op te geven. De fiets wordt hiermee losgekoppeld van de werknemer, waarna een nieuwe fiets of poolfiets aan de werknemer gekoppeld kan worden.</p>
                <div>
                  <button onClick={this.objectIsStolen} className="Button-marge red">Fiets is gestolen</button>
                </div>
                  {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </div>
          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(ObjectStolenBase);
