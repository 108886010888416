import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import OnderhoudsFacturenBase from './OnderhoudsFacturenBase';
import SchademeldingenBase from './SchademeldingenBase';
import ServiceAanvraagBase from './ServiceAanvraagBase';
import DiefstalmeldingenBase from './DiefstalmeldingenBase';

import * as ROUTES from '../../constants/routes';

import './Objects.css';

class ObjectItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      user: null,
      dealer: '',
      dealerContactEmail:'',
      error: '',
      insuranceName: '',
      ...props.location.state,
    };
  }

  componentDidMount() {
    if(this.state.object && this.state.user) {
      return;
    }

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        uid: snapshot.val().userUid,
        loading: false,
      });
        this.getUser();
    })


    // if(!this.state.user) {
    // this.props.firebase
    //   .user(this.state.object.userUid)
    //   // .user(this.state.uid)
    //   .on('value', snapshot => {
    //     this.setState({
    //       user: snapshot.val(),
    //       loading: false,
    //     })
    //   });
    // }
}

  getUser() {
    this.props.firebase
      // .user(this.state.object.userUid)
      .user(this.state.uid)
      .on('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        })
      });
  }

  componentWillUnmount() {
    this.props.firebase.object(this.props.match.params.id).off();
    this.props.firebase.user(this.state.object.userUid).off();
  }

  informDealer = event => {
    //update object
    this.props.firebase
    .object(this.state.user.object)
    .update({
      readyForPickUp: true,
    })

    //update gebruiker
    this.props.firebase
    .user(this.state.uid)
    .update({
      phase: "Wacht op uitgifte fiets",
      readyForPickUp: true,
    })

    //juiste dealer gegevens laden voor versturen van de mail.
    this.props.firebase
    .dealer(this.state.user.dealer)
    .once('value', snapshot => {
      this.setState({
        dealer: snapshot.val(),
        dealerContactEmail: snapshot.val().dealerContactEmail
      })
    })
    .then(()=> {
      this.sendEmailToDealer(event);
    })
    .then(() => {
      this.sendEmailToWerknemer(event);
    })

    //update user activity
    const ref = this.props.firebase
    .user(this.state.uid)

    const userActivity = ref.child('activity')

    userActivity.push({
      activity: 'Mail verstuurd naar dealer en werknemer over ophalen fiets -> Naar fase Wacht op uitgifte fiets',
      createdAt: new Date().toString(),
    })

    // event.preventDefault();
  }

  sendEmailToDealer(event) {
  //Verstuur mail naar dealer
    const templateId = 'fiets_klaar_voor_ophalen_naar_dealer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.dealerContactEmail,
      dealerContact: this.state.dealer.dealerContact,
      werknemerNaam: this.state.user.username,
      werknemerMail: this.state.user.email,
      werknemerPhone: this.state.user.phoneNumber,
      werknemerSqootId: this.state.uid.substring(0, 5),
      objectBrand: this.state.object.objectBrand,
      objectModel: this.state.object.objectModel,
    }

    this.sendFeedbackDealer(templateId, template_params, userId)
  }

  sendFeedbackDealer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  sendEmailToWerknemer(event) {
  //Verstuur mail naar dealer
    const templateId = 'fiets_klaar_voor_ophalen_naar_werknemer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      dealerContact: this.state.dealer.dealerContact,
      dealerName: this.state.dealer.dealerName,
      pechpasNumber: this.state.object.pechhulp,
      werknemerNaam: this.state.user.username,
      werknemerMail: this.state.user.email,
    }

    this.sendFeedbackWerknemer(templateId, template_params, userId)
  }

  sendFeedbackWerknemer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const { object, user, uid, loading } = this.state;

    return (
      <div className ="Object">
          <div className="Terug-naar-overzicht">
            <Link to={{
                pathname: `${ROUTES.OBJECTS}`,
                // state: { user },
              }}
              >
                Terug naar objecten
            </Link>
          </div>
          <div>
            {object && <div className="Wijzigen">
              <Link to={{
                  pathname: `${ROUTES.OBJECTS}/object/edit/${object.objectName}`,
                  // state: { object },
                }}
                >
                  Wijzig object
              </Link>
            </div>}
            {object && object.status !== 'Gestolen' &&  <div className="Fiets-gestolen">
              <Link to={{
                pathname: `${ROUTES.OBJECTS}/object/gestolen`,
                state: {object, uid, user }
                }}
                >
                  Fiets gestolen
              </Link>
            </div>}
          </div>
        {object && !object.readyForPickUp && object.insuranceReady && object.pechhulpReady &&
          <div className="Dealer-informeer-balk">
          <p className="Dealer-informeer-tekst">De fiets is gereed voor ophalen. Informeer de dealer en werknemer.</p>
          <button className="Dealer-informeer-knop" onClick={this.informDealer}>Meld object gereed voor ophalen</button>
          </div>
        }
        {/*{user && object && (*/}
        <div className="Object">
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}

            {object && (
              <div className="Object-detail-tabel">
              <table className="Tabel-gegevens">
                <tbody>
                  {user && !object.poolObject && <tr>
                    <td><strong>Gebruiker</strong></td>
                    <td><Link to={{
                    pathname: `${ROUTES.ADMIN}/user/${uid}`,
                    state: { object, user },
                    }}
                    >
                      {user.username}
                    </Link></td>

                  </tr>}
                  {object.poolObject && <tr>
                    <td><strong>Gebruiker</strong></td>
                    <td>Poolobject</td>

                  </tr>}
                  <tr>
                    <td><strong>Werkgever</strong></td>
                    {user && !object.poolObject &&  <td><Link to={{
                    pathname: `${ROUTES.COMPANIES}/company/${user.company}`,
                    state: { object },
                    }}
                    >
                      {user.company}
                    </Link></td>}
                    {object.poolObject && <td><Link to={{
                    pathname: `${ROUTES.COMPANIES}/company/${object.company}`,
                    state: { object },
                    }}
                    >
                      {object.company}
                    </Link></td>}
                  </tr>
                  <tr>
                    <td><strong>Dealer</strong></td>
                    {user && !object.poolObject && <td><Link to={{
                    pathname: `${ROUTES.DEALERS}/dealer/${user.dealer}`,
                    state: { object },
                    }}
                    >
                      {user.dealer}
                    </Link></td>}
                    {object.poolObject && <td><Link to={{
                    pathname: `${ROUTES.DEALERS}/dealer/${object.dealer}`,
                    state: { object },
                    }}
                    >
                      {object.dealer}
                    </Link></td>}
                  </tr>
                  <tr>
                    <td><strong>Polisnummer</strong></td>
                    {object.insurance &&
                      <td>{object.insurance}</td>}
                    {!object.insurance && !object.insuranceInAanvraag && <td className="Object-insurance-link"> <Link to={{
                      pathname: `${ROUTES.INSURANCE_CREATE}`,
                      state: { object: object },
                    }}
                    >
                      Verzekering aanmaken
                    </Link>
                    </td>}
                    {!object.insurance && object.insuranceInAanvraag && <td className="Object-insurance-link-orange"> <Link to={{
                      pathname: `${ROUTES.INSURANCE_CREATE}`,
                      state: { object: object },
                    }}
                    >
                      Verzekering aangevraagd
                    </Link>
                    </td>}
                  </tr>
                  <tr>
                    <td><strong>Pechpasnummer</strong></td>
                    {object.pechhulp && <td>{object.pechhulp}</td>}
                    {!object.pechhulp && !object.pechhulpInAanvraag && <td className="Object-insurance-link"> <Link to={{
                      pathname: `${ROUTES.PECHHULP_CREATE}`,
                      state: { object: object },
                    }}
                    >
                      Pechhulp aanmaken
                    </Link>
                    </td>}
                    {!object.pechhulp && object.pechhulpInAanvraag && <td className="Object-insurance-link-orange"> <Link to={{
                      pathname: `${ROUTES.PECHHULP_CREATE}`,
                      state: { object: object },
                    }}
                    >
                      Pechhulp aangevraagd
                    </Link>
                    </td>}
                    </tr>
                    {object.accessoires && <tr>
                        <td><strong>Accessoires</strong></td><td><ul className="Accessoires-lijst">{object.accessoires.map(accessoire => {return <li key={accessoire}>{accessoire}</li>})}</ul></td>
                      </tr>}
                    <tr>
                      <td><strong>Kenteken</strong></td><td>{object.objectLicensePlate}</td>
                    </tr>
                  <tr>
                    <td><strong>Meldcode</strong></td><td>{object.objectMeldCode}</td>
                  </tr>
                  {object.readyForPickUp && <tr>
                    <td><strong>Gereed</strong>
                    </td>
                    <td>
                      Dealer is geïnformeerd
                    </td>
                  </tr>}
                  <tr>
                    <td><strong>Start contract</strong></td>{user && user.startDateContract && <td>{user.startDateContract.slice(4, 15)}</td>}
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td><td>{object.status}</td>
                  </tr>
                  <tr>
                    <td><strong>Leasecontract:</strong></td><td>{object.downloadLeaseContractUrl &&  <a href={object.downloadLeaseContractUrl} target="_blank" rel="noopener noreferrer">Download leasecontract</a>
                    }</td>
                  </tr>
                </tbody>
              </table>

              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>Objectnummer</strong></td><td>{object.objectName}</td>
                  </tr>
                  <tr>
                    <td><strong>Soort fiets</strong></td><td>{object.objectType}</td>
                  </tr>
                  <tr>
                    <td><strong>Merk</strong></td><td>{object.objectBrand}</td>
                  </tr>
                  <tr>
                    <td><strong>Type</strong></td><td>{object.objectModel}</td>
                  </tr>
                  <tr>
                    <td><strong>Motor positie</strong></td><td>{object.engineType}</td>
                  </tr>
                  <tr>
                    <td><strong>Versnelling</strong></td><td>{object.gearType}</td>
                  </tr>
                  <tr>
                    <td><strong>Aantal versnellingen</strong></td><td>{object.gearNumber}</td>
                  </tr>
                  <tr>
                    <td><strong>Framemaat</strong></td><td>{object.objectFramesize}</td>
                  </tr>
                  <tr>
                    <td><strong>Kleur</strong></td><td>{object.objectColor}</td>
                  </tr>
                  <tr>
                    <td><strong>Dames/heren</strong></td><td>{object.objectGender}</td>
                  </tr>
                  <tr>
                    <td><strong>Framenummer</strong></td><td>{object.objectFrameNumber}</td>
                  </tr>

                </tbody>
              </table>
              <table className="Tabel-gegevens">
                <tbody>
                <tr>
                  <td><strong>Slotsoort</strong></td><td>{object.objectLock}</td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer slot</strong></td><td>{object.objectLockNumber}</td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer accu</strong></td><td>{object.objectBatteryKeyNumber}</td>
                </tr>
                <tr>
                  <td><strong>Accunummer</strong></td><td>{object.objectBatteryNumber}</td>
                </tr>
                <tr>
                  <td><strong>Soort accu</strong></td><td>{object.objectBatteryType}</td>
                </tr>
                <tr>
                  <td><strong>Bruto aanschafprijs</strong></td><td>€{object.objectPrice}</td>
                </tr>
                <tr>
                  <td><strong>Bijzonderheden</strong></td><td>{object.objectBijzonderheden}</td>
                </tr>
                </tbody>
              </table>
            </div>
          )}
          </div>

        </div>
        {object &&
          <div className="Object-meldingen">
            <div className="Onderhoudsfacturen">
              <h2>Onderhoudsfacturen</h2>
              <OnderhoudsFacturen object={object.objectName}/>
            </div>
            <div className="Schademeldingen">
              <h2>Schademeldingen</h2>
              <Schademeldingen object={object.objectName}/>
            </div>
            <div className="Servicemeldingen">
              <h2>Serviceaanvragen</h2>
              <ServiceAanvraag object={object.objectName}/>
            </div>
            <div className="Servicemeldingen">
              <h2>Diefstalmeldingen</h2>
              <Diefstalmeldingen object={object.objectName}/>
            </div>
            <div className="Werknemer-activiteiten">
            <h2>Activiteiten</h2>
            {object.activity &&
              <ul className="Activiteiten-lijst">
                {Object.values(object.activity)
                  .sort()
                  .map(active => (
                  <li key={active.createdAt} className="Activiteit">
                    <div className="Created-at">{active.createdAt.slice(0,25)}</div>
                    <div>{active.activity}</div>
                  </li>
                ))}
              </ul>
            }
            </div>
          </div>
        }

    </div>
    )
  }
}

const OnderhoudsFacturen = withFirebase(OnderhoudsFacturenBase);
const Schademeldingen = withFirebase(SchademeldingenBase);
const ServiceAanvraag = withFirebase(ServiceAanvraagBase);
const Diefstalmeldingen = withFirebase(DiefstalmeldingenBase);

export default ObjectItemBase;
