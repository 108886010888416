import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';

class WerkgeverDeelnemersBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      company:'',
      paginationPageSize: 15,
      columnDefs: [{
        headerName: "Naam", field: "username", width: 250,
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.WERKGEVER}/werknemer/${params.data.uid}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      }, {
        headerName: "Object", field: "object",
        cellRendererFramework: (params) => {
          return <Link to={{
              pathname: `${ROUTES.WERKGEVER}/object/${params.value}`,
              state: { user: params.data },
            }}
            >
              {params.value}
            </Link>
      }
      }, {
        headerName: "Leasecontract", field: "downloadUrlLeaseContract", width:180,
        cellRendererFramework: (params) => {
          if(params.value) {
            return <a href={params.value} target="_blank" rel="noopener noreferrer">Download</a>
          } else {
            return null;
          }
      }
      }, {
        headerName: "Start contract", field: "startDateContract", width:180,
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }
      }, {
        headerName: "Eind contract", field: "endDateContract", width:180,
        cellRendererFramework: (params) => {
          if(params.value) {
            return params.value.slice(4,16)
          } else {
            return null;
          }
      }
      }],
      defaultColDef: {
        width: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        // floatingFilter: true,
        resizable: true,
    },
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase
    .users()
    .on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

render() {
  const { users, loading } = this.state;
  // console.log('USERSLIST', users)

  return (
    <AuthUserContext.Consumer>

      {authUser => (
      <div className="Overzicht-werknemers">
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.WERKGEVER}`,
          }}
          >
            Terug naar overzicht
        </Link>
      </div>
        <div className="Goedgekeurde-werknemers">
          <h2>Deelnemers fietsplan</h2>
          {loading && <div>Loading...</div>}

          <div
            className="ag-theme-alpine"
            style={{
            height: '350px',
            }}
          >
            <AgGridReact
              reactNext={true} //
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              pagination={true}
              paginationPageSize={this.state.paginationPageSize}
              paginationAutoPageSize={true}
              rowData={users
                .filter(user => {
                  return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                })
                .filter(user => {
                  return user.company === authUser.companyName
                })
                .filter(user => {
                  return user.isAkkoord === true
                })}>
            </AgGridReact>
          </div>

          {/*<table className="Tabel-full-width">
            <tbody>
              <tr>
                <th>Naam</th><th>Objectnummer</th><th>Leasecontract</th><th>Begin contract</th><th>Einde contract</th>
              </tr>
              {users
                .sort((a, b) => {
                  if (a.username < b.username) {
                    return -1;
                  }
                  if (a.username > b.username) {
                    return 1;
                  }
                  return 0;
                })
                .filter(user => {
                  return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                })
                .filter(user => {
                  return user.company === authUser.companyName
                })
                .filter(user => {
                  return user.isAkkoord === true
                })
                .map(user => (
                  <tr key={user.uid}>
                  <td><Link to={{
                        pathname: `${ROUTES.WERKGEVER}/werknemer/${user.uid}`,
                        state: { user },
                      }}
                      >
                        {user.username}
                    </Link></td>
                  <td><Link to={{
                        pathname: `${ROUTES.WERKGEVER}/object/${user.object}`,
                        state: { user },
                      }}
                      >
                        {user.object}
                    </Link></td>
                    <td>{user.downloadUrlLeaseContract &&  <a href={user.downloadUrlLeaseContract} target="_blank" rel="noopener noreferrer">Download leasecontract</a>}</td>
                  {user.startDateContract && <td>{user.startDateContract.slice(4,15)}</td>}
                  {user.endDateContract && <td>{user.endDateContract.slice(4,15)}</td>}
                  </tr>
              ))}
            </tbody>
          </table>*/}
        </div>
    </div>
  )}
</AuthUserContext.Consumer>
  )}
}

export default WerkgeverDeelnemersBase;
