import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import * as ROUTES from '../../constants/routes';

import './Objects.css';

class CreateObjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: '',
      user: '',
      company: '',
      userUid: '',
      active: false,
      downloadLeaseContractUrl: '',
      objectName: '',
      objectBijzonderheden: '',
      objectType: '',
      objectBrand: '',
      objectModel: '',
      objectPrice: '',
      objectFramesize: '',
      objectColor:'',
      objectGender: '',
      objectFrameNumber: '',
      objectLock: '',
      objectLockNumber: '',
      objectBatteryNumber: '',
      objectBatteryKeyNumber: '',
      objectBatteryType: '',
      objectLicensePlate:'',
      objectMeldCode: '',
      engineType: '',
      gearType: '',
      gearNumber: '',
      insurance: '',
      pechhulp: '',
      accessoires: '',
      leaseContractObject: '',
      status: 'Nog niet opgehaald bij dealer',
      loading: false,
      error: '',
      value: '',
      ...props.location.state,
    }
  }

componentDidMount() {
  this.setState({
    loading:true
  });

  //Aantal objecten binnenhalen voor juiste nummering van objectName
  this.props.firebase
  .objects()
  .on('value', snapshot => {
    const objectsObject = snapshot.val();

    const numberOfObjects = Object.keys(objectsObject).length;
    console.log('numberOfObjects', numberOfObjects);

    this.setState({
      objectName: `Object-${numberOfObjects + 244}`
    })
  })

  // this.props.firebase.objects().on('value', snapshot => {
  //   const objectsObject = snapshot.val();
  //
  //   const numberOfObjects = Object.keys(objectsObject).length;
  //   console.log('numberOfObjects', numberOfObjects);
  // })

  // this.props.firebase.users().on('value', snapshot => {
  //   const usersObject = snapshot.val();
  //
  //   const usersList = Object.keys(usersObject).map(key => ({
  //     ...usersObject[key],
  //     uid: key,
  //   }));
  //
  //   this.setState({
  //     users: usersList,
  //     loading: false,
  //     // company: this.props.company,
  //   });
  // });
}

componentWillUnmount() {
  this.props.firebase.objects().off();
}

onUpload = event => {
  this.setState({
    leaseContractObject: event.target.files[0],
  })
}

onSubmit = event => {
  const { objectName,
          objectType,
          objectBrand,
          objectBijzonderheden,
          objectModel,
          objectPrice,
          objectFramesize,
          objectColor,
          objectGender,
          objectFrameNumber,
          objectLock,
          objectLockNumber,
          objectBatteryNumber,
          objectBatteryKeyNumber,
          objectBatteryType,
          objectLicensePlate,
          objectMeldCode,
          engineType,
          gearType,
          gearNumber,
          accessoires,
          insurance,
          pechhulp,
          active,
          company,
          status,
          leaseContractObject,
          downloadLeaseContractUrl,
          userUid } = this.state;

  //Koppel objectnaam aan gebruiker
  this.props.firebase
  .user(userUid)
  .update({
    object: objectName
  })
  .then(res => console.log('object succesvol aan werknemer toegevoegd', res))
  .catch(err => console.error('Error', err))

  //update user activity
  const ref = this.props.firebase
    .user(userUid)

  const userActivity = ref.child('activity')

  userActivity.push({
    activity: 'Object is aangemaakt door backoffice',
    createdAt: new Date().toString(),
  })

  return this.props.firebase
  .object(objectName)
  .set({
    objectName,
    objectType,
    objectBrand,
    objectBijzonderheden,
    objectModel,
    objectPrice,
    objectFramesize,
    objectColor,
    objectGender,
    objectFrameNumber,
    objectLock,
    objectLockNumber,
    objectBatteryNumber,
    objectBatteryKeyNumber,
    objectBatteryType,
    objectLicensePlate,
    objectMeldCode,
    engineType,
    gearType,
    gearNumber,
    accessoires,
    insurance,
    pechhulp,
    active,
    leaseContractObject,
    downloadLeaseContractUrl,
    company,
    status,
    userUid })
  .then(() => {
    //upload leasecontract naar object toe indien een bestand is geselecteerd
    if(this.state.leaseContractObject) {
      this.uploadLeaseContract()
    }
  })
  .then(() => {
    //update object activity
    const ref = this.props.firebase
      .object(objectName)

    const objectActivity = ref.child('activity')

    objectActivity.push({
      activity: `Object is aangemaakt en gekoppeld aan ${this.state.user.username} van ${this.state.user.company}`,
      createdAt: new Date().toString(),
    })
  })
  .then(this.setState({
    // users: '',
    user: '',
    // uid: '',
    userUid: '',
    active: false,
    objectName: '',
    objectType: '',
    objectBrand: '',
    objectBijzonderheden: '',
    objectModel: '',
    objectPrice: '',
    objectFramesize: '',
    objectColor: '',
    objectGender: '',
    objectFrameNumber: '',
    objectLock: '',
    objectLockNumber: '',
    objectBatteryNumber: '',
    objectBatteryKeyNumber: '',
    objectBatteryType: '',
    objectLicensePlate: '',
    objectMeldCode: '',
    engineType: '',
    gearType: '',
    gearNumber: '',
    accessoires: '',
    leaseContractObject: '',
    downloadLeaseContractUrl: '',
    company: '',
    status: '',
    loading: false,
    error: '',
    value: '',
  },
  this.props.history.push(ROUTES.OBJECTS)))
  .catch(error => {
    this.setState({error});
  })

  // event.preventDefault();
};

uploadLeaseContract = event => {

  const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

  const uploadTask = this.props.firebase.leaseContractObject(this.state.leaseContractObject, this.state.objectName, date);
  uploadTask.on('state_changed', snapshot => {

  }, error => {
      this.setState({
        error: true
      })

  }, () => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

      console.log('DownloadURL in uploadLeaseContract', downloadURL)

      this.props.firebase
      .object(this.state.objectName)
      .update({
        downloadLeaseContractUrl: downloadURL,
      })
    })
})
}

onChange = event => {
  this.setState({ [event.target.name]: event.target.value })
}

handleAccessoiresChange = event => {

  const options = event.target.options;
  const value = [];
    for(let i = 0, l = options.length; i < l; i++) {
      if(options[i].selected) {
        value.push(options[i].value);
      }
    }
  this.setState({accessoires: value})
}

onObjectSelectChange = event => {
  this.setState({ [event.target.name]: event.target.value })
}

  render() {
    const {
      user,
      // users,
      value,
      // userUid,
      // uid,
      // company,
      objectName,
      objectType,
      objectBrand,
      objectModel,
      objectPrice,
      objectBijzonderheden,
      objectFramesize,
      objectColor,
      objectGender,
      objectFrameNumber,
      objectLockNumber,
      objectLock,
      objectBatteryNumber,
      objectBatteryKeyNumber,
      objectBatteryType,
      objectLicensePlate,
      objectMeldCode,
      engineType,
      gearType,
      gearNumber,
      accessoires,
      // leaseContractObject,
      // downloadLeaseContractUrl,
      error
    } = this.state;

    const isInvalid = user === '';
    // console.log("user", user)
    // console.log("leaseContractObject", leaseContractObject)
    // console.log('downloadLeaseContractUrl', downloadLeaseContractUrl)
    // console.log('company', company)
    // // console.log("users", users)
    // console.log("userUid", userUid)
    // // console.log("uid", uid)
    // console.log("ObjectType", objectType)
    // console.log("objectType", objectType)
    // console.log("engineType", engineType)
    // console.log('gearType', gearType)

    return (
      <>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
      <div className="Objects">
        <form onSubmit={this.onSubmit} className="CreateObjectForm">
        <h2>Maak een nieuw object aan</h2>
          <label>Naam object</label>
          <input
          name = 'objectName'
          value = {objectName}
          onChange = {this.onChange}
          type = 'text'
          disabled
          />
          <label>Merk fiets</label>
            <input
            name = 'objectBrand'
            value = {objectBrand}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Type</label>
            <input
            name = 'objectModel'
            value = {objectModel}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Soort fiets</label>
          <div>
            <select
            name = 'objectType'
            value = {objectType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Selecteer object type</option>
              <option value='Stadsfiets' key="Stadsfiets">Stadsfiets</option>
              <option value='Elektrische stadsfiets' key="Elektrische stadsfiets">Elektrische stadsfiets</option>
              <option value='Speedpedelec' key="Speedpedelec">Speedpedelec</option>
              <option value='Mountainbike' key="Mountainbike">Mountainbike</option>
              <option value='Hybride fiets' key="Hybride fiets">Hybride fiets</option>
              <option value='Elektrische hybride fiets' key="Elektrische hybride fiets">Elektrische hybride fiets</option>
              <option value='Bakfiets' key="Bakfiets">Bakfiets</option>
              <option value='Elektrische bakfiets' key="Elektrische bakfiets">Elektrische bakfiets</option>
              <option value='Racefiets' key="Racefiets">Racefiets</option>
              <option value='Elektrische racefiets' key="Elektrische racefiets">Elektrische racefiets</option>
              <option value='Vouwfiets' key="Vouwfiets">Vouwfiets</option>
              <option value='Elektrische vouwfiets' key="Elektrische vouwfiets">Elektrische vouwfiets</option>
              <option value='Scooter' key="Scooter">Scooter</option>
            </select>
          </div>
          <label>Motor positie</label>
          <div>
            <select
            name = 'engineType'
            value = {engineType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Selecteer motor positie</option>
              <option value='Voor' key="Voor">Voor</option>
              <option value='Midden' key="Midden">Midden</option>
              <option value='Achter' key="Achter">Achter</option>
            </select>
          </div>
          <label>Type versnelling</label>
          <div>
            <select
            name = 'gearType'
            value = {gearType}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Type versnelling</option>
              <option value='Derailleur' key="Derailleur">Derailleur</option>
              <option value='Naaf' key="Naaf">Naaf</option>
            </select>
          </div>
          <label>Aantal versnellingen</label>
          <div>
            <select
            name = 'gearNumber'
            value = {gearNumber}
            onChange = {this.onObjectSelectChange}
            >
              <option value={value} disabled>Aantal versnellingen</option>
              <option value='Geen versnelling' key="Geen versnelling">Geen versnelling</option>
              <option value='Automatisch' key="Automatisch">Automatisch</option>
              <option value='3' key="3">3</option>
              <option value='5' key="5">5</option>
              <option value='7' key="7">7</option>
              <option value='8' key="8">8</option>
            </select>
          </div>
          <label>Framemaat</label>
            <input
            name = 'objectFramesize'
            value = {objectFramesize}
            onChange = {this.onChange}
            type = 'text'
            />
            <label>Kleur</label>
              <input
              name = 'objectColor'
              value = {objectColor}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Dames/heren</label>
              <input
              name = 'objectGender'
              value = {objectGender}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Framenummer</label>
              <input
              name = 'objectFrameNumber'
              value = {objectFrameNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Slotsoort</label>
              <input
              name = 'objectLock'
              value = {objectLock}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Sleutelnummer slot</label>
              <input
              name = 'objectLockNumber'
              value = {objectLockNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Sleutelnummer accu</label>
              <input
              name = 'objectBatteryKeyNumber'
              value = {objectBatteryKeyNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Accunummer</label>
              <input
              name = 'objectBatteryNumber'
              value = {objectBatteryNumber}
              onChange = {this.onChange}
              type = 'text'
              />
            <label>Soort accu</label>
              <input
              name = 'objectBatteryType'
              value = {objectBatteryType}
              onChange = {this.onChange}
              type = 'text'
              />
          <label>Accessoires</label>
          <span className="Toelichting">Houd ctrl ingedrukt als je meerdere accessoires wilt selecteren.</span>
          <div>
            <select
              multiple={true}
              value={accessoires}
              onChange={this.handleAccessoiresChange}
            >
              <option value='Slot' key='Slot'>Slot</option>
              <option value='Kinderzitje' key='Kinderzitje'>Kinderzitje</option>
              <option value='Helm' key='Helm'>Helm</option>
            </select>
          </div>
          <label>Bruto aanschafprijs</label>
            <input
            name = 'objectPrice'
            value = {objectPrice}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Kenteken</label>
            <input
            name = 'objectLicensePlate'
            value = {objectLicensePlate}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Meldcode</label>
            <input
            name = 'objectMeldCode'
            value = {objectMeldCode}
            onChange = {this.onChange}
            type = 'text'
            />
          <label>Upload leasecontract: </label>
            <input
              name = 'file'
              type = 'file'
              onChange = {this.onUpload}
              />
          <label className="Upload-schadeformulier">Bijzonderheden</label>
            <textarea
              onChange={this.onChange}
              name= 'objectBijzonderheden'
              value= {objectBijzonderheden}
              type = 'text'
              />
          <div>
            <button disabled={isInvalid} type='submit'>Maak object aan</button>
          </div>
          {error && <p>{error.message}</p>}

        </form>
      </div>
      </>
    )
  }
}

export default withRouter(CreateObjectBase);
