import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import './Objects.css';

class ServiceAanvraagBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: '',
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.object)
    .on('value', snapshot => {


    const aanvragenObject = snapshot.val().serviceaanvragen;

    if(aanvragenObject) {
      const aanvragenList = Object.keys(aanvragenObject).map(key => ({
        ...aanvragenObject[key],
        uid: key,
      })
    );

      this.setState({
        object: snapshot.val(),
        loading:false,
        aanvragen: aanvragenList
      })
    } else {
      this.setState({
        loading: false
      })
    }
  })
}


  render() {
    const { loading, object, aanvragen } = this.state;

    return (
      <AuthUserContext.Consumer>
      {authUser => (
      <div className="Service-aanvraag-formulier-blok">
            {loading && <div>Loading...</div>}
            {object.serviceaanvragen && (
              <table className="Tabel-full-width">
                <thead>
                  <tr>
                    <th>Aanvraag</th><th>Goedgekeurd</th><th></th><th>Afgehandeld</th>
                  </tr>
                </thead>
              <tbody>
              {Object.values(aanvragen)
                .map(aanvraag => (
                <tr key={aanvraag.uid} className="Activiteit">
                  {!!authUser.roles[ROLES.ADMIN] && <td><Link to={{
                  pathname: `${ROUTES.OBJECTS}/object/serviceaanvraag/${aanvraag.uid}`,
                  state: { aanvraagUid: aanvraag.uid, object: object.objectName, uid:object.userUid },
                  }}
                  >
                    {aanvraag.nameMelding.slice(7,29)}
                  </Link></td>}
                  {!!authUser.roles[ROLES.DEALER] && <td><Link to={{
                  pathname: `${ROUTES.DEALER}/meldingen/serviceaanvraag/${aanvraag.uid}`,
                  state: { aanvraagUid: aanvraag.uid, object: object.objectName, uid:object.userUid },
                  }}
                  >
                    {aanvraag.nameMelding.slice(7,29)}
                  </Link></td>}
                  {aanvraag.aanvraagApproved === 'Goedgekeurd' && <td className="Check-ok">V</td>}
                  {aanvraag.aanvraagApproved === '' && <td className="Check-not-ok-orange left">In behandeling</td>}
                  {aanvraag.aanvraagApproved === 'Afgekeurd' && <td className="Check-not-ok">X</td>}
                  <td className="Tabel-cel-smal"></td>
                  {aanvraag.aanvraagAfgerond && <td className="Check-ok">V</td>}
                  {!aanvraag.aanvraagAfgerond && <td className="Check-not-ok">X</td>}
                </tr>
              ))}
              </tbody>
              </table>
            )}
            {!object.serviceaanvragen && <p>Er zijn nog geen serviceaanvragen voor dit object.</p>}
      </div>
    )}
    </AuthUserContext.Consumer>
    )
  }
}

export default withRouter(ServiceAanvraagBase);
