import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';

import './Objects.css';

class SchademeldingDetailBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      invoiceList: '',
      object: '',
      meldingUid: '',
      schadeFormulierOntvangen: false,
      schadeBedragOntvangen: false,
      schadeFormulierVerstuurdEnra: false,
      akkoordEnra: false,
      akkoordGevenHerstel: false,
      eigenRisicoGebruiker: false,
      meldingAfgehandeld: false,
      stappenUpdate: false,
      descriptionBO: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .schademelding(this.state.object, this.state.meldingUid)
    .on('value', snapshot => {
      this.setState({
        melding: snapshot.val(),
        schadeFormulierOntvangen: snapshot.val().schadeFormulierOntvangen,
        schadeBedragOntvangen: snapshot.val().schadeBedragOntvangen,
        schadeFormulierVerstuurdEnra: snapshot.val().schadeFormulierVerstuurdEnra,
        akkoordEnra: snapshot.val().akkoordEnra,
        akkoordGevenHerstel: snapshot.val().akkoordGevenHerstel,
        eigenRisicoGebruiker: snapshot.val().eigenRisicoGebruiker,
        meldingAfgehandeld: snapshot.val().meldingAfgehandeld,
        descriptionBO: snapshot.val().descriptionBO,
        loading:false,
      })
    })
  }

  handleCheckboxChange = event => {
    const target = event.target;
    const value = target.name ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onSubmit = event => {
    const { schadeFormulierOntvangen, schadeBedragOntvangen, meldingUid, schadeFormulierVerstuurdEnra, akkoordEnra, akkoordGevenHerstel, eigenRisicoGebruiker, meldingAfgehandeld, descriptionBO, object } = this.state;

    this.props.firebase
    .schademelding(object, meldingUid)
    .update({
      schadeFormulierOntvangen: schadeFormulierOntvangen,
      schadeBedragOntvangen: schadeBedragOntvangen,
      schadeFormulierVerstuurdEnra: schadeFormulierVerstuurdEnra,
      akkoordEnra: akkoordEnra,
      akkoordGevenHerstel: akkoordGevenHerstel,
      eigenRisicoGebruiker: eigenRisicoGebruiker,
      meldingAfgehandeld: meldingAfgehandeld,
      descriptionBO: descriptionBO,
    })
    .then(() => {
      this.setState({
        stappenUpdate: true
      })
    })
    .then(res => console.log('Stappenplan succesvol bijgewerkt', res))
    .catch(err => console.error('Error', err))

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { invoiceList, loading, object, meldingUid, melding, schadeFormulierOntvangen, schadeBedragOntvangen, schadeFormulierVerstuurdEnra, akkoordEnra, akkoordGevenHerstel, eigenRisicoGebruiker, meldingAfgehandeld, descriptionBO, stappenUpdate } = this.state;
    console.log('invoiceList', invoiceList)
    console.log('meldingUid', meldingUid)
    console.log('melding', melding)
    console.log('object', object)

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug naar object</button>
        <div className="Schademelding-detail">
          <h2>Schademelding</h2>
          {loading && <div>Loading...</div>}
          <div className="Schademelding-detail-tabel">
          {melding &&
          <table className="Tabel-gegevens">
            <tbody>
              <tr>
                <td><strong>Datum melding</strong></td>
                <td>{melding.dateMelding.substring(0,24)}</td>
              </tr>
              <tr>
                <td><strong>Datum schade</strong></td>
                <td>{melding.dateSchade.substring(0,24)}</td>
              </tr>
              <tr>
                <td><strong>Beschrijving</strong></td>
                <td>{melding.description}</td>
              </tr>
              <tr>
                <td><strong>Hoe is de schade ontstaan?</strong></td>
                <td>{melding.descriptionOntstaanSchade}</td>
              </tr>
              <tr>
                <td><strong>Veroorzaakt door derden?</strong></td>
                <td>{melding.derden}</td>
              </tr>
              <tr>
                <td colSpan="2">
                <h3 className="H3-tabel">Gegevens tegenpartij</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Naam</strong></td>
                <td>{melding.naamTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>E-mailadres</strong></td>
                <td>{melding.mailTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>Telefoonnummer</strong></td>
                <td>{melding.phoneTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>Adres</strong></td>
                <td>{melding.addressTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>Postcode</strong></td>
                <td>{melding.zipCodeTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>Plaats</strong></td>
                <td>{melding.placeTegenPartij}</td>
              </tr>
              <tr>
                <td colSpan="2">
                <h3 className="H3-tabel">Betrokken bij ongeval</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Betrokken bij ongeval?</strong></td>
                <td>{melding.incidentTegenPartij}</td>
              </tr>
              <tr>
                <td><strong>Schadeformulier</strong></td>
                {melding.downloadUrlSchademelding && <td><a href={melding.downloadUrlSchademelding} target="_blank" rel="noopener noreferrer">Download schadeformulier</a></td>}
              </tr>
            </tbody>
          </table>
        }
          {melding &&
            <div>
              <form className="Stappen" onSubmit={this.onSubmit}>
              <label>
                <input
                  type="checkbox"
                  name="schadeFormulierOntvangen"
                  checked={schadeFormulierOntvangen}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Schadeformulier/schadeomschrijving ontvangen van gebruiker
              </label>
              <label>
                <input
                  type="checkbox"
                  name="schadeBedragOntvangen"
                  checked={schadeBedragOntvangen}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Schadebedrag ontvangen van dealer
              </label>
              <label>
                <input
                  type="checkbox"
                  name="schadeFormulierVerstuurdEnra"
                  checked={schadeFormulierVerstuurdEnra}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Schadeformulier/schadeomschrijving + schadebedrag verstuurd naar Enra
              </label>
              <label>
                <input
                  type="checkbox"
                  name="akkoordEnra"
                  checked={akkoordEnra}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Akkoord ontvangen vanuit Enra voor herstelling schade
              </label>
              <label>
                <input
                  type="checkbox"
                  name="akkoordGevenHerstel"
                  checked={akkoordGevenHerstel}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Dealer en gebruiker akkoord gegeven voor herstelling schade
              </label>
              <label>
                <input
                  type="checkbox"
                  name="eigenRisicoGebruiker"
                  checked={eigenRisicoGebruiker}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Eventueel eigen risico in rekening gebracht bij gebruiker
              </label>

              <label>
                <input
                  type="checkbox"
                  name="meldingAfgehandeld"
                  checked={meldingAfgehandeld}
                  onChange={this.handleCheckboxChange}
                  className="form-check-input"
                />
                Melding afgehandeld
              </label>

              <div className="Knop-met-marge">
              <label>Opmerkingen</label>
                <textarea
                  onChange={this.onChange}
                  name= 'descriptionBO'
                  value= {descriptionBO}
                  type = 'text'
                  placeholder = {descriptionBO}
                  />
              </div>
              {stappenUpdate && <p>Succesvol aangepast.</p>}

              <button className="Knop-met-marge" type='submit'>Opslaan</button>

              </form>
            </div>
          }
      </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SchademeldingDetailBase);
