import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
};

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }

  // *** AUTH API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
  this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
  this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification({
    url: 'https://portal.sqoot.nl/',
  });

  // *** Merge Auth and DB User API ***

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            //default empty roles
            if(!dbUser.roles) {
              dbUser.roles = {};
            }

            //merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

    // *** USER API ***

  authUser = uid => this.auth.ref(`users/${uid}`);


  // *** USER API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** COMPANIES API ***

  company = uid => this.db.ref(`companies/${uid}`);

  companyObject = (uid, objectUid) => this.db.ref(`companies/${uid}/objecten/${objectUid}`)

  companyObjects = uid => this.db.ref(`companies/${uid}/objecten`)

  companies = () => this.db.ref('companies');

    // *** COMPANIES API ***

  dealer = uid => this.db.ref(`dealers/${uid}`);

  dealers = () => this.db.ref('dealers');

  // *** OBJECTS API ***

  object = uid => this.db.ref(`objects/${uid}`);

  objects = () => this.db.ref('objects');

  schademelding = (uid, meldingUid) => this.db.ref(`objects/${uid}/schademeldingen/${meldingUid}`)

  schademeldingen = (uid) => this.db.ref(`objects/${uid}/schademeldingen`)

  serviceaanvraag = (uid, aanvraagUid) => this.db.ref(`objects/${uid}/serviceaanvragen/${aanvraagUid}`)

  serviceaanvragen = (uid) => this.db.ref(`objects/${uid}/schademeldingen`)

  diefstalmelding = (uid, meldingUid) => this.db.ref(`objects/${uid}/diefstallen/${meldingUid}`)

  diefstalmeldingen = (uid) => this.db.ref(`objects/${uid}/diefstallen`)


  // *** USER STORAGE API ***

  userStorage = (uid) => this.storage.ref(`users/${uid}`);

  // *** INVOICES API ***

  invoice = (file, uid, date) => this.storage.ref(`users/${uid}/invoices/`+ file.name + '-' + date).put(file);

  invoices = () => this.storage.ref("invoices");

  // *** LEASECONTRACT API ***

  leaseContract = (file, uid, date) => this.storage.ref(`users/${uid}/leasecontract/`+ file.name + '-' + date).put(file);

  leaseContracts = () => this.storage.ref("leasecontract");

  // *** MAINTENANCE API ***

  maintenance = (file, uid, date) => this.storage.ref(`objects/${uid}/maintenance/`+ file.name + '-' + date).put(file);

  maintenances = uid => this.storage.ref(`objects/${uid}/maintenance`);

  // *** SCHADEMELDING API ***

  schade = (file, uid, date) => this.storage.ref(`objects/${uid}/schademelding/`+ file.name + '-' + date).put(file);

  schades = uid => this.storage.ref(`objects/${uid}/schademelding`);

  // *** DIEFSTALMELDING API ***

  diefstal = (file, uid, date) => this.storage.ref(`objects/${uid}/diefstal/`+ file.name + '-' + date).put(file);

  diefstallen = uid => this.storage.ref(`objects/${uid}/diefstal`);

  // *** COMPANY INVOICES API ***

  companyInvoice = (file, uid, date) => this.storage.ref(`companies/${uid}/invoices/`+ file.name + '-' + date).put(file);

  companyInvoices = uid => this.storage.ref(`companies/${uid}/invoices`);

  // *** COMPANY RAAMCONTRACT API ***

  companyRaamContract = (file, uid, date) => this.storage.ref(`companies/${uid}/raamcontract/`+ file.name + '-' + date).put(file);

  companyRaamContracten = uid => this.storage.ref(`companies/${uid}/raamcontract`);

  // maintenances = () => this.storage.ref('maintenance');

  // *** OBJECT LEASECONTRACT API ***

  leaseContractObject = (file, uid, date) => this.storage.ref(`objects/${uid}/leasecontract/`+ file.name + '-' + date).put(file);

  leaseContractObjects = uid => this.storage.ref(`objects/${uid}/leasecontract`);

  // *** MESSAGES API ***

  // message = uid => this.db.ref(`messages/${uid}`);
  //
  // messages = () => this.db.ref('messages');

}





export default Firebase;
