import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import OnderhoudsFacturenBase from '../Objects/OnderhoudsFacturenBase';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

class WerknemerObjectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      objectName: '',
      uid:'',
      user: null,
      dealer: '',
      error: '',
      insuranceName: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.state.objectName)
    .on('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        // uid: snapshot.val().userUid,
        loading: false,
      });
    })
  }


  componentWillUnmount() {
    this.props.firebase.object(this.props.match.params.id).off();
    // this.props.firebase.user(this.state.uid).off();
  }

  render() {
    const { object, loading } = this.state;
    // console.log('user', user)
    // console.log('uid', uid)
    // console.log('object', object)
    // console.log('objectName', objectName)

    return (
      <div>
        <div className="Terug-naar-overzicht">
          <Link to={{
              pathname: `${ROUTES.WERKNEMER}`,
              // state: { user },
            }}
            >
              Terug naar overzicht
          </Link>
        </div>
        {/*{user && object && (*/}
        <div className="Object">
          <div className="Object-details">
            <h2>Objectgegevens</h2>
            {loading && <div>Loading...</div>}

            {object && (
              <div className="Object-detail-tabel">
              <table className="Tabel-gegevens">
                <tbody>
                <tr>
                  <td><strong>Objectnummer</strong></td><td>{object.objectName}</td>
                </tr>
                <tr>
                  <td><strong>Soort fiets</strong></td><td>{object.objectType}</td>
                </tr>
                <tr>
                  <td><strong>Merk</strong></td><td>{object.objectBrand}</td>
                </tr>
                <tr>
                  <td><strong>Type</strong></td><td>{object.objectModel}</td>
                </tr>
                <tr>
                  <td><strong>Motor positie</strong></td><td>{object.engineType}</td>
                </tr>
                <tr>
                  <td><strong>Versnelling</strong></td><td>{object.gearType}</td>
                </tr>
                <tr>
                  <td><strong>Framemaat</strong></td><td>{object.objectFramesize}</td>
                </tr>
                <tr>
                  <td><strong>Kleur</strong></td><td>{object.objectColor}</td>
                </tr>
                <tr>
                  <td><strong>Dames/heren</strong></td><td>{object.objectGender}</td>
                </tr>
                <tr>
                  <td><strong>Framenummer</strong></td><td>{object.objectFrameNumber}</td>
                </tr>
                </tbody>
              </table>

              <table className="Tabel-gegevens">
                <tbody>
                <tr>
                  <td><strong>Slotsoort</strong></td><td>{object.objectLock}</td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer slot</strong></td><td>{object.objectLockNumber}</td>
                </tr>
                <tr>
                  <td><strong>Sleutelnummer accu</strong></td><td>{object.objectBatteryKeyNumber}</td>
                </tr>
                <tr>
                  <td><strong>Accunummer</strong></td><td>{object.objectBatteryNumber}</td>
                </tr>
                <tr>
                  <td><strong>Soort accu</strong></td><td>{object.objectBatteryType}</td>
                </tr>
                <tr>
                  <td><strong>Bruto adviesprijs</strong></td><td>€{object.objectPrice}</td>
                </tr>
                  {object.accessoires && <tr>
                    <td><strong>Accessoires</strong></td><td><ul className="Accessoires-lijst">{object.accessoires.map(accessoire => {return <li>{accessoire}</li>})}</ul></td>
                  </tr>}
                  <tr>
                    <td><strong>Polisnummer verzekering</strong></td>
                    {object.insurance &&
                      <td>{object.insurance}</td>}
                    {!object.insurance &&
                      <td>In aanvraag</td>}
                  </tr>
                  <tr>
                    <td><strong>Pechpasnummer</strong></td>
                    {object.pechhulp &&
                    <td>{object.pechhulp}</td>}
                    {!object.pechhulp &&
                    <td>In aanvraag</td>}
                  </tr>
                </tbody>
              </table>
              </div>
          )}
          {!object && <p>Er is momenteel nog geen fiets aan uw account gekoppeld.</p>}
          </div>
        </div>
        {object && <div className="Object-meldingen">
        <div className="Onderhoudsfacturen-lijst">
          <h3>Onderhoudsfacturen</h3>
          {object.objectName && <OnderhoudsFacturen object={object.objectName} />}
          {!object && <p>Er is nog geen object gekoppeld aan deze werknemer.</p>}
        </div>
        </div>}
    </div>
    )
  }
}

const OnderhoudsFacturen = withFirebase(OnderhoudsFacturenBase);

export default WerknemerObjectBase;
