import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';

class WerkgeverOverzichtBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:false,
      users:[],
      company:'',
    };
  }

  componentDidMount() {
    this.setState({
      loading:true
    });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
        // company: this.props.company,
      });
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

render() {
  const { users, loading } = this.state;
  // console.log('company', this.props.company)

  return (
    <AuthUserContext.Consumer>
      {authUser => (
      <div className="Overzicht-werknemers">
       <div className="Goed-te-keuren-werknemers">
         <h2>Nog goed te keuren werknemers</h2>
         {loading && <div>Loading...</div>}
          <table className="Tabel-full-width">
            <tbody>
              <tr>
                <th>Naam</th><th>E-mailadres</th><th>Details</th>
              </tr>
              {users
                .sort((a, b) => {
                  if (a.username < b.username) {
                    return -1;
                  }
                  if (a.username > b.username) {
                    return 1;
                  }
                  return 0;
                })
                .filter(user => {
                  return user.roles.WERKNEMER === "WERKNEMER" //alleen werknemers tonen in lijst
                })
                .filter(user => {
                  return user.company === authUser.companyName
                })
                .filter(user => {
                  return user.isAkkoord === false
                })
                .map(user => (
                 user &&
              <tr key={user.uid}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td><Link to={{
                  pathname: `${ROUTES.WERKGEVER}/werknemer/${user.uid}`,
                  state: { user },
                }}
                >
                  Details
                </Link></td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>


        <div className="Werkgever-buttons">
          <Link to={{
            pathname: `${ROUTES.WERKGEVER}/deelnemers`,
            }}
          >
            <div className="Deelnemers">
              <h3>Deelnemers fietsplan</h3>
            </div>
          </Link>

          <Link to={{
            pathname: `${ROUTES.WERKGEVER_POOL_OBJECTS}`,
            state: {companyName: this.props.company}
            }}
            >
            <div className="Facturen">
              <h3>Poolobjecten</h3>
            </div>
          </Link>

          <Link to={{
            pathname: `${ROUTES.WERKGEVER_FACTUREN}`,
            state: {companyName: this.props.company}
            }}
            >
            <div className="Facturen">
              <h3>Facturen</h3>
            </div>
          </Link>

        </div>
    </div>
  )}
</AuthUserContext.Consumer>
  )}
}

export default WerkgeverOverzichtBase;
