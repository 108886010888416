import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class WerkgeverObjectDetailBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      object: null,
      uid:'',
      user: null,
      dealer: '',
      error: '',
      insuranceName: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true
    });

    this.props.firebase
    .object(this.props.match.params.id)
    .once('value', snapshot => {
      this.setState({
        object: snapshot.val(),
        uid: snapshot.val().userUid,
        loading: false,
      });
    })
    .then(() => {
      this.props.firebase
      .user(this.state.object.userUid)
      .once('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        })
      });
    })
    }

  render() {
    const { object, user, uid, loading } = this.state;
    // console.log('user', user)
    // console.log('uid', uid)
    // console.log('UID', this.props.uid)

    return (
      <div>
        <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {/*{user && object && (*/}
        <div className="Object">
          {object && object.poolObject && <div className="Poolobject-aanmaken-link">
          <Link to={{
              pathname: `${ROUTES.WERKGEVER}/object/${object.objectName}/edit`,
              state: { object },
            }}
            >
              Wijzig berijder poolfiets
          </Link>
        </div>}
          <div className="Object-details">
            <h2>{this.props.match.params.id}</h2>
            {loading && <div>Loading...</div>}

            {object && (
              <div className="Object-tabellen">
              <table className="Tabel-gegevens">
                <tbody>
                  <tr>
                    <td><strong>Object type</strong></td><td>{object.objectType}</td>
                  </tr>
                  <tr>
                    <td><strong>Merk</strong></td><td>{object.objectBrand}</td>
                  </tr>
                  <tr>
                    <td><strong>Model</strong></td><td>{object.objectModel}</td>
                  </tr>
                  <tr>
                    <td><strong>Bruto adviesprijs</strong></td><td>€{object.objectPrice},-</td>
                  </tr>
                  <tr>
                    <td><strong>Leasecontract:</strong></td><td>{object.downloadLeaseContractUrl &&  <a href={object.downloadLeaseContractUrl} target="_blank" rel="noopener noreferrer">Download leasecontract</a>
                    }</td>
                  </tr>
                  {object.accessoires && <tr>
                    <td><strong>Accessoires</strong></td><td><ul className="Accessoires-lijst">{object.accessoires.map(accessoire => {return <li>{accessoire}</li>})}</ul></td>
                  </tr>}

                </tbody>
              </table>
              <table className="Tabel-gegevens">
                <tbody>

                  {user && !object.poolObject && <tr>
                   <td><strong>Gebruiker</strong></td>
                    <td><Link to={{
                    pathname: `${ROUTES.WERKGEVER}/werknemer/${uid}`,
                    state: { object, user, uid },
                    }}
                    >
                      {user.username}
                    </Link></td>
                  </tr>}
                  {object.poolObject && <tr>
                    <td><strong>Gebruiker</strong></td>
                    <td>Poolobject</td>

                  </tr>}
                  {user && !object.poolObject && <tr>
                    <td><strong>Dealer</strong></td>
                    <td>{user.dealer}</td>
                  </tr>}
                  {object.poolObject && <tr>
                    <td><strong>Dealer</strong></td>
                    <td>{object.dealer}</td>

                  </tr>}
                  <tr>
                    <td><strong>Verzekering</strong></td>
                    {object.insurance &&
                      <td>{object.insurance}</td>}
                  </tr>
                  <tr>
                    <td><strong>Pechhulp</strong></td>
                    {object.pechhulp &&
                    <td>{object.pechhulp}</td>}
                  </tr>

                  {object.poolObject && <>
                  <h3 className="Marge-top">Poolobject berijder</h3>
                  <tr>
                    <td><strong>Poolobject berijder</strong></td>
                     <td>{object.poolObjectDriver}</td>
                   </tr>
                  <tr>
                     <td><strong>E-mailadres berijder</strong></td>
                     <td>{object.poolObjectDriverEmail}</td>
                   </tr>
                   <tr>
                     <td><strong>Telefoonnummer berijder</strong></td>
                     <td>{object.poolObjectDriverPhone}</td>
                   </tr>
                  <tr>
                     <td><strong>Locatie poolobject</strong></td>
                     <td>{object.poolObjectLocation}</td>
                   </tr>
                   <tr>
                      <td><strong>Startdatum</strong></td>
                      {object.poolObjectStartDate && <td>{object.poolObjectStartDate.slice(4,16)}</td>}
                    </tr>
                    <tr>
                       <td><strong>Einddatum</strong></td>
                       {object.poolObjectEndDate && <td>{object.poolObjectEndDate.slice(4,16)}</td>}
                     </tr>
                  </>}

                </tbody>
              </table>

              </div>
          )}
          </div>
          {object && object.poolObject && <div className="Melding-maken">
          <h2>Melding maken van...</h2>

            <div className="Meldingen-buttons">

            <Link to={{
              pathname: `${ROUTES.WERKGEVER}/diefstalformulier`,
              state: { object:object.objectName, uid: this.props.uid }
            }}
            >
              <div className="Melding-button">
                <h3>Diefstal</h3>
              </div>
            </Link>

            <Link to={{
              pathname: `${ROUTES.WERKGEVER}/schadeformulier`,
              state: { object:object.objectName, uid: this.props.uid }
            }}
            >
              <div className="Melding-button">
                <h3>Reparatie / Schade</h3>
              </div>
            </Link>
          </div>
        </div>}
        </div>
    </div>
    )
  }
}

export default withRouter(WerkgeverObjectDetailBase);
