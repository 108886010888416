import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import "./SignUpDealer.css";

const SignUpDealerPage = () => (
  <div className="Page">
    <h1>Aanmeldpagina fietsendealers</h1>
    <div className="SignUpDealerForm">
      <SignUpDealerForm />
    </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  dealerName: '',
  dealerAddress: '',
  dealerPlace: '',
  dealerContact: '',
  dealerContactEmail: '',
  isDealer: true,
  error: null,
}

class SignUpDealerFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { username, email, passwordOne, dealerName, dealerPlace, dealerAddress, dealerContact, dealerContactEmail, isDealer} = this.state;

    const roles = {};

    if(isDealer) {
      roles[ROLES.DEALER] = ROLES.DEALER;
    }


    this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      //Create a user in Firebase realtime database
      return this.props.firebase
      .user(authUser.user.uid)
      .set({username, email, dealerName, roles,})
    })
    .then(() => {
      return this.props.firebase.doSendEmailVerification();
    })
    .then(() => {
      this.sendEmailToDealer(event);
    })
    // .then(authUser => {
    //   // this.setState({...INITIAL_STATE});
    //   this.props.history.push(ROUTES.HOME);
    // })
    .catch(error => {
      this.setState({error});
    });

    return this.props.firebase
    .dealer(dealerName)
    .set({ dealerName, dealerAddress, dealerPlace, dealerContact, dealerContactEmail })
    .then(this.setState({...INITIAL_STATE},
          this.props.history.push(ROUTES.HOME)))
    .catch(error => {
      this.setState({error});
    })

    // event.preventDefault();
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      dealerContact: this.state.username,
      dealerContactEmail: this.state.email,
     })
  }

  sendEmailToDealer(event) {
  //Verstuur mail naar werkgever
    const templateId = 'aanmelding_dealer_bevestiging_naar_dealer';
    const userId = 'user_hxvxyKl4UEjwKu063L7iK';
    const template_params = {
      receiverEmail: this.state.dealerContactEmail,
      dealerContact: this.state.dealerContact,
      dealerAddress: this.state.dealerAddress,
      dealerPlace: this.state.dealerPlace,
      dealerName: this.state.dealerName,
      password: this.state.passwordOne,
    }

    this.sendFeedbackDealer(templateId, template_params, userId)
  }

  sendFeedbackDealer(templateId, template_params, userId) {
    window.emailjs.send(
      'default_service',
      templateId,
      template_params,
      userId
    )
    .then(res => console.log('email verstuurd', res))
    .catch(err => console.error('Error', err))
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      dealerName,
      dealerPlace,
      dealerAddress,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      dealerName === '' ||
      dealerAddress === '' ||
      dealerPlace === '';

    return(
      <div>
      <div className="Terug-naar-overzicht">
        <Link to={{
            pathname: `${ROUTES.DEALERS}`,
            // state: { user },
          }}
          >
            Terug naar dealers
        </Link>
      </div>
      <div>
        <form onSubmit={this.onSubmit} className="SignUpForm">
          <label>Volledige naam contactpersoon</label>
          <input
          name = 'username'
          value = {username}
          onChange = {this.onChange}
          type = 'text'
          />
          <label>Emailadres</label>
          <input
          name = 'email'
          value = {email}
          onChange = {this.onChange}
          type = 'text'
          />
          <label>Wachtwoord</label>
          <input
          name = 'passwordOne'
          value = {passwordOne}
          onChange = {this.onChange}
          type = 'password'
          />
          <label>Bevestig wachtwoord</label>
          <input
          name = 'passwordTwo'
          value = {passwordTwo}
          onChange = {this.onChange}
          type = 'password'
          />
          <label>Bedrijfsnaam</label>
          <input
          name = 'dealerName'
          value = {dealerName}
          onChange = {this.onChange}
          type = 'text'
          />
          <label>Adres bedrijf</label>
          <input
          name = 'dealerAddress'
          value = {dealerAddress}
          onChange = {this.onChange}
          type = 'text'
          />
          <label>Plaats bedrijf</label>
          <input
          name = 'dealerPlace'
          value = {dealerPlace}
          onChange = {this.onChange}
          type = 'text'
          />

          <button disabled={isInvalid} type='submit'>Aanmelden</button>

          {error && <p>{error.message}</p>}

        </form>
      </div>
      </div>
    )
  }
}

const SignUpDealerLink = () => (
  <p>
    Heb je nog geen account? <Link to={ROUTES.SIGN_UP_DEALER}>Meld je hier aan</Link>
  </p>
)

const SignUpDealerForm = compose(
  withRouter,
  withFirebase,
)(SignUpDealerFormBase);

export default SignUpDealerPage;

export { SignUpDealerForm, SignUpDealerLink };
