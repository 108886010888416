import React , { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import Autocomplete from '../SignUpWerknemer/Autosuggestion';

class UserItemEditBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      username: '',
      phoneNumber: '',
      bijtelling: '',
      dealer: '',
      dealers: '',
      object: '',
      objectName: '',
      monthlyLeaseFee: '',
      gegevensGewzijgd: false,
      uid: '',
      error: '',
      activity: [],
      date: '',
      ...props.location.state,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
    });

    this.props.firebase
    .user(this.props.match.params.id)
    .once('value', snapshot => {
      this.setState({
        username: snapshot.val().username,
        uid: this.props.match.params.id,
        objectName: snapshot.val().object,
        phoneNumber: snapshot.val().phoneNumber,
        bijtelling: snapshot.val().bijtelling,
        monthlyLeaseFee: snapshot.val().monthlyLeaseFee,
        dealer: snapshot.val().dealer,
        loading: false,
      });
    })
    .then(() => {
      this.props.firebase
      .dealers()
      .once('value', snapshot => {
        const dealersObject = snapshot.val();

        const dealersList = Object.keys(dealersObject).map(key => ({
          ...dealersObject[key],
          uid: key,
        }));

        this.setState({
          dealers: dealersList,
          loading: false,
        });
      })
    });
  }

  componentWillUnmount() {
    // this.props.firebase.user(this.props.match.params.id).off();
    // this.props.firebase.dealers().off();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  onDealerSelectChange = (dataFromChild) => {
    this.setState({dealer: dataFromChild})
  }

  onUpload = event => {
    this.setState({
      leaseContract: event.target.files[0],
    })
  }

  onSubmit = event => {

    this.props.firebase
      .user(this.state.uid)
      .update({
        username: this.state.username,
        phoneNumber: this.state.phoneNumber,
        bijtelling: this.state.bijtelling,
        dealer: this.state.dealer,
        monthlyLeaseFee: this.state.monthlyLeaseFee,
      })
      .then(() => {
        this.props.firebase
        .object(this.state.objectName)
        .update({
          dealer: this.state.dealer
        })
      })
      .then(() => {
        if(this.state.leaseContract) {
          this.uploadRaamContract()
        }
      })

    // this.props.firebase
    // .user(this.state.uid)
    // .update({
    //   username: this.state.username,
    //   phoneNumber: this.state.phoneNumber,
    //   bijtelling: this.state.bijtelling,
    //   dealer: this.state.dealer,
    //   monthlyLeaseFee: this.state.monthlyLeaseFee,
    // })
    // .then(res => console.log('Gegevens succesvol aangepast bij gebruiker', res))
    // .catch(err => console.error('Error', err));
    //
    // this.props.firebase
    // .object(this.state.objectName)
    // .update({
    //   dealer: this.state.dealer
    // })
    // .then(res => console.log('Dealer succesvol aangepast op object', res))
    // .catch(err => console.error('Error', err));

    //update user activity
    // const ref = this.props.firebase
    // .user(this.state.uid)
    //
    // const userActivity = ref.child('activity')
    //
    // userActivity.push({
    //   activity: 'Gegevens door Backoffice gewijzigd.',
    //   createdAt: new Date().toString(),
    // })

    this.setState({
      gegevensGewzijgd: true,
    })

    event.preventDefault();
  }

  uploadRaamContract = event => {

    const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    // const { user } = this.state;

    // const dealer = event.target.name;

    // const file = event.target.files[0];
    const uploadTask = this.props.firebase.leaseContract(this.state.leaseContract, this.state.uid, date);

    uploadTask.on('state_changed', snapshot => {

    }, error => {
      this.setState({
        error: true
      })

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

        this.props.firebase
        .user(this.state.uid)
        .update({
          downloadUrlLeaseContract: downloadURL,
        })
        .then(res => {
          this.setState({
            successUploadInvoice: true
          })
        })
    })
  })
  }

  render() {
    const { user, username, downloadUrlLeaseContract, phoneNumber, bijtelling, monthlyLeaseFee, dealer, dealers, gegevensGewzijgd, loading } = this.state;

    // console.log('startDateContract', this.state.startDateContract)
    // console.log('endDateContract', this.state.endDateContract)
    // console.log('DEALERS', this.state.dealers)


    return (
      <div>
      <button className="Terug-knop" onClick={this.props.history.goBack}>Terug</button>
        {loading && <div>Loading...</div>}

        {user && dealers && (
          <div className="Werknemer-gegevens-edit">
            <div className="Werknemer-details">
            <div className="Werknemer-details-column">
            <h3>Peroonsgegevens</h3>
            <form onSubmit={this.onSubmit}>
              <label>Naam:</label>
                <input
                  name = 'username'
                  value = {username}
                  onChange = {this.onChange}
                  type = 'text'
                  placeholder = {username}
                />
                <label>Telefoonnummer:</label>
                  <input
                    name = 'phoneNumber'
                    value = {phoneNumber}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {phoneNumber}
                  />
                <label>Bijtelling:</label>
                  <input
                    name = 'bijtelling'
                    value = {bijtelling}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {bijtelling}
                  />
                <label>Maandelijks leasebedrag:</label>
                  <input
                    name = 'monthlyLeaseFee'
                    value = {monthlyLeaseFee}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {monthlyLeaseFee}
                  />
                {/*<div>
                  <label>Onderhoudse-mail</label>
                    <span className="Toelichting-werkgever-keuze">Vink dit vakje aan als deze persoon geen automatische onderhoudsmail moet krijgen na 3 maanden.</span>
                    <input
                      type="radio"
                      name="selectedOption"
                      value="Nee"
                      onChange={this.onChange}
                      className="form-check-input"
                    />
                    <label>
                    Geen onderhoudsmail
                  </label>
                </div>*/}
                <label>Huidige dealer:</label>
                  <input
                    name = 'dealer'
                    value = {dealer}
                    onChange = {this.onChange}
                    type = 'text'
                    placeholder = {dealer}
                  />

                {user.dealer && <div><label>Selecteer nieuwe dealer</label>
                  <span className="Toelichting-werkgever-keuze">Vul hieronder de naam van de dealer in. Nadat je begint met typen, verschijnt een dropdown lijst met dealer.</span>
                  <Autocomplete name='dealer' callbackFromParent={this.onDealerSelectChange} suggestions={dealers.map(dealer => {
                    return dealer.dealerName;
                  })} /></div>}
                <label>Leasecontract:</label>
                  {downloadUrlLeaseContract &&   <a href={downloadUrlLeaseContract} target="_blank" rel="noopener noreferrer">Download leasecontract</a>}
                    {!downloadUrlLeaseContract &&
                      <input
                      name = 'file'
                      type = 'file'
                      onChange = {this.onUpload}
                      />
                    }
              <div>
                <button type='submit' className="Button-marge">Opslaan</button>
              </div>
              {gegevensGewzijgd && <p>Gegevens succesvol gewijzigd.</p>}
              </form>
            </div>

          </div>
        </div>
        )}

      </div>
    )
  }
}

export default withRouter(UserItemEditBase);
